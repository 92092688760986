// import React from 'react';
import styled, { css } from 'styled-components';

const resetCss = css`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

// Add a `max-width` to ensure content within each column does not blow out
// the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
// do not appear to require this.
const makeCol = (size, columns = 12) => {
  if (!size) {
    return null;
  }

  return css`
    flex: 0 0 ${(size / columns) * 100}%;
    max-width: ${(size / columns) * 100}%;
  `;
};

const makeBreakpointColumn = (breakpoint, size, columns = 12) => {
  if (!size) {
    return null;
  }

  return breakpoint`
    ${resetCss};
    ${makeCol(size, columns)}
  `;
};

const Col = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  ${({ xs }) => makeCol(xs)};
  ${({ sm, theme }) => makeBreakpointColumn(theme.small, sm)};
  ${({ md, theme }) => makeBreakpointColumn(theme.medium, md)};
  ${({ lg, theme }) => makeBreakpointColumn(theme.large, lg)};
  ${({ xl, theme }) => makeBreakpointColumn(theme.xlarge, xl)};
`;

export default Col;
