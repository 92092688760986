import React from 'react';

import { SocialListItem } from 'components/PageFooter/styles';

import { icons } from 'components/PageFooter/icons';

function FooterSocialListItem({ name, link }) {
  return (
    <SocialListItem
      href={link} 
      target="_blank"
      rel="noopener noreferrer"
      data-track="click"
      data-action="Footer Social Media Link Click"
      data-category="Link Click"
      data-label="Home"
      data-social={name}
      data-event-category="Global Element"
      data-event-action={`${name} Icon`}
      data-event-label="Footer"
    >
      {icons[name]}
    </SocialListItem>
  )
}

export default FooterSocialListItem;
