import { getStateFromDOM } from '../core/utils/client';

const getUserId = ({ currentUser }) => {
  if (!currentUser) {
    return null;
  }

  return currentUser.id || currentUser.user_id || undefined;
};

const createDataLayerEvent = (method, name, payload = {}) => {
  // segment adds a userId
  const userId = getUserId(getStateFromDOM());
  let args = userId ? { ...payload, userId } : { ...payload };
  if (method === 'page') {
    // Data about the page (segment adds this behind the scenes)
    const {
      referrer,
      title,
      location: { search, pathname: path, href: url },
    } = document;
    const event = `Viewed ${name} Page`;

    args = { name, path, referrer, search, title, url, event, ...args };
  } else if (method === 'track') {
    args = { event: name, ...args };
  }

  return window.dataLayer.push({ gtm: true, ...args });
};

export default createDataLayerEvent;
