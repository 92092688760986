import React from 'react';
import isEmail from 'validator/lib/isEmail';

import TextField from './TextField';

const getValidator = required => (value) => {
  if (required && !value) {
    return 'Required';
  }

  if (!isEmail(value)) {
    return 'Invalid email address';
  }

  return undefined;
};

export default function EmailField(props) {
  return (
    <TextField
      {...props}
      type="email"
      validate={getValidator(props.required)}
    />
  );
}
