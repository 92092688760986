import React from 'react';
import styled from 'styled-components';

import { Button as BaseButton } from 'core/ui';

const Button = styled(BaseButton)`
  font-size: 0.875rem;
  width: 100%;
  text-align: left;
  font-weight: normal;
  padding: 12px 34px;

  ${({ theme }) => theme.large`
    font-weight: bold;
    padding: 8px 11px;
    text-align: center;
    width: auto;
  `}

  @media (min-width: 1030px) {
    padding: 8px 12px;
  }
`;

export const renterRVButton = (listRvButtonProps) => {
  return (
    <Button
      href="/rv-rental"
      variant="blue"
      as="a"
      data-id="nav-links-find-rv"
      data-event-category="Global Element"
      data-event-action="Find an RV"
      data-event-label="Top Navigation"
      {...listRvButtonProps}
    >
      Find an RV
    </Button>
  );
};

export const ownerRVButton = (listRvButtonProps) => {
  return (
    <Button
      href="/list-your-rv"
      variant="coreGreyBlueBorder"
      as="a"
      data-id="nav-links-list-rv"
      data-event-category="Global Element"
      data-event-action="List Your RV"
      data-event-label="Top Navigation"
      {...listRvButtonProps}
    >
      List Your RV
    </Button>
  );
};
