import segment from '../SafeTracking';
import { breakoutTraits, parseUser } from '../utils';

if ('NodeList' in window && !NodeList.prototype.forEach) {
  // IE11 polyfill for looping over nodeList
  NodeList.prototype.forEach = function nodeListForEach(callback, thisArg) {
    const arg = thisArg || window;
    for (let i = 0; i < this.length; i += 1) {
      callback.call(arg, this[i], i, this);
    }
  };
}

const attachLinkListeners = (linksElements) => {
  (linksElements || []).forEach((el) => {
    const dataAttributes = el.dataset || {};
    const name = `Link Click ${dataAttributes.action}`;

    const { action, track, ...attributes } = dataAttributes;

    segment.trackLink(el, name, attributes, { rvsIntegrations: { all: false, segment: true } });
  });
};

const attachFormListeners = (formsElements) => {
  (formsElements || []).forEach((el) => {
    const data = el.dataset || {};
    const name = `Form Submission ${data.action}`;

    segment.trackForm(el, name, {
      category: data.category,
      label: data.label,
      group: data.group,
    }, { rvsIntegrations: { all: false, segment: true } });
  });
};

const parseDomContent = content => (content ? JSON.parse(content) : null);

const normalizeEventProperties = (name, properties) => {
  if (name === 'ViewContent' || name === 'Search') {
    return properties;
  }

  return {
    ...breakoutTraits(window.location.search),
    ...properties,
  };
};

const getPageCategories = name => {
  if (name === 'How it Works') {
    return ['Content Page'];
  }
  if (name === 'Optional Insurance Terms') {
    return ['Content Page'];
  }
  if (name === 'Owner Cancellation Policy') {
    return ['Content Page'];
  }
  if (name === 'Privacy Policy') {
    return ['Content Page'];
  }
  if (name === 'Respectful Community Policy') {
    return ['Content Page'];
  }
  if (name === 'Digital Accessibility') {
    return ['Content Page'];
  }
  if (name === 'RV Rental Agreement & Optional Insurance Terms') {
    return ['Content Page'];
  }
  if (name === 'Terms of Service') {
    return ['Content Page'];
  }
  return null;
}

const submitDefaultEvents = () => {
  const segmentPageElement = document.querySelector('#segment_page');
  const segmentEventsElement = document.querySelector('#segment_events');

  const pageContent = segmentPageElement ? segmentPageElement.getAttribute('content') : null;
  const eventsContent = segmentEventsElement ? segmentEventsElement.getAttribute('content') : null;

  const page = parseDomContent(pageContent);
  const events = parseDomContent(eventsContent);

  // Certain pages still use this legacy tracking, need to add categories to them
  const pageCategories = getPageCategories(page?.name);

  if (page) {
    segment.page(page.name, {
      categories: pageCategories,
      ...breakoutTraits(window.location.search),
      ...page.properties,
    });
  }

  if (events && events.length) {
    events.forEach(({ name, properties, options }) => {
      const event = normalizeEventProperties(name, properties);

      segment.track(name, event, options);
    });
  }
};

export const identifyUser = (currentUser) => {
  if (currentUser) {
    const user = parseUser(currentUser);
    segment.identify(currentUser.id, user, { rvsIntegrations: { all: false, segment: true } });
  }
};

export default function initiateTracking({ currentUser }) {
  identifyUser(currentUser);
  submitDefaultEvents();

  attachLinkListeners(document.querySelectorAll('[data-track="click"]'));
  attachFormListeners(document.querySelectorAll('[data-track="form"]'));
}
