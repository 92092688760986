import safeGtmFunction from './SafeDatalayer';
import safeSegmentFunction from './SafeSegment';

const defaults = { rvsIntegrations: { all: true } };

const safeFunctionRouter = method => (...args) => {
  try {
    // if find returns -1 will break splice below, added check on splice
    const intIndex = args.findIndex(i => !!i && i.rvsIntegrations);
    // splice removes rvsIntegrations from args array and returns as array
    // need to only send Segment 3 args
    const [ rvsIntOptions ] = intIndex >= 0 ? args.splice(intIndex, 1) : [];
    // separate rvsIntegrations because "integrations" is keyword in Segment
    const {
      rvsIntegrations: {
        all, gtm, segment,
      } = {},
    } = rvsIntOptions || defaults;

    if (all || gtm) {
      safeGtmFunction(method, ...args);
    }
    if (all || segment) {
      safeSegmentFunction(method, ...args);
    }
  } catch (e) {
    // fail gracefully
    // eslint-disable-next-line no-console
    console.warn(`analytics.${method}`, args);
  }
};

export default {
  identify: safeFunctionRouter('identify'),
  track: safeFunctionRouter('track'),
  trackFacebook: safeFunctionRouter('trackFacebook'),
  trackLink: safeFunctionRouter('trackLink'),
  trackForm: safeFunctionRouter('trackForm'),
  page: safeFunctionRouter('page'),
};
