import React from 'react';

const defaultState = {
  pathname: '',
  search: '',
};

export const PagePath = React.createContext(defaultState);

export const usePagePath = () => React.useContext(PagePath);
