import { useEffect, useState } from 'react';

/*
 * instantiate image elements passed in via an array and set the src property to the images url to preload images
 * no need to attach it to the DOM as image is cached and image elements with the same source will
 * load instantly
 */

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      resolve(img);
    };
    /* eslint-disable-next-line no-multi-assign */
    img.onerror = img.onabort = function() {
      reject(src);
    };
    img.src = src;
  });
}

export function useImagesPreloader(imageList) {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      if (!imageList || imageList?.length === 0) {
        // if there are no images we can say the component is good to render since there are no images to preload
        return setImagesPreloaded(true);
      }

      try {
        const imagesPromiseList = [];
        imageList.forEach(i => imagesPromiseList.push(preloadImage(i)));

        // wait for the images to preload
        await Promise.all(imagesPromiseList);
      } catch (error) {
        console.error('Preload failed:', error);
        return;
      }

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    // cleanup in case the component unmounts before the images are preloaded
    return () => {
      isCancelled = true;
    };
  }, []);

  return { imagesPreloaded };
}

export function useImagePreloader(image) {
  useEffect(() => {
    if (!image || image.length === 0)
      return;

    const preloadedImage = new Image();
    const preloadedImageRetina = new Image();

    preloadedImage.src = image?.urls[0];
    preloadedImageRetina.src = image?.urls[1];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
