import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from './LazyImage';

const DEFAULT_RENTER_ICON = require('images/default-renter-photo.png');
const DEFAULT_OWNER_ICON = require('images/default-owner-photo.png');

function buildOptions(options, zoomed = false) {
  if (zoomed) {
    return [
      `h_${options.height}`,
      `w_${options.width}`,
      'c_thumb',
      'g_faces:center',
      'f_auto',
      'q_auto',
    ].join(',');
  }

  return [
    `h_${options.height}`,
    `w_${options.width}`,
    'c_thumb',
    'g_center',
    'r_max',
    'f_auto',
  ].join(',');
}

function getParts(fileName) {
  // Starts with http and greedy match to second to last slash, non-slashes and the last slash, non-slashes to the end
  // The return at the bottom shows what I'm trying to accomplish. The cloudinary params (h_400, etc.) need to come
  // before the final directory name
  const parser = /^(http.+\/)([^/]+\/)([^/]+)$/

  if (parser.test(fileName)) {
    return fileName.match(parser).slice(1)
  }

  return ['https://d3adfz34ynqwkr.cloudfront.net/image/upload/', 'rvs-images/', fileName]
}

const createImageSource = (fileName, options, zoomed) => {
  if (fileName.includes('ctfassets')) {
    return { 
      src: fileName,
      srcSet: fileName
    }
  }
  const imageOptions = buildOptions(options, zoomed);
  const [domain, path, key] = getParts(fileName)

  return {
    src: `${domain}${imageOptions}/e_improve,q_auto/${path}${key}`,
    srcSet: `${domain}${imageOptions}/e_improve,q_auto,dpr_2.0/${path}${key} 2x`,
  };
};

function src(userType, fileName, options, zoomed) {
  if (!fileName) {
    return userType === 5 ? { src: DEFAULT_RENTER_ICON } : { src: DEFAULT_OWNER_ICON };
  }
  return createImageSource(fileName, options, zoomed);
}

export default function UserPhoto(props) {
  const { zoomed, options, userType, fileName, lazyLoad } = props;
  const className = `${options.className || ''} ${props.className || ''}`;

  const imgProps = {
    alt: options.alt || '',
    className,
    'data-id': 'user-photo',
    'data-name': options.dataName,
    width: options.width,
    height: options.height,
    ...src(userType, fileName, options, zoomed),
  };

  if (lazyLoad) {
    return <LazyImage {...imgProps} />;
  }

  // eslint-disable-next-line
  return <img {...imgProps} />;
}

UserPhoto.propTypes = {
  className: PropTypes.string,
  fileName: PropTypes.string,
  userType: PropTypes.number,
  zoomed: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  options: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string,
    dataName: PropTypes.string,
  }),
};

UserPhoto.defaultProps = {
  className: '',
  userType: null,
  fileName: null,
  zoomed: false,
  lazyLoad: false,
  options: {
    alt: '',
    height: 29,
    width: 29,
    className: '',
    dataName: null,
  },
};
