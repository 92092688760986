import React from 'react';
import styled from 'styled-components';

import UserPhoto from 'components/UserPhoto';

const UserImage = styled(UserPhoto)`
  vertical-align: middle;
  margin-right: 7px;
`;

const StyledWrapper = styled.span`
  margin-right: 8px;
  font-size: 0.875rem;
`;

const UserFirstName = styled.span`
  font-size: 16px;

  ${({ theme }) => theme.large`
    font-size: 0.875rem;
  `}
`;

function UserImageAndName({ currentUser, imageOptions }) {
  return (
    <StyledWrapper>
      <UserImage
        fileName={currentUser.photo}
        userType={parseInt(currentUser.group_id, 10)}
        alt={`${currentUser.first_name}'s account`}
        options={imageOptions}
      />
      <UserFirstName>{currentUser.first_name}</UserFirstName>
    </StyledWrapper>
  );
}

export default React.memo(UserImageAndName);
