import React from 'react';
import OtpInput from 'react-otp-input';

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '1em',
  marginBottom: '1em'
}

const inputStyle = {
  fontSize: '3em',
  borderRadius: '1.2vh',
  borderSize: '0.25px',
  marginRight: '0.1em',
  marginLeft: '0.1em'
}

export default function OtpField(props) {
  return (
    <OtpInput
      {...props}
      containerStyle={containerStyle}
      inputStyle={inputStyle}
    />
  )
}
