import qs from 'qs';
import findIndex from 'array-find-index';
import toCamelCase from 'camelcase';
import toSnakeCase from 'snake-case';

import { prune } from './Object';

export const strip = str => (str && str[0] === '?' ? str.substr(1) : str);
export const sanitize = obj => qs.parse(qs.stringify(obj));

// Keep these at the beginning of the query and alphabetize everything else
const sortOrder = ['location', 'start_date', 'end_date', 'lat', 'lng'];

function smartSort(a, b) {
  const aIndex = findIndex(sortOrder, val => val === a);
  const bIndex = findIndex(sortOrder, val => val === b);

  // Not special? Sort alphabetically
  if (aIndex === -1 && bIndex === -1) {
    return a.localeCompare(b);
  }

  if (aIndex === -1) {
    return 1;
  }

  if (bIndex === -1) {
    return -1;
  }

  return aIndex < bIndex ? -1 : 1;
}

export const prepareForStringify = obj =>
  Object.keys(prune(obj)).reduce((acc, key) => {
    let value = obj[key];

    if (Array.isArray(value) && key !== 'amenities') {
      // Comma separate arrays
      value = value.join(',');
    } else if (typeof variable === 'boolean') {
      // true is sent, but not false
      value = value ? 'true' : undefined;
    }

    return {
      ...acc,
      [key]: value || undefined,
    };
  }, {});

export const stringify = (obj, { snakeCase, addQueryPrefix } = {}) => {
  if (snakeCase) {
    return qs.stringify(
      Object.keys(obj).reduce(
        (acc, key) => ({
          ...acc,
          [toSnakeCase(key)]: obj[key],
        }),
        {},
      ),
      { sort: smartSort, arrayFormat: 'brackets', addQueryPrefix },
    );
  }

  return qs.stringify(obj, { arrayFormat: 'brackets', addQueryPrefix });
};

export const parse = (str, { camelCase } = {}) => {
  const obj = qs.parse(str, { ignoreQueryPrefix: true });

  if (camelCase) {
    return Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        [toCamelCase(key)]: obj[key],
      }),
      {},
    );
  }

  return obj;
};
