/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import get from 'just-safe-get';

import Collapse from 'components/Collapse';

import { FormFeedbackError } from './styles';


const Input = styled.input`
  margin-right: 4px;
`;

const CheckboxError = styled(FormFeedbackError)`
  margin-left: 20px;
`;

const getValidator = required => (value) => {
  if (required && !value) {
    return 'Required';
  }

  return undefined;
};

export default function CheckboxField(props) {
  const { children, label, onChange: parentOnChange, ...fieldProps } = props;

  return (
    <Field
      name={props.name}
      validate={getValidator(props.required)}
      render={({ field: { value, ...field }, form: { touched, errors, setFieldTouched } }) => {
        // eslint-disable-next-line
        const invalid = !!(get(touched, field.name) && get(errors, field.name));

        const onChange = (...args) => {
          // Firefox bug was not registering touch on checkbox.
          // This manual touch fixes allows validation to fire as expected.
          setFieldTouched(field.name);

          field.onChange(...args);
          if (parentOnChange) {
            parentOnChange(...args);
          }
        };

        const renderError = (
          <Collapse isOpen={invalid}>
            <CheckboxError>
              {get(errors, field.name)}
            </CheckboxError>
          </Collapse>
        );

        const input = (
          <Input
            {...field}
            onChange={onChange}
            // value is always a boolean
            checked={value || false}
            type="checkbox"
            {...fieldProps}
          />
        );

        // conditionally wrap input in label
        if (children || label) {
          return (
            <>
              <label htmlFor={props.id}>
                {input} {children || label}
              </label>

              {renderError}
            </>
          );
        }

        return (
          <>
            {input}
            {renderError}
          </>
        );
      }}
    />
  );
}
