import React, { useState } from 'react';

import SocialAuthButtons from 'auth/components/SocialAuthButtons';
import LoginForm from 'auth/forms/LoginForm';
import { SocialAuthActions } from 'core/helpers/SocialAuth';
import { trackLoginCompleted } from 'tracking/account/TrackLogins';

import SocialRegisterForm from '../forms/SocialRegisterForm';

import { Divider, ContentWrapper, ModalStyled, ModalHeaderStyled, CloseButtonStyled } from './styles/Modal';

function LoginModal({ modal, email, onSuccess }) {
  const [showSocialLogins, setShowSocialLogins] = useState(true);
  const [socialSignUpData, setSocialSignUpData] = useState(null);
  const [showSocialSignUpForm, setShowSocialSignUpForm] = useState(false);

  const loginSuccessHandler = ({
    dismissModal,
    accountType,
    provider,
    data: { user: { id: userId, last_login: lastLoginDate } = {} } = {}
  }) => {
    // if no provider, it's an email login, so let's track it
    if (!provider) {
      trackLoginCompleted({
        email,
        userId,
        lastLoginDate,
        accountType,
        loginMethod: 'Email',
      });
    }

    // Don't redirect to panel on successful login
    // if callback arg is truthy or the login modal was
    // passed an onSuccess method
    if (dismissModal || onSuccess) {
      modal.onRequestClose();

      if (onSuccess) {
        onSuccess();
      } else {
        window.location.reload();
      }
    } else {
      window.location.href = '/dashboard/';
    }
  };

  const onStartSocialSignUpSuccess = values => {
    setSocialSignUpData(values);
    setShowSocialLogins(false);
    setShowSocialSignUpForm(true);
  };

  const onSocialSignUpSuccess = () => {
    modal.onRequestClose();
    window.location.reload();
  }

  return (
    <ModalStyled id="login-modal" {...modal}>
      <ModalHeaderStyled>
        Log In
        <CloseButtonStyled type="button" onClick={() => modal.toggle()}>
          <span className="sr-only">Close modal</span>&times;
        </CloseButtonStyled>
      </ModalHeaderStyled>
      <ContentWrapper>
        {showSocialLogins && (
          <>
            <SocialAuthButtons
              action={SocialAuthActions.LOGIN}
              onSubmitSuccess={loginSuccessHandler}
              onStartSocialSignUpSuccess={onStartSocialSignUpSuccess}
            />
            <Divider>
              <span>or</span>
            </Divider>
          </>
        )}
        {!showSocialSignUpForm ? (
          <LoginForm
            email={email}
            onSubmitSuccess={loginSuccessHandler}
            setShowSocialLogins={setShowSocialLogins}
          />
        ) : (
          <SocialRegisterForm userData={socialSignUpData} onSubmitSuccess={onSocialSignUpSuccess} />
        )}
      </ContentWrapper>
    </ModalStyled>
  );
}

export default LoginModal;
