import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash-es';

import Alert from 'components/Alert';
import { withCurrentUser } from 'core/state/Globals';

import Icon from 'core/ui/Icon';
import { Button } from 'core/ui';
import { getStateFromDOM } from 'core/utils/client';
import SocialIcon from './icons/SocialIcon';

import useSocialLoginHandler from './hooks/useSocialLoginHandler';

function SocialButton(props) {
  const {
    action,
    onSubmitSuccess,
    onStartSocialSignUpSuccess,
    reloadUser,
    loadSdk,
    provider,
    loginHandler,
    buildParams,
    onError,
    headers,
  } = props;

  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = useSocialLoginHandler(
    reloadUser,
    onSubmitSuccess,
    onStartSocialSignUpSuccess,
    onError,
    setLoggingIn,
    setError,
    provider,
    action,
    loginHandler,
    buildParams,
    headers
  );

  useEffect(() => {
    if (sdkLoaded) return;
    loadSdk(() => setSdkLoaded(true))
  }, [loadSdk, sdkLoaded]);

  const enabledHash = getStateFromDOM('env.enabledSocialLogins') || {};
  const buttonEnabled =
    Object.prototype.hasOwnProperty.call(enabledHash, provider) &&
    enabledHash[provider].toString().toLowerCase() === 'true';

  return (
    buttonEnabled && (
      <>
        <Button
          type="button"
          disabled={!sdkLoaded || loggingIn}
          onClick={handleClick}
          data-id={`${provider}-button`}
          variant="coreBlueBorderLargeAlt"
        >
          {!loggingIn && <SocialIcon type={provider} />}
          {loggingIn && (
            <Icon
              style={{
                fontSize: null,
                marginRight: '0.5em',
                verticalAlign: 'middle',
              }}
              icon="sync"
              spin
            />
          )}
          {action} with {capitalize(provider)}
        </Button>
        {error ? <Alert variant="danger">{error}</Alert> : null}
      </>
    )
  );
}

SocialButton.propTypes = {
  action: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func,
  reloadUser: PropTypes.func,
  provider: PropTypes.string.isRequired,
  loginHandler: PropTypes.func.isRequired,
  buildParams: PropTypes.func,
  headers: PropTypes.object,
  onError: PropTypes.func,
  onStartSocialSignUpSuccess: PropTypes.func.isRequired,
  loadSdk: PropTypes.func.isRequired,
};

SocialButton.defaultProps = {
  onSubmitSuccess: null,
  onError: null,
  reloadUser: null,
  buildParams: null,
  headers: {},
};

export default withCurrentUser(SocialButton, { includeSetter: true });
