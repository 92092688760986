import React from 'react';

import SafeRollbar from 'core/helpers/SafeRollbar';

// TODO: Probably make this more robust
const logError = (error, lvl='error') => {
  SafeRollbar[lvl](error);
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service
    logError(error, this.props.errorLevel);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

export const withErrorBoundary = (BaseComponent, errorLevel = 'error') => {
  const Component = (props) => {
    return (
      <ErrorBoundary errorLevel={errorLevel}>
        <BaseComponent {...props} />
      </ErrorBoundary>
    );
  };

  Component.displayName = `withErrorBoundary(${BaseComponent.displayName || 'Unknown'})`;

  return Component;
};
