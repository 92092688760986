import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'core/ui';

import { ContentWrapper, Heading } from '../modals/styles/Modal';

export default function RegisterGroupChooser(props) {
  return (
    <ContentWrapper id="sign-up-part1">
      <Heading>Sign Up As</Heading>
      <div>
        <Button
          id="owner-button"
          variant="coreBlueLargeAlt"
          href="/list-rv"
          as="a"
          data-testid="owner-button"
          onClick={e => props.onChooseOwner(e)}
          style={{ marginBottom: '1rem' }}
        >
          Owner
        </Button>
        <Button
          id="renter-button"
          variant="coreBlueLargeAlt"
          onClick={props.onChooseRenter}
          type="button"
          data-testid="renter-button"
        >
          Renter
        </Button>
      </div>
    </ContentWrapper>
  );
}

RegisterGroupChooser.propTypes = {
  onChooseRenter: PropTypes.func.isRequired,
};
