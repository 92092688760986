import { useCallback, useState } from 'react';

export default function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // eslint-disable-next-line no-prototype-builtins
      if (!window?.localStorage?.hasOwnProperty(key)) {
        return initialValue;
      }

      return JSON.parse(window.localStorage.getItem(key));
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(value => {
    try {
      setStoredValue((oldValue) => {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(oldValue) : value;
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        return valueToStore;
      });
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  }, [setStoredValue, key]);

  return [storedValue, setValue];
}
