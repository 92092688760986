import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { variant as styledVariant } from 'styled-system';

const alertStyle = styledVariant({
  key: 'alerts',
});

const AlertStyled = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  ${alertStyle}

  &.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @-webkit-keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

export default function Alert(props) {
  const { children, variant, ...rest } = props;

  return (
    <AlertStyled variant={variant} {...rest}>
      {children}
    </AlertStyled>
  );
}

Alert.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string.isRequired,
};

Alert.defaultProps = {
  children: null,
};
