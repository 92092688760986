/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Castle from '@castleio/castle-js';

import ShakeAlert from 'components/ShakeAlert';
import useApi from 'core/hooks/useApi';
import { withCurrentUser } from 'core/state/Globals';

import { Button, Flex, Link } from 'core/ui';
import { configureCastle } from 'core/helpers/Castle';

import EmailField from 'form-fields/styled/EmailField';
import PasswordField from 'form-fields/styled/PasswordField';
import CheckboxField from 'form-fields/styled/CheckboxField';
import { errorStyles, FormStylesWrapper } from 'form-fields/rebrand/fieldStyles';

import LoginRouting from 'auth/LoginRouting';
import { withErrorBoundary } from 'components/ErrorBoundary';
import OtpDeliveryChoice from '../components/OtpDeliveryChoice';
import OtpInput from '../components/OtpInput';

import { FormGroup } from '../modals/styles/Modal';

const OptionsRow = styled(Flex).attrs({ justifyContent: 'space-between' })`
  margin-bottom: 1rem;
`;

const ForgotPasswordLink = styled(Link).attrs({ variant: 'blue' })`
  text-align: right;
  text-decoration: underline;
`;

const LoginFormInputs = ({ disabled, buttonText, readOnlyEmail }) => {
  return (
    <>
      <FormStylesWrapper>
        <FormGroup>
          <label htmlFor="login-email">Email</label>
          <EmailField
            id="login-email"
            name="email"
            autoComplete="username"
            data-testid="login-email"
            disabled={disabled}
            readOnly={readOnlyEmail}
            errorStyles={errorStyles}
            required
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="login-password">Password</label>
          <PasswordField
            id="login-password"
            name="password"
            autoComplete="current-password"
            data-testid="login-password"
            disabled={disabled}
            errorStyles={errorStyles}
            required
          />
        </FormGroup>
      </FormStylesWrapper>
      <OptionsRow>
        <div>
          <CheckboxField id="remember-me" name="remember" label="Remember me" disabled={disabled} />
        </div>
        <ForgotPasswordLink href="/users/reset" data-testid="forgot-password">
          Forgot Password?
        </ForgotPasswordLink>
      </OptionsRow>
      <Button
        disabled={disabled}
        type="submit"
        variant="coreBlueLargeAlt"
        data-testid="log-in-submit"
      >
        {buttonText}
      </Button>
    </>
  );
};

const LoginForm = ({
  email,
  onSubmitSuccess,
  reloadUser,
  setShowSocialLogins,
  readOnlyEmail,
  autoRedirect,
}) => {
  const api = useApi();

  useEffect(() => {
    configureCastle();
  }, []);

  const onSubmit = useCallback(
    (values, context) => {
      const stateToRouteMapping = {
        password: '/v1/users/login',
        otp_delivery_method: '/v1/users/otp/deliver',
        otp_required: '/v1/users/otp/verify',
      };

      Castle.createRequestToken().then(castleToken => {
        values.castleToken = castleToken; // eslint-disable-line no-param-reassign
        api
          .post(stateToRouteMapping[values.stage], values)
          .then(async ({ data = {} }) => {
            const { data: { type: userType, id: userID, attributes } = {} } = data;
            if (Object.keys(data).length === 0) {
              datadogLogs.logger.warn(
                `email login success but endpoint returned no data: ${values?.email}`
              );
            }
            datadogLogs.logger.info(`email login success: ${attributes?.email} ${userID}`, {
              userData: { userID, userType, ...attributes },
            });

            datadogRum.setUser({
              id: userID,
              account: userType,
              ...attributes,
            });

            const isRenter = userType === 'renters';
            const isOwner = userType === 'owners';
            const isAdmin = userType === 'admins';

            context.setSubmitting(false);

            reloadUser(attributes.cookie_fields);

            if (onSubmitSuccess) {
              context.setStatus({ success: true });
              let accountType = '';
              if (isRenter) {
                accountType = 'Renter';
              } else if (isOwner) {
                accountType = 'Owner';
              } else if (isAdmin) {
                accountType = 'Admin';
              }

              const submitSuccessData = {
                data: {
                  user: {
                    id: userID,
                    ...attributes,
                  },
                },
                accountType,
                dismissModal: isRenter,
              };

              onSubmitSuccess(submitSuccessData);
              if (isRenter || isAdmin) return;
            }

            if (isOwner && (autoRedirect ?? true)) {
              await LoginRouting(userID);
            }
          })
          .catch(
            ({
              response: {
                status,
                data: { error, stage, otp_provisioning_uri, group, phone_last_4 },
              } = {},
            }) => {
              if (status === 401 && !stage) {
                if (!error) {
                  error = 'Invalid Login'; // eslint-disable-line no-param-reassign
                }
              }

              if (status === 401 && stage === 'otp_required') {
                error = error || 'Two factor authentication required'; // eslint-disable-line no-param-reassign
                context.setFieldValue('stage', stage);
                context.setFieldValue('group', group);
                if (otp_provisioning_uri) {
                  context.setFieldValue('otp_provisioning_uri', otp_provisioning_uri);
                }
                if (setShowSocialLogins) {
                  setShowSocialLogins(false);
                }
              }

              if (status === 401 && stage === 'otp_delivery_method') {
                context.setFieldValue('stage', stage);
                context.setFieldValue('group', group);
                context.setFieldValue('phone_last_4', phone_last_4);
                if (setShowSocialLogins) {
                  setShowSocialLogins(false);
                }
              }

              if (!error) {
                error = 'Something went wrong.'; // eslint-disable-line no-param-reassign
              }

              datadogLogs.logger.error(`email login failure: ${values?.email} ${error} ${status}`, {
                httpStatus: status,
                resError: error,
                stage,
                otp_provisioning_uri,
                group,
                phone_last_4,
                ...(values?.email && {
                  userData: { email: values.email },
                }),
              });

              context.setStatus({ error });
              context.setSubmitting(false);
            }
          );
      });
    },
    [api, onSubmitSuccess, reloadUser, setShowSocialLogins, autoRedirect]
  );

  return (
    <Formik
      initialValues={{
        email: email || '',
        password: '',
        remember: false,
        stage: 'password',
        otp: '',
        otp_provisioning_uri: '',
        phone_last_4: '',
        group: '',
        otp_delivery_method: 'sms',
      }}
      onSubmit={onSubmit}
      render={context => {
        const {
          handleSubmit,
          isSubmitting,
          status: { error, success } = {},
          values: { stage, otp_provisioning_uri, otp, group, phone_last_4, otp_delivery_method },
        } = context;
        const getButtonText = () => {
          if (isSubmitting) {
            return 'Logging In';
          }

          if (success) {
            return 'Logged In';
          }

          return 'Log In';
        };

        const disabled = isSubmitting || success;

        return (
          <div>
            {stage === 'password' && (
              <>
                <ShakeAlert show={!!error && !isSubmitting}>
                  {error || 'Incorrect Login'}
                </ShakeAlert>
                <form id="login-form" className="login-form" noValidate onSubmit={handleSubmit}>
                  <LoginFormInputs
                    disabled={disabled}
                    buttonText={getButtonText()}
                    readOnlyEmail={readOnlyEmail}
                  />
                </form>
              </>
            )}
            {stage === 'otp_required' && (
              <OtpInput
                error={error}
                isSubmitting={isSubmitting}
                disabled={disabled}
                handleSubmit={handleSubmit}
                otp_delivery_method={otp_delivery_method}
                otp_provisioning_uri={otp_provisioning_uri}
                setFieldValue={context.setFieldValue}
                otp={otp}
                group={group}
              />
            )}
            {stage === 'otp_delivery_method' && (
              <OtpDeliveryChoice
                error={error}
                isSubmitting={isSubmitting}
                disabled={disabled}
                handleSubmit={handleSubmit}
                otp_delivery_method={otp_delivery_method}
                setFieldValue={context.setFieldValue}
                phone_last_4={phone_last_4}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export default withErrorBoundary(withCurrentUser(LoginForm, { includeSetter: true }));
