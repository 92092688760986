import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'formik';

import useApi from 'core/hooks/useApi';

import { phoneMaskUS, intPhoneMask } from './helpers/phoneMasks';
import CountryDropDownMenu from './CountryDropDownMenu';

import { FormFeedbackError } from '../styles';
import {
  PhoneInputWrapper,
  DropdownWrapper,
  CountryCodeDisplayed,
  MaskedTextFieldStyled,
} from './styles';

function InternationalPhoneField({ name, placeholder, formik, id: inputId, errorStyles, ...props }) {
  const api = useApi();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [dropDownWidth, setDropDownWidth] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [countryCodeError, setCountryCodeError] = useState(false);
  const dropDownRef = useRef(null);
  const dropDownButtonRef = useRef(null);
  const countryCodeRef = useRef(null);
  const phone = formik.values[name] || {};
  const selectedCountryAlphaCode = phone?.country_alpha_code || 'US';
  const selectedCountryCode = phone?.country_code || '+1';
  const defaultPlaceholder = selectedCountryAlphaCode === 'US' ? '(555) 555-5555' : null;

  // change alpha and numeric country code values when country is selected
  const handleCountrySelect = country => {
    formik.setFieldValue(`${name}.country_alpha_code`, country.abbrev);
    formik.setFieldValue(`${name}.country_code`, country.code);
    formik.setFieldValue(`${name}.number`, '');
    setMenuExpanded(false);
  };

  // recalculate dropdown wrapper width when country code is selected
  // because the amount of characters in the country code causes the width to change
  useEffect(() => {
    const countryCodeWidth = countryCodeRef?.current?.offsetWidth;
    const dropDownButtonWidth = dropDownButtonRef?.current?.offsetWidth;
    const getDropDownWidth = countryCodeWidth + dropDownButtonWidth + 10;
    if (getDropDownWidth !== dropDownWidth) {
      setDropDownWidth(getDropDownWidth);
    }
  }, [dropDownWidth, menuExpanded]);

  if (!phone?.country_alpha_code || !phone?.country_code) {
    console.warn(
      'international phone field must have a country alpha code and country code provided from the formik values'
    );
  }

  const handleGetCountryCodes = () => {
    api
      .post('/graphql', {
        query: `query { countryCodes { abbrev, code, name, flagUrl } }`,
      })
      .then((data) => {
        const countryCodes = data?.data?.data?.countryCodes;

        if (countryCodes) {
          setCountryData(countryCodes);
        } else {
          const errors = data?.data?.errors || '';
          console.error('Error getting country codes', errors);
          setCountryCodeError(true);
        }
      })
      .catch((err) => {
        console.error('Error getting country codes', err);
        setCountryCodeError(true);
      });
  };

  return (
    <>
      <PhoneInputWrapper>
        <DropdownWrapper>
          <CountryDropDownMenu
            handleCountrySelect={handleCountrySelect}
            setMenuExpanded={setMenuExpanded}
            menuExpanded={menuExpanded}
            selectedCountryAlphaCode={selectedCountryAlphaCode}
            handleGetCountryCodes={handleGetCountryCodes}
            countryData={countryData}
            dropDownRef={dropDownRef}
            dropDownButtonRef={dropDownButtonRef}
            countryCodeError={countryCodeError}
          />
          <CountryCodeDisplayed
            ref={countryCodeRef}
            aria-label={`Selected: ${selectedCountryAlphaCode} country code`}
          >
            {selectedCountryCode}
          </CountryCodeDisplayed>
        </DropdownWrapper>
        <MaskedTextFieldStyled
          {...props}
          id={inputId || 'phone-number'}
          name={`${name}.number`}
          guide={false}
          errorsEnabled={false}
          placeholder={placeholder || defaultPlaceholder}
          mask={selectedCountryAlphaCode === 'US' ? phoneMaskUS : intPhoneMask}
          // padding left is dynamic based on the width of the dropdown wrapper
          style={{ paddingLeft: `${dropDownWidth}px` }}
        />
      </PhoneInputWrapper>
      {formik.touched?.phone?.number && (formik.errors?.phone || formik.errors?.phone?.number) && (
        <FormFeedbackError className="error" style={errorStyles}>
          {formik.errors?.phone?.number || formik.errors?.phone}
        </FormFeedbackError>
      )}
    </>
  );
}

export default connect(InternationalPhoneField);
