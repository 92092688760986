import React from 'react';
import { withTheme, ThemeProvider } from 'styled-components';

import { StyledModal } from './modalStyles';

export default withTheme(function Modal(props) {
  const {
    theme,
    mode: forceMode,
    size: forceSize,
    ...restProps
  } = props;

  const mode = forceMode || theme.mode || 'light';
  const size = forceSize || 'auto';

  return (
    <ThemeProvider theme={{ ...theme, mode, size }}>
      <StyledModal {...restProps} />
    </ThemeProvider>
  );
});

export * from './modalStyles';
