import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Solid
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faCar as faCarSolid } from '@fortawesome/free-solid-svg-icons/faCar';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';

// Regular
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons/faCreditCard';
import { faQuestionCircle as faQuestionCircleRegular } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import { faCar as faCarRegular } from '@fortawesome/pro-regular-svg-icons/faCar';
import { faFileAlt as faFile } from '@fortawesome/free-regular-svg-icons/faFileAlt';
import { faStars as faStarsRegular } from '@fortawesome/pro-regular-svg-icons/faStars';
import { faMapMarkedAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkedAlt';
import { faDog } from '@fortawesome/pro-regular-svg-icons/faDog';
import { faSmokingBan } from '@fortawesome/pro-regular-svg-icons/faSmokingBan';
import { faUserCheck } from '@fortawesome/pro-regular-svg-icons/faUserCheck';

// Light
import { faCheck as faLightCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimes as faLightTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

// Brands
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons/faGooglePlusG';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faPinterestP } from '@fortawesome/free-brands-svg-icons/faPinterestP';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';

// We import the CSS globally in bootstrap.jsx or styled.jsx so we don't need to import it here
// This fixes a duplicate import and an issue where icons rendered large until they scaled down
config.autoAddCss = false;

// Custom
const customSolidHeart = {
  prefix: 'fas',
  iconName: 'heart',
  icon: [
    16,
    16,
    [],
    'f004',
    'M14.003 9.214c-.626.75-1.227 1.427-6.008 5.232-4.806-3.805-5.382-4.48-6.008-5.232-2.303-2.754-1.677-5.857.15-7.16 1.628-1.15 3.93-.725 5.858 1.478 1.927-2.178 4.23-2.63 5.857-1.477 1.828 1.302 2.48 4.405.15 7.16z',
  ],
};

const customRegularHeart = {
  prefix: 'far',
  iconName: 'heart',
  icon: [
    16,
    16,
    [],
    'f004',
    'M14.003 9.214c-.626.75-1.227 1.427-6.008 5.232-4.806-3.805-5.382-4.48-6.008-5.232-2.303-2.754-1.677-5.857.15-7.16 1.628-1.15 3.93-.725 5.858 1.478 1.927-2.178 4.23-2.63 5.857-1.477 1.828 1.302 2.48 4.405.15 7.16z',
  ],
};

const customBolt = {
  prefix: 'fas',
  iconName: 'bolt',
  icon: [
    10,
    15,
    [],
    'f0e7',
    'M8.14.07a.41.41,0,0,0-.53.07L.11,8.47A.41.41,0,0,0,0,8.92a.41.41,0,0,0,.38.25H4L1.7,14.42a.4.4,0,0,0,.16.51.34.34,0,0,0,.22.07.41.41,0,0,0,.31-.14l7.5-8.33A.41.41,0,0,0,10,6.08a.43.43,0,0,0-.38-.25H6.05L8.3.58A.41.41,0,0,0,8.14.07Z',
  ],
};

const customBars = {
  prefix: 'far',
  iconName: 'bars',
  icon: [
    448,
    512,
    [],
    'f0c9',
    'M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z',
  ],
};

library.add(
  // Solid
  faSync,
  faCheck,
  fasStar,
  faSearch,
  faFilter,
  faCalendar,
  faQuestionCircle,
  faExclamationCircle,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faTimes,
  faMapMarkerAlt,
  customBolt,
  customSolidHeart,
  faCircle,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faUserFriends,
  faCarSolid,
  faSpinner,

  // Regular
  faEnvelope,
  farStar,
  faCreditCard,
  faClock,
  customRegularHeart,
  customBars,
  faQuestionCircleRegular,
  faCalendarAlt,
  faCarRegular,
  faFile,
  faStarsRegular,
  faMapMarkedAlt,
  faDog,
  faSmokingBan,
  faUserCheck,

  // Light
  faLightCheck,
  faLightTimes,

  // Brands
  faFacebook,
  faFacebookF,
  faTwitter,
  faGoogle,
  faGooglePlusG,
  faInstagram,
  faPinterest,
  faPinterestP,
  faApple
);

export const domReplace = () => {
  dom.watch();
};

export default FontAwesomeIcon;
