import React, { useEffect } from 'react';

import { useImagesPreloader } from 'core/hooks/useImagePreloader';

import { CountryFlag, NoCountryFlag } from './styles';

const RenderCountries = props => {
  const {
    initialLoad,
    selectedCountryAlphaCode,
    handleCountrySelect,
    handleKeyPress,
    setInitialFlagsLoaded,
    countryData
  } = props;
  // move the united states to the top of the countryData array
  const usCountry = countryData.find(country => country.abbrev === 'US')
  countryData.splice(countryData.indexOf(usCountry), 1);
  countryData.unshift(usCountry);

  const firstFewCountries = countryData.slice(0, 10);
  const remainingCountries = countryData.slice(10);
  const initialFlags = firstFewCountries.map(country => country.flagUrl);
  // preload the first 10 flags so we know when the other flags are ready to load
  const { imagesPreloaded: initialFlagsPreloaded } = useImagesPreloader(initialFlags);

  // when initialFlagsLoaded is true, we allow then the remaining flags to load
  useEffect(() => {
    if (initialFlagsPreloaded) {
      setInitialFlagsLoaded(true);
    }
  }, [initialFlagsPreloaded, setInitialFlagsLoaded]);

  return (initialLoad ? firstFewCountries : remainingCountries).map(country => {
    const elementId = `${country.abbrev}-selection`;
    
    return (
      <li
        id={elementId}
        role="option"
        aria-selected={selectedCountryAlphaCode === country}
        tabIndex={0}
        onClick={() => handleCountrySelect(country)}
        onKeyDown={e => handleKeyPress(e, country)}
        key={country.abbrev}
      >
        {country.flagUrl ? (
          <CountryFlag src={country.flagUrl} alt={`${country.abbrev} flag`} />
        ) : (
          <NoCountryFlag />
        )}
        {`${country.abbrev}: ${country.code}`}
      </li>
    );
  });
};

export default React.memo(RenderCountries);
