import React, { useState, useEffect } from 'react';

import RenderCountries from './RenderCountries';

import { CountryDropDown, DownArrow, CountriesMenu, CountryFlag, NoCountryFlag } from './styles';

const CountryDropDownMenu = props => {
  const {
    handleCountrySelect,
    handleGetCountryCodes,
    setMenuExpanded,
    menuExpanded,
    selectedCountryAlphaCode,
    dropDownRef,
    dropDownButtonRef,
    countryData,
    countryCodeError
  } = props;
  const [initialFlagsLoaded, setInitialFlagsLoaded] = useState(false);
  const flagURL = `https://rvs-country-flags.s3.amazonaws.com/${(selectedCountryAlphaCode.toLowerCase()) || 'us'}.svg`;

  // handle keyboard navigation when dropdown is open
  const handleKeyPress = (e, country) => {
    if (e.key === 'Enter' && country) {
      handleCountrySelect(country);
    }

    if (e.key === 'Escape') {
      setMenuExpanded(false);
      e.stopPropagation();
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prevSibling = e.target?.previousSibling;
      if (prevSibling) {
        prevSibling.focus();
      }
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const nextSibling = e.target?.nextSibling;
      if (nextSibling) {
        nextSibling.focus();
      }
    }
  };

  const handleMenuExpand = () => {
    setMenuExpanded(!menuExpanded);
    // we only want to fetch country codes once
    if (!countryData.length) {
      handleGetCountryCodes();
    }
  };

  // add event listener to close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        dropDownRef?.current &&
        dropDownButtonRef?.current &&
        !dropDownRef.current.contains(event.target) &&
        !dropDownButtonRef.current.contains(event.target)
      ) {
        setMenuExpanded(false);
        // this prevents sign up modal from also closing
        event.stopPropagation();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  // focus on first country in list when dropdown is opened
  useEffect(() => {
    if (!countryData.length || !menuExpanded) return;

    const firstCountry = dropDownRef.current?.firstChild?.firstChild;
    
    if (firstCountry) {
      firstCountry.focus({ focusVisible: true });
    }
  }, [dropDownRef, countryData, menuExpanded]);

  return (
    <CountryDropDown>
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded={menuExpanded}
        aria-controls="countries-menu"
        onClick={() => handleMenuExpand()}
        onKeyDown={e => handleKeyPress(e)}
        ref={dropDownButtonRef}
      >
        {selectedCountryAlphaCode ? (
          <CountryFlag
            src={flagURL}
            alt={`${selectedCountryAlphaCode} flag`}
          />
        ) : (
          <NoCountryFlag />
        )}
        <DownArrow aria-hidden />
        <span className="sr-only">Choose country code</span>
      </button>
      {menuExpanded && (
        <CountriesMenu
          id="countries-menu"
          ref={dropDownRef}
          role="listbox"
          aria-labelledby="countries-menu"
        >
          {countryCodeError && (
            <p style={{margin: '0.5rem', color: '#c11e19'}}>Error Loading Country Codes</p>
          )}
          {!!countryData?.length && (
            <ul>
              {/* render the first 10 or so flags (initialLoad) */}
              <RenderCountries
                initialLoad
                countryData={countryData}
                selectedCountryAlphaCode={selectedCountryAlphaCode}
                handleCountrySelect={handleCountrySelect}
                handleKeyPress={handleKeyPress}
                setInitialFlagsLoaded={setInitialFlagsLoaded}
              />
              {/* render the rest of the flags */}
              {initialFlagsLoaded && (
                <RenderCountries
                  countryData={countryData}
                  selectedCountryAlphaCode={selectedCountryAlphaCode}
                  handleCountrySelect={handleCountrySelect}
                  handleKeyPress={handleKeyPress}
                  setInitialFlagsLoaded={setInitialFlagsLoaded}
                />
              )}
            </ul>
          )}
        </CountriesMenu>
      )}
    </CountryDropDown>
  );
};

export default CountryDropDownMenu;
