import React from 'react';
import PropTypes from 'prop-types';

import { SocialAuthActions } from 'core/helpers/SocialAuth';
import Collapse from 'components/Collapse';
import { Button } from 'core/ui';

import SocialAuthButtons from './SocialAuthButtons';
import SocialIcon from './icons/SocialIcon';
import SocialRegisterForm from '../forms/SocialRegisterForm';
import RegisterForm from '../forms/RegisterForm';

import { Heading, ContentWrapper, Divider } from '../modals/styles/Modal';

function RegisterRenterChooser(props) {
  const {
    onShowRegisterForm,
    onRegisterSuccess,
    showRegisterForm,
    showSocialAuthForm,
    userData,
    onRegisterSubmitSuccess,
    onStartSocialSignUpSuccess,
  } = props;

  return (
    <ContentWrapper id="sign-up-part2">
      <Collapse isOpen={!showSocialAuthForm}>
        <Heading>Welcome to RVshare</Heading>
        <SocialAuthButtons
          action={SocialAuthActions.REGISTER}
          onSubmitSuccess={onRegisterSuccess}
          onStartSocialSignUpSuccess={onStartSocialSignUpSuccess}
        />
        <Divider>
          <span>or</span>
        </Divider>
      </Collapse>

      {/* Register with email form */}
      <Collapse isOpen={showRegisterForm && !showSocialAuthForm}>
        <RegisterForm onSubmitSuccess={onRegisterSubmitSuccess} />
      </Collapse>

      {/* Register with social form is active after receiving authorization from provider */}
      <Collapse isOpen={showSocialAuthForm && !showRegisterForm}>
        <SocialRegisterForm userData={userData} onSubmitSuccess={onRegisterSubmitSuccess} />
      </Collapse>

      <Collapse isOpen={!showRegisterForm && !showSocialAuthForm}>
        <Button
          id="register-email-btn"
          variant="coreBlueBorderLargeAlt"
          onClick={onShowRegisterForm}
          data-testid="register-email-btn"
        >
          <SocialIcon type="envelope" />
          Sign Up with Email
        </Button>
      </Collapse>
    </ContentWrapper>
  );
}

RegisterRenterChooser.propTypes = {
  onShowRegisterForm: PropTypes.func.isRequired,
  showRegisterForm: PropTypes.bool.isRequired,
  onRegisterSuccess: PropTypes.func,
};

RegisterRenterChooser.defaultProps = {
  onRegisterSuccess: null,
};

export default RegisterRenterChooser;
