import styled, { css } from 'styled-components';

export const TextInput = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${props => props.theme.colors.bodyColor};
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${props => props.theme.borders.lightGrey};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &[disabled] {
    background-color: ${props => props.theme.colors.veryLightGrey};
    color: ${props => props.theme.colors.mutedGrey};
    cursor: not-allowed;
    opacity: 0.95;
  }

  &:focus {
    color: ${props => props.theme.colors.darkBlue};
    background-color: #fff;
    border-color: ${props => props.theme.colors.darkBlue};
    outline: 0;
  }

  &&& {
    ${props =>
      props.invalid &&
      css`
        border-color: ${props.theme.colors.error};
        border-width: 2px;

        &:focus {
          border-color: ${props.theme.colors.error};
        }
      `}
  }
`;

export const FormFeedback = styled.div`
  margin-top: 0.25rem;
  font-size: 80%;
  font-weight: 400;
`;

export const FormFeedbackError = styled(FormFeedback)`
  color: ${props => props.theme.colors.error};
`;

export const FormFeedbackWarning = styled(FormFeedback)`
  color: ${props => props.theme.colors.warningYellow};
`;

export const InputGroupWrapper = styled.div`
  border: 1px solid
    ${props => (props.focused ? props.theme.colors.primaryBlue : props.theme.borders.lightGrey)};
  border-radius: 4px;
  display: table;
  overflow: hidden;
  width: 100%;
  color: ${props => props.theme.colors.bodyColor};

  ${TextInput} {
    border: none;
  }
`;

export const InputGroupTextPrepend = styled.label`
  ${props =>
    props.disabled &&
    `
    background-color:  ${props.theme.colors.veryLightGrey};
    cursor: not-allowed;
  `}
  display: table-cell;
  ${props =>
    props.isMoney &&
    `
    min-width: 55px;
  `}
  padding: 8px;
  vertical-align: middle;
`;

export const InputGroupPrepend = styled.div`
  display: table-cell;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  ${TextInput} {
    padding: 0;
  }
`;
