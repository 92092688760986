import React from 'react';
import ReactDOM from 'react-dom';
import { load } from 'hypernova';
import { once } from 'lodash';
import get from 'just-safe-get';
import { createBrowserHistory as createHistory } from 'history';
import { Provider as UnstatedProvider } from 'unstated';
import { ThemeProvider } from 'styled-components';
import canUseDOM from 'can-use-dom';

import { PagePath } from 'core/state/PagePathContext';
import { parseGlobals } from './base';
import globalTheme from '../styles/globalTheme';
import ModalsContainer from '../state/Modals';
import GlobalsContainer from '../state/Globals';

const parseStateFromDom = once(() => {
  const prefix = 'preloaded-state__';
  const nodes = Array.from(document.querySelectorAll(`meta[id*="${prefix}"]`));

  const raw = nodes.reduce((acc, node) => {
    const key = node.id.substr(prefix.length);
    const value = JSON.parse(node.content);

    return {
      ...acc,
      [key]: value,
    };
  }, {});

  return parseGlobals(raw);
});

/*
 * Calling: getStateFromDOM()
 * Returns: domState
 *
 * Calling: getStateFromDOM('env.apiHost')
 * Returns: domState.env.apiHost
 */
export function getStateFromDOM(path) {
  if (!canUseDOM) {
    return undefined;
  }

  const domState = parseStateFromDom();

  if (path) {
    return get(domState, path);
  }

  return domState;
}

// memoized global objects
export const getBrowserHistory = once(() => createHistory());
const getModalsContainer = once(() => new ModalsContainer());
const getGlobalsContainer = once((domState) => new GlobalsContainer(domState));

export function withUnstatedProvider(BaseComponent, inject = []) {
  return props => (
    <UnstatedProvider inject={inject}>
      <BaseComponent {...props} />
    </UnstatedProvider>
  );
}

export function renderContainer(name, Component) {
  const { node, data: props } = canUseDOM ? (load(name)[0] || {}) : {};

  if (!node) {
    return;
  }

  const domState = once(getStateFromDOM)()

  // Wrap component with unstated globals and global theme
  // so that they can be freely used by all
  const element = (
    <PagePath.Provider
      value={{ pathname: domState.location?.pathname, search: domState.location?.search }}
    >
      <ThemeProvider theme={globalTheme}>
        <UnstatedProvider inject={[getModalsContainer(), getGlobalsContainer(domState)]}>
          <Component {...props} />
        </UnstatedProvider>
      </ThemeProvider>
    </PagePath.Provider>
  );

  if (node.innerHTML) {
    ReactDOM.hydrate(element, node);
  } else {
    ReactDOM.render(element, node);
  }
}

export function renderContainers(containers) {
  Object.keys(containers).forEach(name => renderContainer(name, containers[name]));
}

export function registerModals(modalsMap) {
  const modals = getModalsContainer();

  Object.keys(modalsMap).forEach(key => {
    modals.register(key, modalsMap[key]);
  });
}
