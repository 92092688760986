// polyfills
import 'element-closest';
import 'custom-event-polyfill';

import EnsureAuthModal from 'auth/modals/EnsureAuthModal';
import LoginModal from 'auth/modals/LoginModal';
import RegisterModal from 'auth/modals/RegisterModal';

import { domReplace as replaceFaIcons } from 'core/ui/Icon';
import ModalRoot from 'components/ModalRoot';
import CookieBanner from 'components/CookieBanner';
import InbentaChat from 'components/InbentaChat';

import initiateTracking from 'tracking/legacy/event_tracking';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import { renderContainer, renderContainers, registerModals, getStateFromDOM } from './utils/client';

function registerBaseModals() {
  registerModals({
    EnsureAuth: EnsureAuthModal,
    Login: LoginModal,
    Register: RegisterModal,
  });
}

function renderBaseComponents() {
  renderContainer('ModalRoot', ModalRoot);
  renderContainer('CookieBanner', CookieBanner);
  renderContainer('InbentaChat', InbentaChat);
}

function renderPageComponents(components) {
  if (components) {
    renderContainers(components);
  }
}

function initFontAwesome() {
  // Replace i.fa tags rendered from rails
  replaceFaIcons();
}

function initTracking() {
  // Initiate tracking
  initiateTracking(getStateFromDOM());
}

function initDatadog() {
  const { rack_env, datadog_app_id, datadog_client_token, datadog_rum_sample_rate, datadog_replay_sample_rate, datadog_browser_sample_rate, datadog_enable_session_replay } = getStateFromDOM('env');
  // Init the datadog browser-rum package to send client side monitoring
  // metrics to datadog. https://docs.datadoghq.com/real_user_monitoring/browser/
  datadogRum.init({
    applicationId: datadog_app_id,
    clientToken: datadog_client_token,
    site: 'datadoghq.com',
    service:'marketplace',
    env: rack_env,
    // eslint-disable-next-line no-undef
    version: COMMIT_REVISION || rack_env,
    sessionSampleRate: datadog_rum_sample_rate || 20, // 20% of traffic
    sessionReplaySampleRate: datadog_replay_sample_rate || 0, // 0% of traffic
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ["feature_flags", "clickmap"],
    allowedTracingUrls: ["https://api.rvshare.com"],
    startSessionReplayRecordingManually: true
  });

  if (datadog_enable_session_replay) {
    datadogRum.startSessionReplayRecording();
  }

  // Init the datadog browser-logs package to send client side errors to datadog
  // Default behavior of sending all errors is disabled to prevent over logging
  // https://docs.datadoghq.com/logs/log_collection/javascript/
  datadogLogs.init({
    clientToken: datadog_client_token,
    site: 'datadoghq.com',
    service:'marketplace',
    env: rack_env,
    // eslint-disable-next-line no-undef
    version: COMMIT_REVISION || rack_env,
    forwardErrorsToLogs: false, // disabled, we only use custom logs for now
    sessionSampleRate: datadog_browser_sample_rate || 20, // 20% of traffic
  });

}

export const init = ({ components } = {}) => {
  // react
  registerBaseModals();

  renderBaseComponents();
  renderPageComponents(components);

  // plain ole javascript
  initFontAwesome();
  initTracking();

  // Datadog Monitoring
  initDatadog();
};
