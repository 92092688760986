import React from 'react';
import styled from 'styled-components';

import FacebookButton from './FacebookButton';
import AppleButton from './AppleButton';
import GoogleButton from './GoogleButton';

const SocialWrapper = styled.div`
  button:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

export default function SocialAuthButtons({ action, onSubmitSuccess, onStartSocialSignUpSuccess }) {
  const buttonProps = {action, onSubmitSuccess, onStartSocialSignUpSuccess}

  return (
    <SocialWrapper>
      <FacebookButton {...buttonProps} />
      <GoogleButton {...buttonProps} />
      <AppleButton {...buttonProps} />
    </SocialWrapper>
  );
};
