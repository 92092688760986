import { useEffect, useCallback } from 'react';
import canUseDom from 'can-use-dom';

import { onKeyPress, convertToAsciiEquivalent, getAsciiCode } from './keys';

/**
 * Source taken from https://github.com/haldarmahesh/use-key-hook
 * (put it in our codebase due to SSR error)
 * https://github.com/haldarmahesh/use-key-hook/issues/15
 */

function useKey(callback, { detectKeys = [], keyEvent = 'keydown' }, dependencies = []) {
  const allowedKeys = convertToAsciiEquivalent(detectKeys);

  const handleEvent = useCallback(
    (event) => {
      const asciiCode = getAsciiCode(event);
      return onKeyPress(asciiCode, callback, allowedKeys);
    },
    [allowedKeys, callback],
  );

  useEffect(() => {
    if (canUseDom) {
      window.document.addEventListener(keyEvent, handleEvent);
    }
    return () => {
      window.document.removeEventListener(keyEvent, handleEvent);
    };
    // eslint-disable-next-line
  }, dependencies.concat(handleEvent));
}

export default useKey;
