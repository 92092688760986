import axios from 'axios';

export const normalizeServerErrors = responseData => {
  if (!responseData || !responseData.errors) {
    return {};
  }

  const { errors } = responseData;

  return Object.keys(errors).reduce((acc, key) => {
    let value = errors[key] || null;

    if (Array.isArray(value)) {
      // Choose first
      [value] = value;
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

// used for when the response.data does not contain an errors key
export const addErrorsKey = responseData => {
  try {
    if (!responseData) {
      return null;
    }

    if (responseData?.errors) {
      return responseData;
    }

    return {
      errors: responseData,
    };
  } catch (e) {
    return responseData;
  }
};

/**
 *
 * @param {Object} [config={}] - (optional) Options for initializing axios
 * @param {Object} [options={}] - (optional) Additional options that are not passed to Axios
 * @param {boolean} options.includeCSRF - Whether or not the pages CSRF token should be included in request headers
 *
 */
export const createAxios = (config = {}, options = {}) => {
  const headers = { 
    'X-Requested-With': 'XMLHttpRequest',
    'CF-Access-Client-Id': process.env.CLOUDFLARE_ACCESS_CLIENT_ID,
    'CF-Access-Client-Secret': process.env.CLOUDFLARE_ACCESS_CLIENT_SECRET
  };

  if (options.includeCSRF) {
    headers['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').content;
  }

  return axios.create({
    withCredentials: true,
    headers,
    ...config,
  });
}
