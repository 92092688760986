import React from 'react';
import once from 'lodash/once';
import canUseDOM from 'can-use-dom';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import { createAxios } from '../helpers/API';

import { getStateFromDOM } from '../utils/client';

export const getApiInstance = once(() =>
  createAxios({ baseURL: getStateFromDOM('env.apiHost') }));

// Return a memoized instance of the API from this hook.
const useApi = () => (canUseDOM ? getApiInstance() : {});

export default useApi;

export const withRVshareApi = BaseComponent => (props) => {
  const api = useApi();

  return (
    <BaseComponent
      {...props}
      api={api}
    />
  );
};

export const getApolloClient = once(() => {
  const client = new ApolloClient({
    uri: `${getStateFromDOM('env.apiHost')}/graphql`,
    cache: new InMemoryCache(),
    credentials: 'include'
  });

  return client;
})

export const useApollo = () => (canUseDOM ? getApolloClient() : {});

export const withApollo = BaseComponent => (props) => {
  const client = useApollo();

  return (
    <BaseComponent
      {...props}
      apollo={client}
    />
  );
};
