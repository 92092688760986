import styled from 'styled-components';

import { colors } from 'core/styles/scss-variables';

export const errorStyles = {
  color: colors.errorRed,
};

export const FormStylesWrapper = styled.div`
  position: relative;

  label {
    line-height: 20px;
    font-size: 0.9rem;
    background-color: white;
    padding: 0 5px;
    top: -10px;
    margin: 0;
    left: 10px;
    position: absolute;
    z-index: 1;

    &:has(+ input:disabled) {
      color: ${colors.mutedGrey};
    }
  }

  input {
    line-height: 1;
    padding: 1rem;
    height: auto;
    border-color: ${colors.grey400};

    &:focus {
      border-color: ${colors.coreDarkBlue};
      border-width: 2px;
      // counter the border-width nudging other inputs
      margin-bottom: -2px;
    }

    &:disabled {
      opacity: 1;
      border-color: ${colors.grey400};
      background-color: ${colors.grey100};
    }
  }
`;
