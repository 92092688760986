import React, { createContext, useCallback, useMemo } from 'react';
import { Container, Subscribe } from 'unstated';
import { memoize } from 'lodash-es';

export const GlobalsContext = createContext({});

export default class GlobalsContainer extends Container {
  static displayName = 'Globals';

  constructor(initialValues = {}) {
    super();

    this.state = initialValues;
  }
}

export const withGlobals = (BaseComponent, { includeSetter = false } = {}) => props => (
  <Subscribe to={[GlobalsContainer]}>
    {context => {
      const globalProps = {
        globals: context.state,
      };

      if (includeSetter) {
        const boundSetter = context.setState.bind(context);

        globalProps.setGlobals = boundSetter;
      }

      return <BaseComponent {...props} {...globalProps} />;
    }}
  </Subscribe>
);

const firstCharUpper = str => str.charAt(0).toUpperCase() + str.slice(1);
const createScopedSetter = memoize((scope, setState) => value => {
  if (typeof value === 'function') {
    setState(prev => {
      const newValue = value(prev[scope]);

      // If returning undefined, don't change anything
      if (newValue === undefined) {
        return undefined;
      }

      return {
        ...prev,
        [scope]: newValue,
      };
    });
  } else {
    setState({ [scope]: value });
  }
});

// Scoped to a single global property
export const withGlobal = (scope, BaseComponent, { includeSetter = false } = {}) => props => (
  <Subscribe to={[GlobalsContainer]}>
    {context => {
      const scopedProps = {
        [scope]: context.state[scope],
      };

      if (includeSetter) {
        const key = `set${firstCharUpper(scope)}`;
        const boundSetter = context.setState.bind(context);

        scopedProps[key] = createScopedSetter(scope, boundSetter);
      }

      return <BaseComponent {...props} {...scopedProps} />;
    }}
  </Subscribe>
);

export const withCookieBanner = (BaseComponent, { includeSetter = false } = {}) =>
withGlobal(
  'cookieBanner',
  ({ cookieBanner, setCookieBanner, ...props }) => {
    const scopedProps = {
      cookieBanner,
    };

    if (includeSetter) {
      scopedProps.setCookieBanner = setCookieBanner;
    }

    return <BaseComponent {...props} {...scopedProps} />;
  },
  { includeSetter }
);

export const withCurrentUser = (BaseComponent, { includeSetter = false } = {}) =>
  withGlobal(
    'currentUser',
    ({ currentUser, setCurrentUser, ...props }) => {
      const scopedProps = {
        currentUser,
        isLoggedIn: !!currentUser,
      };
      // The login endpoint doesn't return the full user
      // so instead of passing a value to this callback
      // we just load the user from the cookie and call
      // setCurrentUser implicitly here if it exists
      const reloadUser = useCallback((user) => {
        if (user && !currentUser) {
          setCurrentUser(user);
        }
      }, [setCurrentUser]);

      if (includeSetter) {
        scopedProps.reloadUser = reloadUser;
      }

      return <BaseComponent {...props} {...scopedProps} />;
    },
    { includeSetter }
  );

export const withTravelPreferences = (BaseComponent, { includeSetter = false } = {}) =>
  withGlobal(
    'travelPreferences',
    ({ travelPreferences, setTravelPreferences, ...props }) => {
      const scopedProps = {
        travelPreferences,
      };

      if (includeSetter) {
        scopedProps.setTravelPreferences = setTravelPreferences;
      }

      return <BaseComponent {...props} {...scopedProps} />;
    },
    { includeSetter }
  );

export const withSearchHistograms = (BaseComponent, { includeSetter = false } = {}) =>
  withGlobal(
    'searchHistograms',
    ({ searchHistograms, setSearchHistograms, ...props }) => {
      const scopedProps = {
        searchHistograms,
      };

      if (includeSetter) {
        scopedProps.setSearchHistograms = setSearchHistograms;
      }

      return <BaseComponent {...props} {...scopedProps} />;
    },
    { includeSetter }
  );

export const withDeviceDetection = BaseComponent =>
  withGlobal('isMobile', ({ isMobile, ...props }) => {
    const device = useMemo(
      () => ({
        isMobile: isMobile || false,
        isDesktop: !isMobile,
      }),
      [isMobile]
    );

    return <BaseComponent {...props} device={device} />;
  });

