export const firstColumnItems = [
  {
    name: 'About us',
    href: '/about',
  },
  {
    name: 'Trust and Safety',
    href: '/trust-and-safety',
  },
  {
    name: 'Careers',
    href: '/careers',
  },
  {
    name: 'Press',
    href: '/press',
  },
  {
    name: 'How it Works',
    href: '/how-it-works',
  },
  {
    name: 'Blog',
    href: '/blog/',
  },
  {
    name: 'Affiliates Program',
    href: '/affiliates',
  },
  {
    name: 'Campground Program',
    href: 'https://pages.rvshare.com/campground-partnerships/',
  },
  {
    name: 'Corporate and Festival Partnership Program',
    href: 'https://pages.rvshare.com/event-rv-rentals',
  },
];

export const secondColumnItems = [
  {
    name: 'National Parks',
    href: '/national-parks',
    'data-label': 'National Parks',
    'data-link': 'National Parks',
  },
  {
    name: 'State Parks',
    href: '/state-parks',
    'data-label': 'State Parks',
    'data-link': 'State Parks',
  },
  {
    name: 'Events',
    href: '/events',
    'data-label': 'Events',
    'data-link': 'Events',
  },
  {
    name: 'Campgrounds',
    href: '/blog/top-10-campgrounds',
    'data-label': 'Top 10 Campgrounds',
    'data-link': 'Top 10 Campgrounds',
  },
  {
    name: 'Dump Stations',
    href: '/dumpstations',
    'data-label': 'Dump Stations',
    'data-link': 'Dump Stations',
  },
  {
    name: 'Road Trips',
    href: '/road-trips',
  },
  {
    name: 'RV Information',
    href: '/rv',
  },
  {
    name: 'RV Storage',
    href: '/rv-storage',
  },
  {
    name: 'RV Repair Companies',
    href: '/rv-repair-companies',
  },
];

export const thirdColumnItems = [
  {
    name: 'Search RV Rentals',
    href: '/rv-rental',
  },
  {
    name: 'Worry-free Rental Guarantee',
    href: '/rental-guarantee',
  },
  {
    name: 'Temporary Housing',
    href: '/temporary-housing',
  },
  {
    name: 'One Way RV Rentals',
    href: '/one-way-rv-rentals',
  },
  {
    name: 'Help and Support',
    href: 'https://rvshare.my.site.com/s/topic/0TOf40000004xZbGAI/renter-information',
  },
  {
    name: 'Protection Products & Services',
    href: '/products-renter',
  },
];

export const fourthColumnItems = [
  {
    name: 'List Your RV',
    href: '/list-your-rv',
  },
  {
    name: 'List a Park Model',
    href: 'https://pages.rvshare.com/parkmodels/',
  },
  {
    name: 'Owner Toolkit',
    href: 'https://owner-toolkit.rvshare.com/',
  },
  {
    name: 'RV Products and Services',
    href: '/products-owner',
  },
  {
    name: 'Disaster Relief RV Rentals',
    href: 'https://pages.rvshare.com/rv-temporary-housing/',
  },
  {
    name: 'Help and Support',
    href: 'https://rvshare.my.site.com/s/topic/0TOf40000004xZaGAI/owner-information',
    'data-link': 'Help',
  },
];
