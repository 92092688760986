import * as Dates from 'core/helpers/Dates';
import * as Query from 'core/helpers/Query';

import segment from './SafeTracking';

// Breakout channel and band/non brand
const parseBrandChannel = (channel, key, value) => {
  if (!value) {
    return {};
  }

  const split = value.split('.');

  return {
    [key]: value,
    mktg_code: `${key}=${value}`,
    mktg_channel: channel,
    channel: split[0],
    brand_v_non_brand: split[1],
  };
};

// Breakout keyword and matchtype
const parseKeyword = (keyword) => {
  if (!keyword) {
    return {};
  }

  const split = keyword.split('.');

  return {
    match_type: split[0],
    keyword: split[1],
  };
};

const targets = ['kwd', 'dsa', 'aud', 'pla'];

export const breakoutTraits = (search) => {
  const {
    semid,
    psocid,
    emlid,
    affid,
    socialid,
    keyword,
    ...restQuery
  } = Query.parse(search);

  const traits = {
    ...restQuery,
    ...parseBrandChannel('sem', 'semid', semid),
    ...parseBrandChannel('psoc', 'psocid', psocid),
    ...parseBrandChannel('aff', 'affid', affid),
    ...parseBrandChannel('eml', 'emlid', emlid),
    ...parseBrandChannel('social', 'socialid', socialid),
    ...parseKeyword(keyword),
  };

  return Object.keys(traits).reduce((acc, trait) => {
    let key = trait;
    let value = traits[key];

    // If value is missing and key starts with target ID, split on hyphen
    if (value === '' && targets.some(x => key.startsWith(x))) {
      ([key, value] = key.split('-'));
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

export const parseUser = (currentUser) => {
  if (!currentUser) {
    return null;
  }

  const userId = currentUser.id || currentUser.user_id || undefined;

  return {
    // Standard segment traits
    createdAt: Dates.maybeSqlToISODate(currentUser.created_on),
    description: currentUser.group_description,
    email: currentUser.email,
    firstName: currentUser.first_name,
    id: userId,
    lastName: currentUser.last_name,

    // Legacy fields we might be using?
    'First Name': currentUser.first_name,
    'Last Name': currentUser.last_name,
    Email: currentUser.email,
    Group: currentUser.group,
    GroupId: currentUser.group_id,
    'Created At': Dates.maybeSqlToISODate(currentUser.created_on),
    'Last Login': Dates.maybeSqlToISODate(currentUser.last_login),
    userId,
  };
};

export function trackClick(data) {
  const {
    action,
    properties,
  } = data;

  const name = `Link Click ${action}`;
  segment.track(name, properties);
}

export const getRvName = (make, model, headline) => (make || model)
  ? `${make || ''} ${model || ''}`.trim()
  : headline;