import React, { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

import { Button as BaseButton, Flex } from 'core/ui';
import useKey from 'core/hooks/useKey';
import useToggle from 'core/hooks/useToggle';
import { withModals } from 'core/state/Modals';
import { GROUPS as USER_GROUP } from 'core/helpers/User';
import SafeTracking from 'tracking/SafeTracking';

import HamburgerIcon from 'components/Hamburger';

import LoginRegisterListItems from '../Nav/LoginRegisterListItems';
import UserNav from '../Nav/UserNav';
import { renterRVButton, ownerRVButton } from '../Nav/RVButtons';

const Button = styled(BaseButton)`
  color: #afb6bd;
  position: relative;
  z-index: 13;
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  margin-right: -5px;
`;

const Drawer = styled.div`
  background-color: #fff;
  padding-top: 50px;
  height: 100%;
  width: 280px;
  position: fixed;
  top: 0;
  right: 0;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  ${({ open }) => open && 'transform: scale(1, 1);'}
  z-index: 11;
`;

const NavLinksWrapperStyled = styled.div`
  li {
    display: block;
  }
`;


const Li = styled.li`
  font-size: 0.875rem;
  width: 100%;
  text-align: left;
  font-weight: normal;
  padding: 12px 34px;
  a {
      color: ${({ theme }) => theme.colors.darkBlue};
      padding: 0;
      text-decoration: none;
    }
    &:visited {
      color: ${({ theme }) => theme.colors.darkBlue};
    }
`;

const ESC_KEY = 27;
function MobileNav(props) {
  const { currentUser, showModal } = props;
  const isLoggedIn = !!currentUser;
  const isOwner = !!isLoggedIn && currentUser.group_id === USER_GROUP.OWNER;
  const isAdmin = !!isLoggedIn && currentUser.group_id === USER_GROUP.ADMIN;
  const showListRvButton = (isLoggedIn && isOwner) || !isLoggedIn;

  const menuRef = useRef(null);
  const [on, onToggle] = useToggle();

  useEffect(() => {
    document.body.style.overflow = on ? 'hidden' : 'inherit';
  }, [on]);

  useKey(
    () => {
      if (on) {
        onToggle();
      }
    },
    {
      detectKeys: [ESC_KEY],
    },
    [on],
  );

  const handleClickOutside = useCallback(
    (e) => {
      const isDescendantOfRoot = menuRef.current && menuRef.current.contains(e.target);
      if (on && !isDescendantOfRoot) {
        onToggle();
      }
    },
    [on, onToggle],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => document.removeEventListener('mousedown', handleClickOutside, false);
  }, [handleClickOutside]);

  const listRvButtonProps = {
    variant: 'link',
    ml: 0,
    py: 0,
  };

  return (
    <Flex ref={menuRef}>
      {isLoggedIn && <UserNav currentUser={currentUser} />}
      <Button
        variant="transparent"
        type="button"
        aria-controls="mobileNavbar"
        aria-expanded={on.toString()}
        aria-label="Toggle navigation"
        onClick={onToggle}
        open={on}
      >
        <HamburgerIcon open={on} />
      </Button>

      <Drawer id="mobileNavbar" open={on}>
        <NavLinksWrapperStyled>
          {!isLoggedIn && (
            <LoginRegisterListItems
              openRegisterModal={() => showModal('Register')}
              openLoginModal={() => showModal('Login')}
              isMobileNav
            />
          )}
          <Li>
            {showListRvButton ?
              ownerRVButton(listRvButtonProps)
              :
              renterRVButton(listRvButtonProps)
            }
          </Li>
          {isLoggedIn && (
            <>

              <Li>
                <a className="nav-link" href="/dashboard/favorites" data-event-category="Global Element" data-event-action="Favorites" data-event-label="Top Navigation">
                  Favorites
                </a>
              </Li>
              {!isOwner && (

                <Li>
                  <a className="nav-link" href="/dashboard/trips" data-event-category="Global Element" data-event-action="Trips" data-event-label="Top Navigation">
                    Trips
                  </a>
                </Li>
              )}

              <Li>
                <a className="nav-link" href="/dashboard/inbox/" data-event-category="Global Element" data-event-action="Inbox" data-event-label="Top Navigation">
                  Inbox
                </a>
              </Li>
            </>
          )}

          <Li>
            <a className="nav-link" href="https://help.rvshare.com/hc/en-us" target="_blank" rel="noopener noreferrer" data-event-category="Global Element" data-event-action="Help Center" data-event-label="Top Navigation">
              Help Center
            </a>
          </Li>
          {(isOwner || isAdmin) && (
            <Li>
              <a className="nav-link" href="https://owner-toolkit.rvshare.com/" target="_blank" rel="noopener noreferrer" data-event-category="Global Element" data-event-action="Owner Toolkit" data-event-label="Top Navigation">
                Owner Toolkit
              </a>
            </Li>
          )}

          <Li>
            <a className="nav-link" href="https://www.surveymonkey.com/r/feedbackcenter" rel="noopener noreferrer" data-event-category="Global Element" data-event-action="Give Feedback" data-event-label="Top Navigation" target="_blank" onClick={() => SafeTracking.track('Give feedback header click')}>
              Give Feedback
            </a>
          </Li>
        </NavLinksWrapperStyled>
      </Drawer>
    </Flex>
  );
}
export default React.memo(withModals(MobileNav));
