import React from 'react';

import { withCurrentUser } from 'core/state/Globals';

import FooterColumn from 'components/PageFooter/FooterColumn';
import FooterSocialColumn from 'components/PageFooter/FooterSocialColumn';
import FooterCopyrightSection from 'components/PageFooter/FooterCopyrightSection';
import {
  FooterComponent,
  AllColumnsWrapper,
  ColumnWrapper,
  InnerWrapper,
} from 'components/PageFooter/styles';
import {
  firstColumnItems,
  secondColumnItems,
  thirdColumnItems,
  fourthColumnItems,
} from 'components/PageFooter/columns';

function NewFooter({ sitemapUrl, hasSitemap }) {
  return (
    <FooterComponent>
      <InnerWrapper>
        <AllColumnsWrapper>
          <ColumnWrapper>
            <FooterColumn
              columnName="RVshare"
              items={firstColumnItems}
              hasSitemap={hasSitemap}
              sitemapUrl={sitemapUrl}
            />
            <FooterColumn columnName="Explore" items={secondColumnItems} />
          </ColumnWrapper>
          <ColumnWrapper>
            <FooterColumn columnName="Renters" items={thirdColumnItems} />
            <FooterColumn columnName="Owners" items={fourthColumnItems} />
          </ColumnWrapper>
        </AllColumnsWrapper>
        <FooterSocialColumn />
      </InnerWrapper>
      <FooterCopyrightSection />
    </FooterComponent>
  );
}

export default withCurrentUser(NewFooter);
