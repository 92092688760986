import moment from 'moment';
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay';
import isInclusivelyBeforeDay from 'react-dates/lib/utils/isInclusivelyBeforeDay';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';

// THESE SHOULD BE DEPRECATED IF THEY USE MOMENT.JS
// https://momentjs.com/docs/#/-project-status/

export const DEFAULT_FORMAT = 'YYYY-MM-DD';

export const stringify = (date, format = DEFAULT_FORMAT) => {
  if (date && moment(date).isValid()) {
    return moment(date).format(format);
  }

  return undefined;
};

export const parse = (str, format = DEFAULT_FORMAT) => {
  const date = moment(str, format);

  return date.isValid() ? date : undefined;
};

export const parseUTC = (str, format = DEFAULT_FORMAT) => {
  const date = moment.utc(str, format);

  return date.isValid() ? date : undefined;
};

export const isBeforeToday = date => date && date.isBefore(moment(), 'day');

export const isBetween = (date, a, b) => date.isAfter(a) && date.isBefore(b);
export const isInclusivelyBetween = (date, a, b) => (
  isInclusivelyAfterDay(date, a) &&
  isInclusivelyBeforeDay(date, b)
);

export const isBeforeAndInclusivelyAfterDay = (date, a, b) => (
  isInclusivelyAfterDay(date, a) &&
  isBeforeDay(date, b)
);

export const isOutside = (date, a, b) => date.isBefore(a) || date.isAfter(b);
export const isInclusivelyOutside = (date, a, b) => (
  isInclusivelyBeforeDay(date, a) ||
  isInclusivelyAfterDay(date, b)
);

export const didChange = (a, b) => !a || !b || !a.isSame(b, 'day');

export const maybeSqlToISODate = value => (
  value
    ? new Date(parseInt(value, 10) * 1000).toISOString()
    : null
);

export const maybeValidDates = (dates) => {
  if (!dates) {
    return {};
  }

  const startDate = parse(dates.startDate);
  const endDate = parse(dates.endDate);

  if (!startDate || !endDate) {
    return {};
  }

  return { startDate, endDate };
};

export const convertMomentRangeToDates = (dates) => {
  const startDate = parse(dates.startDate);
  const endDate = parse(dates.endDate);

  if (!startDate && !endDate) {
    return {};
  }

  return {
    startDate: startDate ? startDate.toDate() : undefined,
    endDate: endDate ? endDate.toDate() : undefined,
  };
};

export const convertToMomentRange = (value) => {
  const { startDate, endDate } = value || {};

  return {
    startDate: startDate ? moment(startDate) : undefined,
    endDate: endDate ? moment(endDate) : undefined,
  };
};
