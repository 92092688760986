import variables from 'stylesheets/_variables.scss';

/*
 * TODO: this file is most useful to subdivide the
 * exports (i.e. so you can import { colors }) for other files
 * it's mostly a duplicate of the :export part of _variables.scss
 * aside from the renaming in breakpoints & containers
 */

// Map _variables.scss to JS object
export const colors = {
  bodyColor: variables.bodyColor, // #414141
  primaryBlue: variables.primaryBlue, // #0d63a6
  primaryBlueHover: variables.primaryBlueHover, // #257ec4
  babyBlue: variables.babyBlue, // #b9cfe0
  grey: variables.grey, // #ced4da
  mutedGrey: variables.mutedGrey, // #6c757d
  warmGrey: variables.warmGrey, // #7e7e7e
  lightGrey: variables.lightGrey, // #d9d9d9
  veryLightGrey: variables.veryLightGrey, // #e5e5e5
  altBlue: variables.altBlue, // #456b98
  darkBlue: variables.darkBlue, // #0a2239
  yellow: variables.yellow, // #f6bc14
  yellowHover: variables.yellowHover, // #ffda6d
  yellowPressed: variables.yellowPressed, // #e9b214
  gold: variables.gold, // #eda63a
  facebookBlue: variables.facebookBlue, // #49639f
  paginationColor: variables.paginationColor, // primaryBlue
  paginationActiveBgColor: variables.paginationActiveBgColor, // primaryBlue
  paginationActiveBorderColor: variables.paginationActiveBorderColor, // primaryBlue
  heartColor: variables.heartColor, // #499ee0
  linkColor: variables.linkColor, // #377ac9
  placeholderColor: variables.placeholderColor, // #757575
  mediumGrey: variables.mediumGrey, // #5c5c5c
  pinkishGrey: variables.pinkishGrey, // #c8c8c8
  niceBlue: variables.niceBlue, // #0e6cb5
  offWhite: variables.offWhite, // #f7f7f7
  lightBlue: variables.lightBlue, // #eaf0f4
  fadedText: variables.fadedText, // #0a2239
  rvCardPlacholderColor: variables.rvCardPlacholderColor, // #eaeaea
  rvCardBorderColor: variables.rvCardBorderColor, // #bcbcbc
  forestGreen: variables.forestGreen, // #5a8f19
  confirmationGreen: variables.confirmationGreen, // #29a645

  // Rebrand
  coreBlue: variables.primaryBlueRebrand, // #004665
  coreBlueHover: variables.primaryBlueHoverRebrand, // #006a9a
  coreDarkBlue: variables.darkBlueRebrand, // #00324D
  secondaryGreen: variables.secondaryGreenRebrand, // #4c945a
  groveGreen: variables.groveGreen, // #365938

  // greys
  grey100: variables.grey100, // #fafaf9
  grey200: variables.grey200, // #f0f0ef
  grey300: variables.grey300, // #e0e0dd
  grey400: variables.grey400, // #BFBFBA
  grey500: variables.grey500, // #9e9e97
  grey600: variables.grey600, // #7c7c75
  grey700: variables.grey700, // #595954
  grey800: variables.grey800, // #3f3f3b
  grey900: variables.grey900, // #2d2d2b
  black: variables.black, // #1c1c1a
  errorRed: variables.errorRedRebrand, // #c11e19
  tealRebrand: variables.tealRebrand, // #c11e19

  // bs
  warning: variables.warning,
  error: variables.error,
};

export const fonts = {
  fontFamily: variables.fontFamily,
  headingsFontFamily: variables.headingsFontFamily,
  semiBoldFontFamily: variables.semiBoldFontFamily,
  h1FontSize: variables.h1FontSize,
  h2FontSize: variables.h2FontSize,
  h3FontSize: variables.h3FontSize,
  h4FontSize: variables.h4FontSize,
  h5FontSize: variables.h5FontSize,
  lineHeight: variables.lineHeight,
  lineHeightLg: variables.lineHeightLg,
  lineHeightSm: variables.lineHeightSm,
  fontWeightNormal: variables.fontWeightNormal,
  fontWeightBold: variables.fontWeightBold,
  fontWeightSemiBold: variables.fontWeightSemiBold,
};

export const general = {
  buttonRadius: variables.buttonRadius,
  buttonRadiusLg: variables.buttonRadiusLg,
  buttonRadiusSm: variables.buttonRadiusSm,
};

export const containers = {
  widthSm: variables.containerWidthSm,
  widthMd: variables.containerWidthMd,
  widthLg: variables.containerWidthLg,
  widthXl: variables.containerWidthXl,
};

export const breakpoints = {
  mobile: variables.breakpointMobile,
  xs: variables.breakpointXs,
  sm: variables.breakpointSm,
  md: variables.breakpointMd,
  lg: variables.breakpointLg,
  xl: variables.breakpointXl,
};

export default {
  colors,
  fonts,
  general,
  containers,
  breakpoints,
};
