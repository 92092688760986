import React, { useState, useRef } from 'react';
import { Field } from 'formik';
import get from 'just-safe-get';

import Collapse from 'components/Collapse';
import {
  TextInput,
  FormFeedbackError,
  FormFeedbackWarning,
  InputGroupWrapper,
  InputGroupTextPrepend,
  InputGroupPrepend,
} from './styles';

const getValidator = required => value => {
  if (required && !value) {
    return 'Required';
  }

  return undefined;
};

export default function TextField(props) {
  const {
    children,
    component: CustomComponent,
    flashError,
    type,
    validate,
    onBlur,
    onFocus,
    fieldStatus,
    appendedIcon,
    errorsEnabled = true,
    errorStyles = {},
    ...restProps
  } = props;

  const [focused, setFocused] = useState(false);
  const inputElement = useRef();

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <Field name={props.name} validate={validate || getValidator(props.required)}>
      {({ field, form: { touched, errors } }) => {
        if (field.name === undefined) { throw new Error('Please define a name attribute for your text field') };

        const invalid = !!(get(touched, field.name) && get(errors, field.name));
        const hasFlashError = !!(!get(touched, field.name) && flashError);
        const hasStatus = !!fieldStatus;

        const inputId = restProps.id ? restProps.id : `${restProps.name}_textField`;

        const inputProps = {
          ...restProps,
          id: inputId,
          type: type || 'text',
          onBlur: e => {
            handleBlur();

            // Allow for custom blur handler
            if (onBlur) {
              onBlur(e);
            }

            // always call the default formik onBlur handler
            field.onBlur(e);
          },
          onFocus: e => {
            handleFocus();

            if (onFocus) {
              onFocus(e);
            }
          },
        };

        const renderField = () => {
          if (CustomComponent) {
            return <CustomComponent {...field} invalid={invalid} {...inputProps} />;
          }

          const textInput = (
            <TextInput {...field} {...inputProps} invalid={invalid} ref={inputElement} />
          );

          if (appendedIcon) {
            return (
              <InputGroupWrapper focused={focused}>
                <InputGroupTextPrepend htmlFor={inputId}>{appendedIcon}</InputGroupTextPrepend>
                <InputGroupPrepend>{textInput}</InputGroupPrepend>
              </InputGroupWrapper>
            );
          }

          return textInput;
        };

        const renderErrors = () => (
          <Collapse isOpen={invalid || hasFlashError}>
            <FormFeedbackError style={errorStyles}>
              {invalid ? get(errors, field.name) : null}
              {hasFlashError ? flashError : null}
            </FormFeedbackError>
          </Collapse>
        );

        const renderWarnings = () => (
          <Collapse isOpen={hasStatus}>
            <FormFeedbackWarning>{hasStatus ? fieldStatus : null}</FormFeedbackWarning>
          </Collapse>
        );

        if (typeof children === 'function') {
          return children({ renderField, renderErrors, renderWarnings });
        }

        return (
          <>
            {renderField()}
            {errorsEnabled && renderErrors()}
            {errorsEnabled && renderWarnings()}
          </>
        );
      }}
    </Field>
  );
}
