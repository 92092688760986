import styled, { css } from 'styled-components';
import theming from 'styled-theming';

import { Flex } from 'core/ui';
import ReactModalAdapter from './ReactModalAdapter';

const modalTextColor = theming('mode', {
  dark: '#fff',
});

const overlayBackgroundColor = theming('mode', {
  light: 'rgba(0, 0, 0, 0.5)',
  dark: 'rgba(0, 0, 0, 1)',
});

const contentBackgroundColor = theming('mode', {
  light: '#fff',
  dark: '#000',
});

export const StyledModal = styled(ReactModalAdapter)`
  color: ${modalTextColor};

  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: ${overlayBackgroundColor};
    z-index: 2000;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    padding: 10px;
    -webkit-overflow-scrolling: touch;
    overflow-y: unset !important;

    ${({ theme }) => theme.medium`
      padding: 0;
    `}

    ${({ isFullScreen }) =>
    isFullScreen &&
      css`
        padding: 0;
        opacity: 1;
        transform: translateY(100vh);
        transition: transform 300ms cubic-bezier(0.18, 0.89, 1, 0.99),
        transform 300ms cubic-bezier(0.43, 0.57, 0.79, 0.99);
      `}
  }

  .ReactModal__Overlay--after-open {
    transform: translateY(0);
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  &__content {
    background: ${contentBackgroundColor};
    ${({ isFullScreen }) =>
    isFullScreen &&
      css`
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: 100%;
      `}

    ${({ isFullScreen, theme }) =>
    !isFullScreen &&
      css`
        ${theme.medium`
        max-width: 415px;
        margin: 1.75rem auto;
      `}
      `}

    &:focus {
      outline: none;
    }
  }
`;

const lightModeCloseButton = css`
  color: #000;
  text-shadow: 0 1px 0 #fff;
`;

const darkModeCloseButton = css`
  color: #fff;
  text-shadow: 0 1px 0 #fff;
`;

const closeButtonStyles = theming('mode', {
  light: lightModeCloseButton,
  dark: darkModeCloseButton,
});

export const CloseButton = styled.button`
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  transition: opacity 100ms ease-in-out;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 1rem 1rem;
  margin: -1rem -0.5rem -1rem auto;
  ${closeButtonStyles};

  &:hover {
    opacity: 1;
  }
`;

export const ModalHeading = styled.h5`
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
`;

const headerBorderColor = theming('mode', {
  light: '#dee2e6',
  dark: 'rgba(255, 255, 255, 0.5)',
});

export const ModalHeader = styled(Flex)`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${headerBorderColor};
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
`;

export const StickyHeader = styled.div`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 9;
`;

export const StickyButton = styled.div`
  background-color: #fff;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.12);
  padding: 15px;
  position: sticky;
  bottom: 0;
  z-index: 2;
  width: 100%;
`;

export const CloseButtonStyled = styled(CloseButton)`
  font-size: 32px;
  padding: 0.8rem;
  margin-left: 0;
  margin-top: -13px;
`;
