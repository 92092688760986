import React from 'react';
import PropTypes from 'prop-types';
import canUseDOM from 'can-use-dom';

import { getStateFromDOM } from 'core/utils/client';
import { SocialAuthProviders } from 'core/helpers/SocialAuth';

import SocialButton from './SocialButton';

const fbLoadSdk = (callback) => {
  if (!canUseDOM) return;

  const id = 'facebook-jssdk';
  const firstScript = document.getElementsByTagName('script')[0];

  // if loaded
  if (document.getElementById(id)) {
    callback();
    return;
  }

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: getStateFromDOM('env.fbAppId'),
      version: 'v2.10',
      cookie: true,
    });
    callback();
  };

  if (!firstScript) return;

  const js = document.createElement('script');
  js.id = id;
  js.src = '//connect.facebook.net/en_US/sdk.js';
  js.async = true;
  js.defer = true;
  firstScript.parentNode.insertBefore(js, firstScript);
};

const fbLogin = (callback) => {
  window.FB.login((response) => {
    if (response.authResponse) {
      // Make a request to Facebook's Graph API to fetch the user data
      window.FB.api('/me', { fields: 'email,first_name,last_name' }, (graphResponse) => {
        if (graphResponse && !graphResponse.error) {
          const { email, first_name, last_name } = graphResponse;
          
          // Call the callback function with the access token, email address, first name, last name
          callback({ email, first_name, last_name, ...response.authResponse });
        } else {
          // Handle error when fetching user data
          console.error('Error retrieving user data from Facebook Graph API');
        }
      });
    } else {
      // Handle login error
      console.error('Error logging in with Facebook');
    }
  }, { scope: 'public_profile,email' });
};

export const fbBuildParams = (authResponse, signUpValues) => {
  if (!authResponse) {
    return { error: true }
  }

  const signedRequestBody = authResponse.signedRequest.split('.')[1];
  const { code } = JSON.parse(atob(signedRequestBody));
  let params = `code=${code}&user_type=renter`;

  if (signUpValues) {
    const { first_name, last_name, phone } = signUpValues;
    // phone is an object with country_code, number, and alpha_country_code
    // has to be encoded, so object can be passed as a url param
    const encodedPhone = encodeURIComponent(JSON.stringify(phone));
    params += encodeURI(`&first_name=${first_name}&last_name=${last_name}&phone=${encodedPhone}`);
  }

  return { params };
};

const fbHeaders = {
  "Content-Type": "application/x-www-form-urlencoded"
};

export default function FacebookButton(props) {
  const { action, onSubmitSuccess, onStartSocialSignUpSuccess } = props;

  return (
    <SocialButton
      {...{
        action,
        onSubmitSuccess,
        onStartSocialSignUpSuccess
      }}
      provider={SocialAuthProviders.FACEBOOK}
      loadSdk={fbLoadSdk}
      loginHandler={fbLogin}
      buildParams={fbBuildParams}
      headers={fbHeaders}
    />
  );
}

FacebookButton.propTypes = {
  action: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func,
};

FacebookButton.defaultProps = {
  onSubmitSuccess: null,
};
