import { colors, fonts } from './scss-variables';
import ButtonStyles from './buttons';
import mediaQueries, { breakpoints } from './mediaQuery';

const globalTheme = {
  /**
   * Usage (min-width: Npx)
   * ${({ theme }) => theme.medium`
   *   ...your styles...
   * `}
   */
  ...mediaQueries,

  breakpoints,

  fonts: {
    sans: fonts.fontFamily,
    fontFamily: fonts.fontFamily, // for consistency
    headingFontFamily: fonts.headingsFontFamily,
    semiBoldFontFamily: fonts.semiBoldFontFamily,
  },

  fontWeights: {
    normal: fonts.fontWeightNormal,
    semiBold: fonts.fontWeightSemiBold,
    bold: fonts.fontWeightBold,
  },

  colors: {
    // "black"
    bodyColor: colors.bodyColor,
    // blues
    darkBlue: colors.darkBlue,
    primaryBlue: colors.primaryBlue,
    primaryBlueHover: colors.primaryBlueHover,
    niceBlue: colors.niceBlue,
    lightBlue: colors.lightBlue,
    linkColor: colors.linkColor,
    // yellows
    yellow: colors.yellow,
    warningYellow: colors.warning,
    // Golds
    gold: colors.gold,
    // reds
    error: colors.error,
    // greys
    grey: colors.grey,
    mutedGrey: colors.mutedGrey,
    lightGrey: colors.lightGrey,
    veryLightGrey: colors.veryLightGrey,
    pinkishGrey: colors.pinkishGrey,
    warmGrey: colors.warmGrey,
    // greens
    forestGreen: colors.forestGreen,
    confirmationGreen: colors.confirmationGreen,
    // rebrand
    coreBlue: colors.coreBlue,
    coreBlueHover: colors.coreBlueHover,
    coreDarkBlue: colors.coreDarkBlue,
    secondaryGreen: colors.secondaryGreen,
    groveGreen: colors.groveGreen,
    grey100: colors.grey100,
    grey200: colors.grey200,
    grey300: colors.grey300,
    grey400: colors.grey400,
    grey500: colors.grey500,
    grey600: colors.grey600,
    grey700: colors.grey700,
    grey800: colors.grey800,
    grey900: colors.grey900,
    black: colors.black,
    errorRed: colors.errorRed,
    coreTeal: colors.tealRebrand,
  },

  borders: {
    lightGrey: colors.lightGrey,
  },

  buttons: ButtonStyles(),

  links: {
    blue: {
      color: colors.primaryBlue,
      textDecoration: 'none',
      '&:hover': {
        color: colors.primaryBlueHover,
      },
      '&:active': {
        color: colors.darkBlue,
      },
    },
    dark: {
      color: colors.darkBlue,
      textDecoration: 'none',
      '&:hover': {
        color: colors.primaryBlueHover,
      },
      '&:active': {
        color: colors.primaryBlue,
      },
    },
  },

  alerts: {
    danger: {
      backgroundColor: '#f8d7da',
      borderColor: '#f5c6cb',
      color: '#721c24',
    },
    info: {
      backgroundColor: '#d1ecf1',
      borderColor: '#bee5eb',
      color: '#0c5460',
    },
    success: {
      color: '#155724',
      backgroundColor: '#d4edda',
      borderColor: '#c3e6cb',
    },
    greyWarning: {
      color: colors.mutedGrey,
      backgroundColor: colors.grey200
    }
  },
};

export default globalTheme;
