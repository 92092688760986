import React from 'react';

import TextField from './TextField';

export default function PasswordField(props) {
  return (
    <TextField
      {...props}
      type="password"
    />
  );
}
