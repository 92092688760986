/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';

import { Button } from 'core/ui';
import { normalizeServerErrors } from 'core/helpers/API';
import useApi from 'core/hooks/useApi';

import EmailField from 'form-fields/styled/EmailField';
import { errorStyles, FormStylesWrapper } from 'form-fields/rebrand/fieldStyles';
import { InputGroup, InputGroupAppend } from 'form-fields/styled/inputGroupStyled';

import Disclaimer from '../components/Disclaimer';

const InputGroupWrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const FormInputs = ({ disabled }) => {
  return (
    <FormStylesWrapper>
      <label htmlFor="email" className="sr-only">Email</label>
      <EmailField
        id="continue-email"
        data-testid="continue-email"
        name="email"
        errorStyles={errorStyles}
        placeholder="Email"
        disabled={disabled}
        required
      >
        {({ renderField, renderErrors }) => (
          <InputGroupWrapper>
            <InputGroup>
              {renderField()}
              <InputGroupAppend>
                <Button type="submit" variant="blue" disabled={disabled} data-testid="email-availability-continue-button">
                  Continue
                </Button>
              </InputGroupAppend>
            </InputGroup>
            {renderErrors()}
          </InputGroupWrapper>
        )}
      </EmailField>
    </FormStylesWrapper>
  );
};

function EmailAvailabilityForm({ onSubmitSuccess }) {
  const api = useApi();

  const onSubmit = useCallback(
    ({ email }, context) => {
      const { setSubmitting, setStatus, setErrors } = context;

      api
        .get('/v1/users/email-availability', { params: { email } })
        .then(({ data }) => {
          setSubmitting(false);
          setStatus({ success: true });

          if (onSubmitSuccess) {
            onSubmitSuccess({
              email,
              showLoginForm: data && data.available === false,
              showRegisterForm: data && data.available === true,
            });
          }
        })
        .catch(({ response: { data } = {} }) => {
          setSubmitting(false);
          setErrors({
            email: 'Something went wrong',
            ...normalizeServerErrors(data),
          });
        });
    },
    [api, onSubmitSuccess]
  );

  return (
    <div>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={onSubmit}
        render={context => {
          const { handleSubmit, isSubmitting, status: { success } = {} } = context;

          const disabled = isSubmitting || success;

          return (
            <form id="email-availability-form" noValidate onSubmit={handleSubmit}>
              <FormInputs disabled={disabled} />
            </form>
          );
        }}
      />
      <Disclaimer />
    </div>
  );
}

export default EmailAvailabilityForm;
