// import React from 'react';
import styled from 'styled-components';

import Col from './Col';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -${({ gutter }) => gutter / 2}px;
  margin-left: -${({ gutter }) => gutter / 2}px;

  ${Col} {
    padding-right: ${({ gutter }) => gutter / 2}px;
    padding-left: ${({ gutter }) => gutter / 2}px;
  }
`;

Row.defaultProps = {
  gutter: 30,
};

export default Row;
