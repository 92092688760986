/* eslint-disable camelcase */
import React from 'react';
import { useMedia } from 'use-media';

import { sizes } from 'core/styles/mediaQuery';
import { withCurrentUser } from 'core/state/Globals';
import { usePagePath } from 'core/state/PagePathContext';
import { useDevice } from 'core/hooks/useDevice';
import { GROUPS as USER_GROUP } from 'core/helpers/User';

import logoPrimary from 'images/logo_primary.png';
import logoPrimary2x from 'images/logo_primary@2x.png';

import {
  LogoWrapper,
  HeaderComponent,
  Logo,
  HeaderContainer,
  LogoMobileNav,
  DesktopNavWrapper,
} from './styles/Header';

import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

function GlobalHeader({ currentUser, searchBar: SearchBar }) {
  const { isMobile } = useDevice();
  const isAdminOrOwner =
    currentUser?.group_id === USER_GROUP.OWNER || currentUser?.group_id === USER_GROUP.ADMIN;

  const { search } = usePagePath();
  const isBelowLarge = useMedia({ maxWidth: sizes.lg - 1 }, isMobile);

  // default to whatever is passed in (could be null, I guess)
  let searchBarComponent = SearchBar || null;
  if (SearchBar && typeof SearchBar === 'function') {
    // if it is a function, then render it as a react component
    // otherwise it must've already been passed in like `searchBar={<SomeComponent />}`
    // which would be an object
    searchBarComponent = <SearchBar />;
  }

  const isMobileApp = search.includes('mobile_app=true');
  const handleLogoClick = event => {
    if (isMobileApp) {
      event.preventDefault();
    }
  };

  return (
    <HeaderComponent>
      <HeaderContainer>
        <LogoMobileNav>
          <LogoWrapper>
            <a href="/" onClick={handleLogoClick}>
              <Logo
                alt="RVshare.com"
                src={logoPrimary}
                srcSet={`${logoPrimary}, ${logoPrimary2x} 2x`}
                data-event-category="Global Element"
                data-event-action="Logo"
                data-event-label="Top Navigation"
                width="120"
                height="31"
              />
            </a>
          </LogoWrapper>
          {/* Mobile nav position for phone sized screens */}
          {isMobile && !isMobileApp && <MobileNav currentUser={currentUser} />}
        </LogoMobileNav>
        {searchBarComponent}
        {!isBelowLarge && (
          <DesktopNavWrapper>
            <DesktopNav
              isAdminOrOwner={isAdminOrOwner}
              currentUser={currentUser}
              hasSearchBar={!!SearchBar}
            />
          </DesktopNavWrapper>
        )}
        {/* Mobile nav position for tablet sized screens */}
        {isBelowLarge && !isMobile && <MobileNav currentUser={currentUser} />}
      </HeaderContainer>
    </HeaderComponent>
  );
}

export default withCurrentUser(GlobalHeader);
