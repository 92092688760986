import React from 'react';
import styled from 'styled-components';

import Link from 'core/ui/Link';
import { withModals } from 'core/state/Modals';
import { GROUPS as USER_GROUP } from 'core/helpers/User';

import LoginRegisterListItems from './LoginRegisterListItems';
import { renterRVButton, ownerRVButton } from './RVButtons';

const LinkInner = styled.div`
  ${({ theme }) => theme.large`
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0 16px;
  `}
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > li {
    white-space: nowrap;
  }

  ${({ theme }) => theme.large`
    flex-direction: row;
  `}
`;

const Li = styled.li`
  display: inline-block;
  margin-bottom: 0;
`;

const ListRvLi = styled(Li)`
  order: -1;

  ${({ theme }) => theme.large`
    order: 0;
  `}
`;

function NavLinks(props) {
  const { isLoggedIn, currentUser, listRvButtonProps, showModal, hasSearchBar, children = '' } = props;

  const isOwner = !!isLoggedIn && currentUser.group_id === USER_GROUP.OWNER;
  const showListRvButton = !isLoggedIn || isOwner;

  return (
    <Ul>
      {!isLoggedIn && (
        <LoginRegisterListItems
          openRegisterModal={() => showModal('Register')}
          openLoginModal={() => showModal('Login')}
        />
      )}
      {(isLoggedIn && !hasSearchBar) && (
        <>
          <Li>
            <Link href="/dashboard/favorites" variant="dark" data-event-category="Global Element" data-event-action="Favorites" data-event-label="Top Navigation">
              <LinkInner>Favorites</LinkInner>
            </Link>
          </Li>
          {!isOwner && (
            <Li>
              <Link href="/dashboard/trips" variant="dark" data-event-category="Global Element" data-event-action="Trips" data-event-label="Top Navigation" data-testid="nav-trips">
                <LinkInner>Trips</LinkInner>
              </Link>
            </Li>
          )}
          <Li>
            <Link href="/dashboard/inbox/" variant="dark" data-event-category="Global Element" data-event-action="Inbox" data-event-label="Top Navigation">
              <LinkInner>Inbox</LinkInner>
            </Link>
          </Li>
        </>
      )}
      {children && <Li>{children}</Li>}

      {(!hasSearchBar || (hasSearchBar && !isLoggedIn)) && (
        <ListRvLi>
          {showListRvButton ?
            ownerRVButton(listRvButtonProps)
            :
            renterRVButton(listRvButtonProps)
          }
        </ListRvLi>
      )}
    </Ul>
  );
}
export default withModals(React.memo(NavLinks));
