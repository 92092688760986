import React, { useEffect } from 'react'
import OtpField from 'form-fields/styled/OtpField';
import QRCode from 'qrcode.react';
import ShakeAlert from 'components/ShakeAlert';
import { Flex } from 'core/ui';

const AutoSave = ({ otp, handleSubmit }) => {
  useEffect(() => {
    if (otp.length === 6) {
      handleSubmit();
    }
  }, [otp, handleSubmit]);

  return null;
};

export default function OtpInput({handleSubmit, error, disabled, isSubmitting, otp_provisioning_uri, group, otp_delivery_method, otp, setFieldValue}) {
  return (
    <Flex flexDirection="column">
      {/* Necessary because Formik can't trigger form submissions in a field onChange: https://github.com/formium/formik/issues/1218 */}
      <AutoSave otp={otp} handleSubmit={handleSubmit} />
      <ShakeAlert show={!!error && !isSubmitting}>{error}</ShakeAlert>
      {otp_provisioning_uri && (
        <>
          <p>Follow the steps below:</p>
          <ol>
            <li>
              Install the Google Authenticator for your{' '}
              <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">iPhone</a> or{' '}
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                Android phone
              </a>
            </li>
            <li>Use the Google Authenticator app to scan the image below</li>
            <li>The app will generate 6 digit codes you can use as 1 time passwords</li>
          </ol>
          <Flex justifyContent="center">
            <QRCode value={otp_provisioning_uri} size={256} />
          </Flex>
        </>
      )}
      <form id="login-form" className="login-form" noValidate onSubmit={handleSubmit}>
        {group === 'owner' ? (
          <>
            <p>
              {otp_delivery_method === 'sms' &&
                'Enter the six digit verification code sent to your phone.'}
              {otp_delivery_method === 'voice' &&
                'Calling you now. Enter your six digit verification code from the automated caller.'}
            </p>
            <OtpField
              disabled={disabled}
              numInputs={6}
              name="otp"
              value={otp}
              shouldAutoFocus
              onChange={value => {
                setFieldValue('otp', value);
              }}
            />
            <p>
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={() => {
                  setFieldValue('stage', 'otp_delivery_method');
                }}
              >
                Didn&apos;t receive an verification code?
              </a>
              {/* eslint-enable jsx-a11y/anchor-is-valid */}
            </p>
          </>
        ) : (
          <>
            <p>
              Enter your six digit code one time password code from your Authenticator app below:
            </p>
            <OtpField
              disabled={disabled}
              numInputs={6}
              name="otp"
              value={otp}
              shouldAutoFocus
              onChange={value => {
                setFieldValue('otp', value);
              }}
            />
            <a
              href="https://rvshare.atlassian.net/wiki/spaces/TS/pages/1332379732/How+to+use+Two+Factor+Authentication+on+the+RVshare+Dashboard"
              target="_blank"
              rel="noopener noreferrer"
            >
              Having issues logging in?
            </a>
          </>
        )}
      </form>
    </Flex>
  );
}
