import React from 'react';

import { Column } from 'components/PageFooter/styles';
import FooterListItem from 'components/PageFooter/FooterListItem';

import BBBLogo from 'images/bbb-logo.png';

function FooterColumn({ columnName, items, hasSitemap, sitemapUrl }) {
  const sitemapItem = { name: 'Sitemap', href: sitemapUrl };

  return (
    <Column>
      <h4>{columnName}</h4>
      <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
        {items.map(item => {
          return <FooterListItem key={item.name} item={item} />;
        })}
        {hasSitemap && <FooterListItem key={sitemapItem.name} item={sitemapItem} />}
        {columnName === 'RVshare' && (
          <li>
            <a
              href="https://www.bbb.org/us/tx/austin/profile/rv-rental/rvshare-0825-1000165281/#sealclick"
              target="_blank"
              rel="noopener noreferrer"
              data-event-category="Global Element"
              data-event-action="BBB Logo"
              data-event-label="Footer"
            >
              <img
                src={BBBLogo}
                loading="lazy"
                alt="RVshare BBB Business Review"
                width="100"
                height="38"
              />
            </a>
          </li>
        )}
      </ul>
    </Column>
  );
}

export default FooterColumn;
