import {
  format as formatDate,
  parse as parseDateString,
  parseISO,
  isValid,
  isBefore,
  isAfter,
  isSameDay,
  startOfDay,
} from 'date-fns';

export { parseISO };

export const DEFAULT_FORMAT = 'yyyy-MM-dd';

export const parseTimeWithTimezone = (time = '') =>
  time.indexOf('+') > -1 ? time.replace('+00', '') : time;

export const bookingDateTime = (
  booking = {},
  dateFormat = 'MM/dd/yyyy',
  timeFormat = 'h:mm aa'
) => {
  const startDateIso = parseISO(booking.start_date);
  const endDateIso = parseISO(booking.end_date);
  const startDate = formatDate(startDateIso, dateFormat);
  const startTime = formatDate(
    parseISO(`${booking.start_date}T${parseTimeWithTimezone(booking.pickup_time)}`),
    timeFormat
  );

  const endDate = formatDate(endDateIso, dateFormat);
  const endTime = formatDate(
    parseISO(`${booking.end_date}T${parseTimeWithTimezone(booking.dropoff_time)}`),
    timeFormat
  );

  return { startDate, endDate, startTime, endTime, startDateIso, endDateIso };
};

export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const stringify = (date, format = DEFAULT_FORMAT) =>
  date && isValid(date) ? formatDate(date, format) : undefined;

export const parse = (strOrDate, format = DEFAULT_FORMAT) => {
  if (typeof strOrDate === 'string') {
    return parseDateString(strOrDate, format, new Date());
  }

  return strOrDate && isValid(strOrDate) ? strOrDate : undefined;
};

export const parseUTC = (str, format = DEFAULT_FORMAT) => parse(str, format);

const isInclusivelyAfterDay = (date, cmp) => isSameDay(date, cmp) || isAfter(date, cmp);

const isInclusivelyBeforeDay = (date, cmp) => isSameDay(date, cmp) || isBefore(date, cmp);

const isBeforeDay = (a, b) => isBefore(startOfDay(a), startOfDay(b));

export const isBeforeToday = date => isBeforeDay(date, new Date());

export const isBetween = (date, a, b) => isAfter(date, a) && isBefore(date, b);
export const isInclusivelyBetween = (date, a, b) =>
  isInclusivelyAfterDay(date, a) && isInclusivelyBeforeDay(date, b);

export const isBeforeAndInclusivelyAfterDay = (date, a, b) =>
  isInclusivelyAfterDay(date, a) && isBeforeDay(date, b);

export const isOutside = (date, a, b) => isBefore(date, a) || isAfter(date, b);
export const isInclusivelyOutside = (date, a, b) =>
  isInclusivelyBeforeDay(date, a) || isInclusivelyAfterDay(date, b);

export const didChange = (a, b) => !a || !b || !isSameDay(a, b);

export const maybeSqlToISODate = value =>
  value ? new Date(parseInt(value, 10) * 1000).toISOString() : null;

export const maybeValidDates = dates => {
  if (!dates) {
    return {};
  }

  const startDate = parse(dates.startDate);
  const endDate = parse(dates.endDate);

  if (!startDate || !endDate) {
    return {};
  }

  return { startDate, endDate };
};
