import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withCurrentUser } from 'core/state/Globals';
import { SocialAuthActions } from 'core/helpers/SocialAuth';

import { trackSignUpMethodSelected, trackSignUpStarted } from 'tracking/account/TrackSignUps';
import Collapse from 'components/Collapse';

import EmailAvailabilityForm from '../forms/EmailAvailabilityForm';
import LoginForm from '../forms/LoginForm';
import RegisterForm from '../forms/RegisterForm';
import SocialRegisterForm from '../forms/SocialRegisterForm';
import SocialAuthButtons from '../components/SocialAuthButtons';

import {
  ModalStyled,
  ModalHeaderStyled,
  CloseButtonStyled,
  Divider,
  SignUpRow,
  SignUpButton
} from './styles/Modal';

const ContentWrapper = styled.div`
  padding: 24px;
`;

function EnsureAuthModal({ currentUser, ...props }) {
  const { modal, title, onCancelled, onAuthenticated } = props;

  const [stepId, setStepId] = useState(props.stepId || 'EmailAvailability');
  const [emailAddress, setEmail] = useState('');
  const [socialSignUpData, setSocialSignUpData] = useState(null);

  // Close this modal immediately if the user is logged in and call the handlers
  useEffect(() => {
    if (currentUser) {
      props.onAuthenticated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAuth = () => {
    modal.onRequestClose();

    if (onAuthenticated) {
      onAuthenticated();
    }
  };

  const loginSuccessHandler = ({ accountType }) => {
    onAuth();
    if (onAuthenticated) return;
    if (accountType === 'Renter' || accountType === 'Admin') {
      window.location.reload();
    }
  };

  // if a social sign up button is pressed, set returned user data and show the social auth form
  const onStartSocialSignUpSuccess = values => {
    setSocialSignUpData(values);
    setStepId('SocialRegister');
  };

  const chooseForm = ({ email, showRegisterForm }) => {
    const id = showRegisterForm ? 'Register' : 'Login';

    if (id === 'Register') {
      trackSignUpStarted(window.location.pathname);
      trackSignUpMethodSelected('Email');
    }

    setEmail(email);
    setStepId(id);
  };

  const onRegisterAuth = () => {
    onAuth();
  };

  const handleClose = () => {
    if (onCancelled) {
      onCancelled();
    }

    modal.onRequestClose();
  };

  const getTitle = () => {
    if (stepId === 'Login') {
      return 'Log into RVshare';
    }

    if (stepId === 'Register') {
      return 'Create your account';
    }

    if (stepId === 'SocialRegister') {
      return 'Complete Sign Up';
    }

    return title || '';
  };

  return (
    <ModalStyled
      id="ensure-auth-modal"
      className="auth-modal"
      {...modal}
      onRequestClose={handleClose}
    >
      <ModalHeaderStyled alignItems="flex-start" justifyContent="space-between">
        {getTitle()}
        <CloseButtonStyled type="button" onClick={handleClose} aria-label="Close">
          <span className="sr-only">Close modal</span>&times;
        </CloseButtonStyled>
      </ModalHeaderStyled>
      <ContentWrapper>

        {/* also remove social login buttons social registration form */}
        <Collapse isOpen={!currentUser && stepId !== 'SocialRegister'}>
          <SocialAuthButtons
            onSubmitSuccess={loginSuccessHandler}
            action={SocialAuthActions.LOGIN}
            onStartSocialSignUpSuccess={onStartSocialSignUpSuccess}
          />
          <Divider>
            <span>or</span>
          </Divider>
        </Collapse>

        {/* default state when form is open */}
        <Collapse isOpen={stepId === 'EmailAvailability'}>
          <EmailAvailabilityForm onSubmitSuccess={chooseForm} />
        </Collapse>

        <Collapse isOpen={stepId === 'Login'}>
          <LoginForm email={emailAddress} onSubmitSuccess={loginSuccessHandler} />
          {props.stepId ? (
            <SignUpRow>
              Don’t have an account? <SignUpButton variant="linkPrimary" type="button" onClick={() => setStepId('Register')}>Sign Up</SignUpButton>
            </SignUpRow>
          ) : null}
        </Collapse>

        <Collapse isOpen={stepId === 'Register'}>
          <RegisterForm email={emailAddress} onSubmitSuccess={onRegisterAuth} />
        </Collapse>

        <Collapse isOpen={stepId === 'SocialRegister'}>
          <SocialRegisterForm userData={socialSignUpData} onSubmitSuccess={onRegisterAuth} />
        </Collapse>
      </ContentWrapper>
    </ModalStyled>
  );
}

EnsureAuthModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withCurrentUser(EnsureAuthModal);
