import React from 'react';
import PropTypes from 'prop-types';

import Alert from 'components/Alert';
import Collapse from 'components/Collapse';

export default function ShakeAlert({ children, show, className = '' }) {
  return (
    <Collapse isOpen={!!show}>
      <Alert variant="danger" className={show ? `shake ${className}` : className}>
        {children}
      </Alert>
    </Collapse>
  );
}

ShakeAlert.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
};

ShakeAlert.defaultProps = {
  children: null,
};
