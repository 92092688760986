// import React from 'react';
// import styled from 'styled-components';

function Collapse({ isOpen, children }) {
  if (!isOpen) {
    return null;
  }

  return children;
}

export default Collapse;
