import React from 'react';
import styled from 'styled-components';

import Link from 'core/ui/Link';

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li`
  margin-bottom: 0;
`;

const LinkStyled = styled(Link)`
  padding: 12px 34px;
  display: block;
  font-size: 0.875rem;
  ${({ theme }) => theme.large`
    padding: 12px 6px;
  `}
`;

const LinkInner = styled.div`
  ${({ theme }) => theme.large`
    padding-right: 35px;
    padding-left: 10px;
  `}
`;

function UserDropdown(props) {
  const { hasSearchBar, isOwner } = props;

  return (
    <Ul>
      <Li>
        <LinkStyled href="/dashboard/" variant="dark" data-event-category="Global Element" data-event-action="Dashboard" data-event-label="Top Navigation">
          <LinkInner>Dashboard</LinkInner>
        </LinkStyled>
      </Li>
      {hasSearchBar && (
        <>
          <Li>
            <LinkStyled href="/dashboard/favorites" variant="dark" data-event-category="Global Element" data-event-action="Favorites" data-event-label="Top Navigation">
              <LinkInner>Favorites</LinkInner>
            </LinkStyled>
          </Li>
          {!isOwner && (
            <Li>
              <LinkStyled href="/dashboard/trips" variant="dark" data-event-category="Global Element" data-event-action="Favorites" data-event-label="Top Navigation">
                <LinkInner>Trips</LinkInner>
              </LinkStyled>
            </Li>
          )}
        </>
      )}
      <Li>
        <LinkStyled href="/users/logout" variant="dark" data-event-category="Global Element" data-event-action="Logout" data-event-label="Top Navigation" data-testid="logout">
          <LinkInner>Logout</LinkInner>
        </LinkStyled>
      </Li>
    </Ul>
  );
}
export default React.memo(UserDropdown);
