import React from 'react';
import styled from 'styled-components';

const InputGroupStyled = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }
`;

const InputGroupAppendStyled = styled.div`
  margin-left: -1px;
  display: flex;

  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    z-index: 2;
  }
`;

export const InputGroup = (props) => {
  return <InputGroupStyled {...props}>{props.children}</InputGroupStyled>;
};

export const InputGroupAppend = (props) => {
  return <InputGroupAppendStyled {...props}>{props.children}</InputGroupAppendStyled>;
};
