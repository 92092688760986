/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { capitalize } from 'lodash-es';

import ShakeAlert from 'components/ShakeAlert';
import TextField from 'form-fields/styled/TextField';
import EmailField from 'form-fields/styled/EmailField';
import useApi from 'core/hooks/useApi';
import { normalizeServerErrors } from 'core/helpers/API';
import InternationalPhoneField from 'form-fields/styled/InternationalPhoneField';
import { errorStyles } from 'form-fields/rebrand/fieldStyles';
import { Button } from 'core/ui';
import { trackSignUpCompleted } from 'tracking/account/TrackSignUps';
import { trackLoginCompleted } from 'tracking/account/TrackLogins';

import SocialRegisterFormValidator from '../validators/SocialRegister';
import SocialIcon from '../components/icons/SocialIcon';
import { appleBuildParams } from '../components/AppleButton';
import { fbBuildParams } from '../components/FacebookButton';

import {
  Heading,
  FieldsWrapper,
  FormGroup,
  FormGroupFlex,
  Instruction,
} from '../modals/styles/Modal';

const SocialAuthHeading = styled(Heading)`
  font-size: 1.125rem;
  margin-bottom: 2rem;
  margin-top: .5rem;

  span {
    text-transform: capitalize;
  }
`;

const RegisterForm = props => {
  const { userData, onSubmitSuccess } = props;
  const authResponse = userData?.authResponse;
  const provider = userData?.provider;
  const { hideEmail } = userData;
  const api = useApi();

  const submitHandler = async (values, { setSubmitting, setStatus, setErrors }) => {
    const headers = userData?.headers;
    const appleParams = provider === 'apple' && appleBuildParams(authResponse, values);
    const fbParams = provider === 'facebook' && fbBuildParams(authResponse, values);
    const googleParams = {
      params: {
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        ...authResponse,
      }, error: null
    }

    const { params, error } = appleParams || fbParams || googleParams;

    if (error) {
      setSubmitting(false);
      setStatus({ error: true });
      console.error('Error building params for social sign up', error);
      return;
    }

    try {
      const response = await api.post(`/v1/users/auth/${provider}/callback`, params, {
        headers,
      });

      const {
        data: {
          data: {
            id: userId,
            attributes: { 
              is_new_user: newUser, 
              last_login: lastLoginDate,
              email
            },
          },
        },
      } = response;

      if (newUser) {
        trackSignUpCompleted({
          email: values.email,
          userId,
          signUpMethod: capitalize(provider),
        });
      } else {
        trackLoginCompleted({
          // get the email address from the response, instead of the form
          email,
          userId,
          lastLoginDate,
          loginMethod: capitalize(provider),
        });
      }
      
      setSubmitting(false);
      setStatus({ success: true });
      onSubmitSuccess();
    } catch ({ response: { data, status } = {} }) {
      const errorMessage = data?.errors?.social_login?.[0];
      
      const phoneNotUnique = errorMessage?.includes('This phone number is already in use') && {
        phone: {
          number: 'This phone number is already in use',
        },
      };

      // eslint-disable-next-line no-console
      console.error(errorMessage);
      setErrors(phoneNotUnique || normalizeServerErrors(data));
      setSubmitting(false);
      setStatus({ error: true });
    }
  };

  return (
    <>
      <SocialAuthHeading>
        <SocialIcon type={provider} />
        Signing up with <span>{provider}</span>
      </SocialAuthHeading>
      <Formik
        initialValues={{
          first_name: userData?.first_name || '',
          last_name: userData?.last_name || '',
          email: userData?.email || '',
          provider: userData?.provider || '',
          phone: {
            country_alpha_code: userData?.phone?.country_alpha_code || 'US',
            country_code: userData?.phone?.country_code || '+1',
            number: userData?.phone?.number || '',
          },
        }}
        validationSchema={SocialRegisterFormValidator}
        onSubmit={submitHandler}
      >
        {({ handleSubmit, isSubmitting, status, }) => {

          const getButtonText = () => {
            if (isSubmitting) {
              return 'Signing Up';
            }
            if (status?.success) {
              return 'Signed Up';
            }
            return 'Sign Up';
          };
          
          return (
            <div id="sign-up-part3">
              <ShakeAlert show={!!status?.error && !isSubmitting}>
                Something went wrong. Please refresh the page and try again.
              </ShakeAlert>
              <form noValidate onSubmit={handleSubmit}>
                <FieldsWrapper>
                  <FormGroupFlex>
                    <div>
                      <label htmlFor="first_name">First Name</label>
                      <TextField
                        id="first_name"
                        name="first_name"
                        data-testid="first-name"
                        errorStyles={errorStyles}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="last_name">Last Name</label>
                      <TextField
                        id="last_name"
                        name="last_name"
                        data-testid="last-name"
                        errorStyles={errorStyles}
                        required
                      />
                    </div>
                  </FormGroupFlex>
                  <Instruction>
                    Enter your name exactly as it appears on your driver&apos;s license.
                  </Instruction>
                  {!hideEmail && (
                    <FormGroup>
                      <label htmlFor="email">Email</label>
                      <EmailField id="email" name="email" disabled required data-testid="email" />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <label htmlFor="phone">Phone</label>
                    <InternationalPhoneField
                      id="phone"
                      name="phone"
                      data-testid="phone"
                      autoComplete="tel-national"
                      errorStyles={errorStyles}
                      required
                    />
                  </FormGroup>
                  <Instruction>Confirm phone number is correct.</Instruction>
                </FieldsWrapper>
                <Button
                  type="submit"
                  disabled={isSubmitting || status?.success}
                  variant="coreBlueLargeAlt"
                  data-testid="sign-up"
                >
                  {getButtonText()}
                </Button>
              </form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default RegisterForm;
