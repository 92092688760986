import styled from 'styled-components';
import { Flex } from 'core/ui';

export const LogoWrapper = styled.div`
  display: inline-block;
  margin-right: 14px;

  a {
    display: flex;
    justify-content: center;
    width: 99px;
  }
`;

export const SearchInputsWrapper = styled(Flex)``;

export const HeaderComponent = styled.header`
  padding: 10px 16px;
  background: #fff;

  ${({ theme }) => theme.large`
    padding: 20px 20px 16px 20px;
  `}
`;

export const Logo = styled.img`
  height: auto;
  width: 120px;
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  min-height: 40px;
  gap: 10px;
  ${({ theme }) => theme.medium`
    flex-flow: row nowrap;
  `}
`;

export const LogoMobileNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10; // keeps datepicker on mobile from blocking logout button
  ${({ theme }) => theme.medium`
    display: block;
    margin-bottom: 0;
    width: auto;
  `}
`;

export const DesktopNavWrapper = styled.div`
  margin-left: 0;
`;
