/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LazyLoad from 'react-lazyload';

const blankSrc = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

export default function LazyImage(props) {
  const { alt, className, src, srcSet } = props;

  const imgProps = {
    ...props,
    alt: alt || '',
    className: cx(className, { blank: !src }) || null,
    src: src || blankSrc, // if src is missing, use data uri so image can be styled
    srcSet,
  };

  const placeholder = (
    <img
      {...imgProps}
      // include src as data-src and data-srcset for SSR
      data-src={src}
      data-srcset={srcSet}
      src={blankSrc}
    />
  );

  return (
    <LazyLoad once offset={100} placeholder={placeholder}>
      <img {...imgProps} />
    </LazyLoad>
  );
}

LazyImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  srcSet: PropTypes.string,
};

LazyImage.defaultProps = {
  alt: null,
  className: null,
  src: null,
  srcSet: null,
};
