import styled from 'styled-components';
import {
  fontWeight,
  borders,
  borderColor,
  borderRadius,
  buttonStyle,
} from 'styled-system';

import Box from './Box';

const Button = styled(Box)`
  appearance: none;
  display: inline-block;
  text-align: center;
  line-height: inherit;
  text-decoration: none;
  ${fontWeight};
  ${borders};
  ${borderColor};
  ${borderRadius};
  ${buttonStyle};
  ${props => props.theme.Button};

  &:disabled {
    opacity: 0.65 !important;
    cursor: default !important;
  }
`;

Button.propTypes = {
  ...fontWeight.propTypes,
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...buttonStyle.propTypes,
};

Button.defaultProps = {
  as: 'button',
  fontSize: 'inherit',
  fontWeight: 'bold',
  m: 0,
  px: 3,
  py: 2,
  color: 'white',
  bg: 'blue',
  border: 0,
  borderRadius: 4,
};

export default Button;
