import SafeTracking from 'tracking/SafeTracking';
import { format } from 'date-fns';

export const trackSignUpStarted = currentPage => {
  SafeTracking.track('Sign Up Started', { currentPage }, { rvsIntegrations: { all: false, segment: true } });
};

export const trackSignUpTypeSelected = (accountType = 'Renter') => {
  SafeTracking.track('Sign Up Type Selected', { accountType }, { rvsIntegrations: { all: false, segment: true } });
};

export const trackSignUpMethodSelected = (method) => {
  SafeTracking.track('Sign Up Method Selected', { method }, { rvsIntegrations: { all: false, segment: true } });
};

export const trackSignUpCompleted = ({
  email,
  userId,
  signUpMethod,
  accountType = 'Renter',
  inBookingFlow = false,
  registrationDate = format(new Date(), 'M/d/yy'),
}) => {
  SafeTracking.track('Sign Up Completed', {
    email,
    userId,
    signUpMethod,
    accountType,
    inBookingFlow,
    registrationDate,
  }, { rvsIntegrations: { all: false, segment: true } });
};

export default {
  trackSignUpStarted,
  trackSignUpTypeSelected,
  trackSignUpMethodSelected,
  trackSignUpCompleted,
};
