import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import canUseDOM from 'can-use-dom';

import { getStateFromDOM } from 'core/utils/client';
import { SocialAuthProviders } from 'core/helpers/SocialAuth';
import useApi from 'core/hooks/useApi';

import SocialButton from './SocialButton';

export const appleBuildParams = (authResponse, signUpValues) => {
  if (authResponse.error) {
    let error = authResponse.details ?? `Error code ${authResponse.error} received from Apple.`;

    if (authResponse.error === 'popup_closed_by_user' || authResponse.error === 'popup_blocked_by_browser') {
      error = 'The authorization popup was closed or blocked. If this issue persists, try temporarily enabling popups for this site.';
    }

    return { error }
  }

  const { id_token, code } = authResponse.authorization;
  const authResponseUser = authResponse?.user;
  let params = `id_token=${id_token}&code=${code}`;

  if (signUpValues) {
    const { first_name, last_name, phone } = signUpValues;
    // phone is an object with country_code, number, and alpha_country_code
    // has to be encoded, so object can be passed as a url param
    const encodedPhone = encodeURIComponent(JSON.stringify(phone));
    params += encodeURI(`&first_name=${first_name}&last_name=${last_name}&phone=${encodedPhone}`);
  } else if (authResponseUser?.name) {
    const { firstName, lastName } = authResponseUser.name;
    params += encodeURI(`&first_name=${firstName}&last_name=${lastName}`);
  }
  
  return { params };
};

const appleHeaders = {
  "Content-Type": "application/x-www-form-urlencoded"
};

export default function AppleButton(props) {
  const { action, onSubmitSuccess, onStartSocialSignUpSuccess } = props;
  const api = useApi();

  const refreshNonce = useCallback(async () => {
    const nonceRes = await api.post('/v1/users/nonce');

    await window.AppleID.auth.init({
      clientId: getStateFromDOM('env.appleClientId'),
      scope: 'name email',
      authorizeParams: {
        scope: 'name email'
      },
      redirectURI: window.location.origin,
      usePopup: true,
      nonce: nonceRes?.data?.nonce
    });
  }, [api]);

  const appleLoadSdk = (callback) => {
    if (!canUseDOM) return;

    const id = 'apple-jssdk';
    const firstScript = document.getElementsByTagName('script')[0];

    // if loaded
    if (document.getElementById(id)) {
      refreshNonce().then(callback);
      return;
    }

    if (!firstScript) return;

    const js = document.createElement('script');
    js.id = id;
    js.onload = () => {
      refreshNonce().then(callback);
    };
    js.src = '//appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    js.async = true;
    js.defer = true;
    firstScript.parentNode.insertBefore(js, firstScript);
  };

  const appleOnError = () => {
    refreshNonce();
  };

  const appleLogin = (callback) => {
    // callback will receive an object of type { error: string } in the case of a catch
    window.AppleID.auth.signIn().then(callback).catch(callback);
  };

  return (
    <SocialButton
      {...{
        action,
        onSubmitSuccess,
        onStartSocialSignUpSuccess
      }}
      provider={SocialAuthProviders.APPLE}
      loadSdk={appleLoadSdk}
      loginHandler={appleLogin}
      buildParams={appleBuildParams}
      headers={appleHeaders}
      onError={appleOnError}
    />
  );
}

AppleButton.propTypes = {
  action: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func,
};

AppleButton.defaultProps = {
  onSubmitSuccess: null,
};
