import React from 'react';
import MaskedInput from 'react-text-mask';

import TextField from './TextField';
import { TextInput } from './styles';

const MaskedInputStyled = (props) => {
  return (
    <MaskedInput
      {...props}
      render={(ref, maskedProps) => <TextInput ref={ref} {...maskedProps} />}
    />
  );
};

export default function MaskedTextField(props) {
  return <TextField {...props} component={MaskedInputStyled} />;
}
