import { getApiInstance } from 'core/hooks/useApi';
import { getStateFromDOM } from '../core/utils/client';

const getUserType = ({ currentUser }) => {
  if (!currentUser) {
    return 'Not Logged In';
  }

  return currentUser.group_description;
};

const getUserId = ({ currentUser }) => {
  if (!currentUser) {
    return null;
  }

  return currentUser.id || currentUser.user_id || undefined;
};

const logSegmentDisabled = () => {
  // check if the cdn has loaded the first time we try and call it and on a users first session
  if (window?.analytics?.length === 0 && !window?.analytics?._cdn && window?.sessionStorage?.getItem('pageviewsSession') === '1') {
    const api = getApiInstance();
    api.post('/v1/log-segment-disabled').catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
    // eslint-disable-next-line no-console
    console.warn(`Segment SDK failed to load.`);
  }
}

const createSafeFunction = (method, ...args) => {
  try {
    logSegmentDisabled();
    return window.analytics[method](...args);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`analytics.${method}`, args);
  }

  return null;
};

const createSingleIntegrationTracker = integration => (name, payload, options) =>
  createSafeFunction('track', name, payload, {
    ...options,
    integrations: { All: false, [integration]: true },
  });

const getCookieBanner = (key) => {
  try {
     // eslint-disable-next-line no-prototype-builtins
    if (!window?.localStorage?.hasOwnProperty(key)) {
      return false;
    }

    return JSON.parse(window.localStorage.getItem(key));
  } catch (error) {
    // If error also return initialValue
    console.error(error);
    return false;
  }
}

const SafeSegment = (method, ...args) => {
  // newRenterId is used in checkout, when a new renter is created if not logged in
  const currentUser = {
    userId: args[1]?.userId || args[1]?.newRenterId || getUserId(getStateFromDOM()),
    eventUserType: args[1]?.eventUserType || getUserType(getStateFromDOM()),
  };

  try {
    switch (method) {
      case 'trackFacebook':
        return createSingleIntegrationTracker('Facebook Pixel')(...args);
      default:
        // segment track() takes 2 or 3 arguments
        // args[0] = segment "name"
        // args[1] = data we pass to segment. need to transform by adding user info
        // args[2] = segment integrations object (optional)

        // eslint-disable-next-line no-case-declarations
        const cookiebannerDismissed = getCookieBanner('cookies_dismissed');
        // create dataWithUser without newRenterId property, since it's the same as userId
        // eslint-disable-next-line no-case-declarations
        const {newRenterId, ...dataWithUser} = { ...args[1], ...currentUser, cookiebannerDismissed };

        return createSafeFunction(method, args[0], dataWithUser, args[2]);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`analytics.${method}`, ...args);
  }
  return null;
};

export default SafeSegment;
