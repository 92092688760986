import styled from 'styled-components';
import {
  height,
  borderRadius,
} from 'styled-system';

import Box from './Box';

export const Image = styled(Box)`
  max-width: 100%;
  height: auto;

  ${height};
  ${borderRadius};
  ${props => props.theme.Image};
`;

Image.propTypes = {
  ...height.propTypes,
  ...borderRadius.propTypes,
};

Image.defaultProps = {
  as: 'img',
  m: 0,
};

export default Image;
