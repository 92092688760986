import React, {useLayoutEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import { Button, Link } from 'core/ui';

import SafeTracking from 'tracking/SafeTracking';
import useLocalStorage from 'core/hooks/useLocalStorage';
import { useDevice } from 'core/hooks/useDevice';
import useIsMobileApp from 'core/hooks/useIsMobileApp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle';
import { withCookieBanner } from 'core/state/Globals';

const Container = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.coreDarkBlue};
  border: 1px solid ${({ theme }) => theme.colors.coreDarkBlue};
  border-radius: 8px 8px 0 0;
  bottom: 0;
  color: #fff;
  display: flex;
  font-size: 0.75rem;
  justify-content: space-between;
  left: 0;
  line-height: 1rem;
  padding: 10px;
  position: fixed;
  text-align: center;
  z-index: 999;

  ${({ theme }) => theme.medium`
    border-radius: 8px;
    bottom: 25px;
    display: flex;
    font-size: 0.875rem;
    left: 30px;
    padding: 5px 10px 10px;
    text-align: left;
    width: 568px;
  `}
`;

const CTA = styled(Button)`
  align-self: auto;
  background: none;
  color: ${({ theme }) => theme.colors.white};
  flex: 0 0 0;
  font-size: 1rem;
  height: 40px;
  margin-top: 0;
  padding: 0;
  width: 76px;

  ${({ theme }) => theme.medium`
    align-self: center;
    background-color: #fff;
    color: ${theme.colors.coreDarkBlue};
    margin-left: 15px;
    padding: 12px;
  `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.coreDarkBlue};
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.75rem;
`;

const P = styled.p`
  flex: 0 0 90%;
  margin: 0;
  text-align: justify;

  ${({ theme }) => theme.medium`
    flex: 0 0 80%;
    margin: 0.25rem 0.25rem 0 0.25rem;
  `}
`;

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: underline;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

const ShortLines = styled.button`
  background: none;
  border: none;
  color: #fff;
  margin-left: 0;
  padding-left: 0;
  text-decoration: underline;
`;

function CookieBanner({ setCookieBanner }) {
  const [cookiesDismissed, setCookiesDismiss] = useLocalStorage('cookies_dismissed');
  const { isMobile } = useDevice();
  const [shortLine, setShortLine] = useState(isMobile);
  const containerRef = useRef(null);
  const isMobileApp = useIsMobileApp();

  useLayoutEffect(() => {
    setCookieBanner({ containerSize: cookiesDismissed ? 0 : containerRef.current?.offsetHeight || 0 });
  }, [shortLine, setCookieBanner, cookiesDismissed]);

  const handleClick = () => {
    setCookiesDismiss(true);
    SafeTracking.track('cookie_banner_dismissed');
  };

  const handlePolicyClick = () => {
    SafeTracking.track('cookie_banner_policy_link');
  };

  const handleExpandLines = () => {
    setShortLine(false);
  };

  return (cookiesDismissed || isMobileApp) ? null : (
    <Container id='cookie-banner' ref={containerRef}>
      <P>
        We use cookies to personalize content and ads, to provide social media features and to
        analyze our traffic. By visiting RVshare.com, you agree to the use of {shortLine && 'cook... '}
        {shortLine &&
          <ShortLines type='button' onClick={handleExpandLines}>
            Read more
          </ShortLines>
        }
        {!shortLine && 'cookies for these purposes. You can disable cookies at any time by changing your browser settings.'}
        {' '}View our{' '}
        <StyledLink
          href="/privacy-policy"
          data-event-category="Global Element"
          data-event-label="Cookie Banner Navigation"
          target="_blank"
          onClick={handlePolicyClick}
        >
          Privacy Policy.
        </StyledLink>
      </P>
      <CTA
        data-event-category="Global Element"
        data-event-action="Cookie Banner Dismiss"
        data-event-label="Cookie Banner Dismiss Button"
        onClick={handleClick}
      >
        {isMobile ? <StyledIcon icon={faTimesCircle} /> : 'Dismiss'}

      </CTA>
    </Container>
  );
}

export default withCookieBanner(CookieBanner, { includeSetter: true });
