/* eslint-disable no-param-reassign */
import { colors } from './scss-variables';

/**
 * Add button styles ("variants") here.
 * They will automatically have a default size & whatever sizes you define below
 * (see the `buttonSizes` constant for this)
 */
const buttonColors = {
  blue: {
    color: '#fff',
    backgroundColor: colors.primaryBlue,
    borderColor: 'transparent',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: colors.primaryBlueHover,
      color: '#fff',
      textDecoration: 'none',
    },
    '&:active': {
      backgroundColor: colors.darkBlue,
    },
  },
  yellow: {
    color: colors.darkBlue,
    backgroundColor: colors.yellow,
    borderColor: 'transparent',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: colors.yellowHover,
      color: colors.darkBlue,
      textDecoration: 'none',
    },
    '&:active': {
      backgroundColor: colors.yellowPressed,
    },
  },
  greyBorder: {
    backgroundColor: 'transparent',
    color: colors.primaryBlue,
    border: `1px solid ${colors.lightGrey}`,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: colors.lightGrey,
      color: colors.darkBlue,
    },
    '&:focus': {
      border: `1px solid ${colors.primaryBlue}`,
      outline: 'none',
    },
    '&.active': {
      backgroundColor: colors.primaryBlue,
      borderColor: 'transparent',
      color: '#fff',
    },
    '&.active:hover': {
      backgroundColor: colors.primaryBlue,
      borderColor: 'transparent',
      color: '#fff',
    },
    '&.active:hover g': {
      fill: '#fff',
    },
    '&.active:hover svg, &.active:hover span': {
      color: '#fff',
    },
    '&:hover g': {
      fill: colors.darkBlue,
    },
    '&:hover svg': {
      color: colors.darkBlue,
    },
    '&:hover span': {
      color: colors.darkBlue,
    },
  },
  link: {
    backgroundColor: 'transparent',
    color: colors.darkBlue,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.primaryBlue,
    },
    '&:focus': {
      backgroundColor: 'transparent',
      outline: 'none',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  linkPrimary: {
    backgroundColor: 'transparent',
    color: colors.primaryBlue,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.darkBlue,
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  linkBlue: {
    backgroundColor: 'transparent',
    color: '#0A6EB8',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.darkBlue,
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  linkMutedGrey: {
    backgroundColor: 'transparent',
    color: colors.mutedGrey,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#000',
    },
    '&:focus': {
      backgroundColor: 'transparent',
      outline: 'none',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  blueBorder: {
    backgroundColor: 'transparent',
    border: `1px solid ${colors.babyBlue}`,
    color: colors.primaryBlue,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: colors.primaryBlue,
      color: '#fff',
    },
  },
  transparent: {
    backgroundColor: 'transparent',
    color: colors.darkBlue || "#0a2239",
    border: '1px solid transparent',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.primaryBlue,
    },
    '&:focus': {
      backgroundColor: 'transparent',
      outline: 'none',
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: colors.primaryBlue,
    },
    '&.dropdown-open': {
      color: colors.primaryBlue,
    },
  },
  facebook: {
    backgroundColor: '#49639f',
    borderColor: '#49639f',
    borderWidth: '1px',
    color: '#fff',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: '#637db8',
    },
  },
  google: {
    backgroundColor: '#0e6cb5',
    border: '1px solid #dadce0',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1288e4'
    }
  },
  apple: {
    backgroundColor: '#414141',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#5b5b5b'
    }
  },
  blueNoHover: {
    color: '#fff',
    backgroundColor: colors.primaryBlue,
    borderColor: 'transparent',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: colors.primaryBlue,
      color: '#fff',
    },
    '&:active': {
      backgroundColor: colors.primaryBlue,
      color: '#fff',
    },
  },
  white: {
    color: colors.coreDarkBlue,
    backgroundColor: '#fff',
    borderColor: 'transparent',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: colors.coreBlueHover,
      color: '#fff',
      textDecoration: 'none',
    },
    '&:active': {
      backgroundColor: colors.coreDarkBlue,
    },
  },
  // primary blue after rebrand
  coreBlue: {
    color: '#fff',
    backgroundColor: colors.coreBlue,
    borderColor: 'transparent',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: colors.coreBlueHover,
      color: '#fff',
      textDecoration: 'none',
    },
    '&:active': {
      backgroundColor: colors.coreDarkBlue,
    },
  },
  coreBlueBorder: {
    backgroundColor: 'transparent',
    border: `2px solid ${colors.coreBlue}`,
    color: colors.coreBlue,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.coreBlueHover,
      borderColor: colors.coreBlueHover,
    },
  },
  coreWhiteBorder: {
    backgroundColor: colors.coreBlue,
    border: `2px solid ${colors.coreBlue}`,
    color: '#fff',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: colors.coreBlueHover,
      color: colors.white,
      borderColor: colors.coreBlueHover,
    },
  },
  coreGreyBlueBorder: {
    backgroundColor: colors.grey100,
    border: `2px solid ${colors.coreBlue}`,
    color: colors.coreBlue,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: colors.grey100,
      color: colors.coreBlueHover,
      borderColor: colors.coreBlueHover,
    },
  },
};

const buttonSizes = {
  Block: {
    display: 'block',
    width: '100%',
  },
  Large: {
    display: 'block',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '20px',
    width: '100%',
  },
  Inline: {
    display: 'inline-block',
    padding: 0,
    fontSize: '1em',
    fontWeight: 'inherit',
  },
  LargeInline: {
    display: 'inline-block',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '20px',
  },
  // large after rebrand
  LargeAlt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    fontSize: '1.125rem',
    fontWeight: '600',
    height: '56px',
    width: '100%',
    '&:focus-visible': {
      outline: `2px solid ${colors.coreBlue}`,
      outlineOffset: '1px',
    }
  },
  // large after rebrand
  MediumAlt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    fontWeight: '600',
    height: '40px',
    width: '100%',
    '&:focus-visible': {
      outline: `2px solid ${colors.coreBlue}`,
      outlineOffset: '1px',
    }
  }
};

const composeButtonWithSizes = (colorName, colorStyles) => {
  return Object.keys(buttonSizes).reduce((styles, size) => {
    styles[colorName + size] = {
      ...colorStyles,
      ...buttonSizes[size],
    };

    return styles;
  }, {});
};

export default function ButtonStyles() {
  const colorsWithSizing = Object.keys(buttonColors).reduce((styles, color) => {
    styles[color] = buttonColors[color];

    const buttonSizesWithColors = composeButtonWithSizes(color, buttonColors[color]);
    Object.keys(buttonSizesWithColors).forEach((sizesWithColor) => {
      styles[sizesWithColor] = buttonSizesWithColors[sizesWithColor];
    });

    return styles;
  }, {});

  return colorsWithSizing;
}
