import React from 'react';
import styled from 'styled-components';

import SafeTracking from 'tracking/SafeTracking';
import { Link, Button as BaseButton } from 'core/ui';
import Icon from 'core/ui/Icon';
import Dropdown from 'components/DropdownCustom';

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li`
  margin-bottom: 0;
`;

const LinkStyled = styled(Link)`
  padding: 12px 34px;
  display: block;
  font-size: 0.875rem;

  ${({ theme }) => theme.large`
    padding: 12px 6px;
  `}
`;

const LinkInner = styled.div`
  ${({ theme }) => theme.large`
    padding-right: 10px;
    padding-left: 10px;
  `}
`;

const dropdownStyles = {
  padding: 0,
  left: '-30px',
  width: '125px',
};

const Button = styled(BaseButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 1rem;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 0;
  padding-right: 4px;
  ${({ theme }) => theme.large`
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
  `}
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const ArrowIcon = styled(({ dropdownOpen, ...rest }) => <Icon {...rest} />)`
  vertical-align: middle;
`;

function HelpLinks({ isAdminOrOwner }) {
  return (
    <Ul>
      <Li>
        <LinkStyled href="https://help.rvshare.com/hc/en-us" variant="dark" target="_blank" rel="noopener noreferrer" data-event-category="Global Element" data-event-action="Help Center" data-event-label="Top Navigation">
          <LinkInner>Help Center</LinkInner>
        </LinkStyled>
      </Li>
      {(isAdminOrOwner) &&
        <Li>
          <LinkStyled href="https://owner-toolkit.rvshare.com/" variant="dark" target="_blank" rel="noopener noreferrer" data-event-category="Global Element" data-event-action="Owner Toolkit" data-event-label="Top Navigation">
            <LinkInner>Owner Toolkit</LinkInner>
          </LinkStyled>
        </Li>
      }
      <Li>
        <LinkStyled href="https://www.surveymonkey.com/r/feedbackcenter" variant="dark" target="_blank" rel="noopener noreferrer" data-event-category="Global Element" data-event-action="Give Feedback" data-event-label="Top Navigation">
          <LinkInner onClick={() => SafeTracking.track('Give feedback header click')}>Give Feedback</LinkInner>
        </LinkStyled>
      </Li>
    </Ul>
  );
}

function HelpDropdown({ isAdminOrOwner }) {
  const button = ({ handleToggle, open }) => (
    <Button
      onClick={handleToggle}
      data-event-category="Global Element"
      data-event-action="Help"
      data-event-label="Top Navigation"
    >
      Help &nbsp;
      <ArrowIcon icon={open ? 'angle-up' : 'angle-down'} dropdownOpen={open} />
    </Button>
  );

  return (
    <Dropdown
      button={button}
      id="help-nav"
      dropdownStyles={dropdownStyles}
    >
      <HelpLinks isAdminOrOwner={isAdminOrOwner} />
    </Dropdown>
  );
}

export default React.memo(HelpDropdown);
