/* eslint-disable no-console */

const createSafeFunction = lvl => (...args) => {
  try {
    return window.Rollbar[lvl](...args);
  } catch (e) {
    console.warn(`Rollbar.${lvl}`, args);
  }

  return null;
};

export default {
  critical: createSafeFunction('critical'),
  error: createSafeFunction('error'),
  warning: createSafeFunction('warning'),
  info: createSafeFunction('info'),
  debug: createSafeFunction('debug'),
};
