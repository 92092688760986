import React from 'react';
import styled from 'styled-components';

import Dropdown from 'components/DropdownCustom';
import { Link, Button as BaseButton } from 'core/ui';
import Icon from 'core/ui/Icon';
import { GROUPS as USER_GROUP } from 'core/helpers/User';
import useSetUserId from 'core/hooks/useSetUserId';

import UserImageAndName from './UserImageAndName';
import UserDropdown from './UserDropdown';

const InboxIcon = styled(props => <Icon {...props} />)`
  && {
    font-size: 19px;
    vertical-align: middle;
  }
`;

const dropdownStyles = {
  padding: 0,
};

const ArrowIcon = styled(({ dropdownOpen, ...rest }) => <Icon {...rest} />)`
  vertical-align: middle;
`;

const Button = styled(BaseButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 0.875rem;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 0;
  padding-right: 4px;
  white-space: nowrap;
  margin-left: 14px;
  ${({ theme }) => theme.large`
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
  `}
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

function UserNav({ currentUser, hasSearchBar }) {
  const button = ({ handleToggle, open }) => (
    <Button onClick={handleToggle}>
      <UserImageAndName currentUser={currentUser} />
      <ArrowIcon icon={open ? 'angle-up' : 'angle-down'} dropdownOpen={open} />
    </Button>
  );

  useSetUserId(currentUser.user_id);

  return (
    <>
      <Dropdown
        button={button}
        dropdownStyles={dropdownStyles}
        id="user-nav"
        data-event-category="Global Element"
        data-event-label="Top Navigation"
      >
        <UserDropdown
          hasSearchBar={!!hasSearchBar}
          isOwner={currentUser.group_id === USER_GROUP.OWNER}
        />
      </Dropdown>
      {hasSearchBar && (
        <Link
          href="/dashboard/inbox/"
          variant="dark"
          data-event-category="Global Element"
          data-event-action="Inbox Icon"
          data-event-label="Top Navigation"
        >
          <InboxIcon icon={['far', 'envelope']} />
        </Link>
      )}
    </>
  );
}

export default React.memo(UserNav);
