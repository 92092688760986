import React from 'react'
import ShakeAlert from 'components/ShakeAlert';
import styled from 'styled-components';
import { Flex, Button } from 'core/ui';
import { CustomInput } from 'reactstrap';

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const RadioInput = styled(CustomInput)`
  label {
    margin-left: 0.5rem;
  }
`;

const SubmitButton = styled(Button).attrs({ type: 'submit', variant: 'yellowLarge' })`
  margin-top: 25px;
`;


export default function OtpDeliveryChoice({error, isSubmitting, disabled, handleSubmit, otp_delivery_method, phone_last_4, setFieldValue}) {
  return (
    <Flex flexDirection="column">
      <ShakeAlert show={!!error && !isSubmitting}>{error}</ShakeAlert>
      <h3>We need to verify your identity</h3>
      <p>
        Help us keep your account more secure. Choose how your verification code is sent to your
        phone:
      </p>
      <form id="login-form" className="login-form" noValidate onSubmit={handleSubmit}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <FormGroup check>
            <RadioInput
              id="deliver_otp_by_sms"
              type="radio"
              name="otp_delivery_method"
              value="sms"
              checked={otp_delivery_method === 'sms'}
              onChange={() => {
                setFieldValue('otp_delivery_method', 'sms');
              }}
              label={`Text Message to ***-***-${phone_last_4}`}
            />
            <RadioInput
              id="deliver_otp_by_call"
              type="radio"
              name="otp_delivery_method"
              value="call"
              checked={otp_delivery_method === 'call'}
              onChange={() => {
                setFieldValue('otp_delivery_method', 'call');
              }}
              label={`Voice Call to ***-***-${phone_last_4}`}
            />
          </FormGroup>
        </Flex>
        <SubmitButton disabled={disabled}>Send Verification Code</SubmitButton>
      </form>
      <p>
        Need help? Call Customer Experience at <a href="tel:8884820234">888-482-0234</a>
      </p>
    </Flex>
  );
}
