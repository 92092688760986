import { useEffect } from 'react';

const USER_ID = 'user_id';

function useSetUserId(id = null) {
  useEffect(() => {
    if (id) {
      const idInLocalStorage = localStorage.getItem(USER_ID);
      // eslint-disable-next-line
      if (idInLocalStorage && idInLocalStorage != id || !idInLocalStorage) {
        localStorage.setItem(USER_ID, id);
      }
    }
  }, [id]);
}

export default useSetUserId;
