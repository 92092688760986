import React, { useEffect, useRef, useCallback } from 'react';

export default function OutsideClickHandler(props) {
  const { children, onOutsideClick } = props;

  const childNodeRef = useRef(null);

  const handleClickOutside = useCallback(
    (e) => {
      const isDescendantOfRoot = childNodeRef.current && childNodeRef.current.contains(e.target);
      if (!isDescendantOfRoot) {
        onOutsideClick();
      }
    },
    [onOutsideClick],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => document.removeEventListener('mousedown', handleClickOutside, true);
  }, [handleClickOutside]);

  return <div ref={childNodeRef}>{children}</div>;
}
