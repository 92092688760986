import { css } from 'styled-components';

import { breakpoints as bp } from './scss-variables';

export const breakpoints = bp;

export const sizes = Object.keys(bp).reduce((agg, k) => {
  const num = parseInt(bp[k], 10);

  if (Number.isNaN(num)) return agg;

  // eslint-disable-next-line no-param-reassign
  agg[k] = num;

  return agg;
}, {});

const betweenQuery = (min, max) => `@media screen and (min-width: ${min}px) and (max-width: ${max - 1}px)`;
const upQuery = min => `@media screen and (min-width: ${min}px)`;
const downQuery = max => `@media screen and (max-width: ${max - 1}px)`;

// I was going to be fancy & programmatically generate these,
// but for now just make sure the sizes below are in sync w/ the sizes above
export const mediaQueriesForHide = {
  mobile: downQuery(sizes.sm),
  small: betweenQuery(sizes.sm, sizes.md),
  medium: betweenQuery(sizes.md, sizes.lg),
  large: betweenQuery(sizes.lg, sizes.xl),
  xlarge: upQuery(sizes.xl),
};

const mediaHelpers = {
  mobile: downQuery(sizes.sm),
  small: upQuery(sizes.sm),
  betweenSmallMobile: betweenQuery(sizes.mobile, sizes.small),
  belowMedium: downQuery(sizes.md),
  medium: upQuery(sizes.md),
  betweenMediumLarge: betweenQuery(sizes.md, sizes.lg),
  belowLarge: downQuery(sizes.lg),
  large: upQuery(sizes.lg),
  belowXlarge: downQuery(sizes.xl),
  xlarge: upQuery(sizes.xl),
  // retina queries unfortunately copied/mirrored from _mixins.scss
  retina2x: `@media
    only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (-o-min-device-pixel-ratio: 5/4),
    only screen and (min-resolution: 1.25dppx)
  `,
  retina3x: `@media
    only screen and (-webkit-min-device-pixel-ratio: 2.25),
    only screen and (-o-min-device-pixel-ratio: 9/4),
    only screen and (min-resolution: 2.25dppx)
  `,
  retina4x: `@media
    only screen and (-webkit-min-device-pixel-ratio: 3.25),
    only screen and (-o-min-device-pixel-ratio: 13/4),
    only screen and (min-resolution: 3.25dppx)
  `,
};

// Iterate through the sizes and create a media template
export default Object.keys(mediaHelpers)
  .reduce((acc, label) => {
    acc[label] = (...args) => css`
      ${mediaHelpers[label]} {
        ${css(...args)}
      }
    `;

    return acc;
  }, {});
