import get from 'just-safe-get';

import { parse as parseDateString } from 'core/helpers/DateFns';

const parseTravelDates = (travelDates) => {
  if (!travelDates) {
    return {};
  }

  return {
    startDate: parseDateString(travelDates.startDate),
    endDate: parseDateString(travelDates.endDate),
  };
};

export const parseGlobals = (domState) => {
  return {
    ...domState,
    travelPreferences: {
      ...domState.travelPreferences,
      travelDates: parseTravelDates(get(domState, 'travelPreferences.travelDates')),
    },
  };
};
