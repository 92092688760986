import React from 'react';
import FooterListItem from './FooterListItem';
import {
  FooterCopyrightWrapper,
  FooterCopyright,
  FooterCopyrightSpacer,
  FooterCopyrightList,
} from './styles';

const POLICY_LINKS = [
  { name: 'Terms of Service', href: '/terms-of-service', showBullet: true },
  { name: 'Privacy Policy', href: '/privacy-policy', showBullet: true },
  {
    name: 'Respectful Community Policy',
    href: '/respectful-community-policy',
    showBullet: true,
  },
  { name: 'Digital Accessibility at RVshare', href: '/digital-accessibility', showBullet: false },
];

export default function FooterCopyrightSection() {
  return (
    <FooterCopyrightWrapper>
      <FooterCopyright>
        &copy; {`Copyright ${new Date().getFullYear()} RVshare.com`}
        <FooterCopyrightSpacer />
      </FooterCopyright>
      <FooterCopyrightList>
        {POLICY_LINKS.map(link => (
          <FooterListItem
            key={link.name}
            item={{ name: link.name, href: link.href }}
            showBullet={link.showBullet}
          />
        ))}
      </FooterCopyrightList>
    </FooterCopyrightWrapper>
  );
}
