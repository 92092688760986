import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'core/ui/Button';

const Li = styled.li`
  display: inline-block;
  margin-bottom: 0;
`;

const LiMobile = styled.li`
  width: 100%;
  text-align: left;
  font-weight: normal;
  padding: 12px 34px;

  button {
    font-weight: 400;
    padding: 0;
  }
`;


function LoginRegisterListItems({ openRegisterModal, openLoginModal, isMobileNav = false }) {
  const LI = isMobileNav ? LiMobile : Li;

  return (
    <>
      <LI>
        <Button
          variant="link"
          data-event-category="Global Element"
          data-event-action="Register"
          data-event-label="Top Navigation"
          id="registration-modal-trigger"
          onClick={openRegisterModal}
          data-testid="registration-modal-trigger"
        >
          Sign Up
        </Button>
      </LI>
      <LI>
        <Button
          variant="link"
          data-event-category="Global Element"
          data-event-action="Log In"
          data-event-label="Top Navigation"
          id="login-modal-trigger"
          onClick={openLoginModal}
          data-testid="login-modal-trigger"
        >
          Log In
        </Button>
      </LI>
    </>
  );
}

LoginRegisterListItems.propTypes = {
  openRegisterModal: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
};

export default LoginRegisterListItems;
