import React from 'react';
import Modal from 'react-modal';

export default function ReactModalAdapter({ className, ariaModal = true, ...props }) {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      closeTimeoutMS={300}
      bodyOpenClassName="ReactModal__Body--open"
      htmlOpenClassName="ReactModal__Html--open"
      aria={{
        modal: ariaModal,
      }}
      // we don't need to hide the app behind the modals for screen readers, since we're using aria-modal="true" on the modal itself
      ariaHideApp={false}
      {...props}
    />
  );
}
