import { useCallback } from 'react';
import { capitalize } from 'lodash-es';

import useApi from 'core/hooks/useApi';
import { trackSignUpMethodSelected, trackSignUpStarted } from 'tracking/account/TrackSignUps';
import { trackLoginCompleted } from 'tracking/account/TrackLogins';
import { SocialAuthActions } from 'core/helpers/SocialAuth';

const parseJwt = (token) => {
  if (!token) return null;

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return `%${  (`00${  c.charCodeAt(0).toString(16)}`).slice(-2)}`;
  }).join(''));
  return JSON.parse(jsonPayload);
}

const useSocialLoginHandler = (
  reloadUser,
  onSubmitSuccess,
  onStartSocialSignUpSuccess,
  onError,
  loadingHandler,
  logError,
  provider,
  action,
  loginHandler,
  buildParams,
  headers
) => {
  const api = useApi();

  // return handler callback
  return useCallback(() => {
    const handleError = message => {
      logError(
        message ||
          `There was a problem connecting to ${capitalize(provider)}. Please try again`
      );
      loadingHandler(false);
      if (onError) {
        onError();
      }
    };

    const googleProfileFields = async authResponse => {
      const bearer = { Authorization: `Bearer ${authResponse.access_token}` };

      try {
        const resp = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', { headers: bearer });
        const json = await resp.json();

      const { email, given_name: firstName, family_name: lastName } = json;
      
      return { email, firstName, lastName, hideEmail: false }
      } catch (error) {
        handleError(error);
      }
    }

    const profileFields = async authResponse => {
      if (authResponse.token_type === 'Bearer') { return googleProfileFields(authResponse) }

      // Apple returns an id token which we can parse. Facebook returns it in an authResponse object
      const idToken = authResponse?.id_token || authResponse?.authorization?.id_token || null;
      const parsedToken = parseJwt(idToken);
      const email = parsedToken?.email || authResponse?.email || null;
      const firstName = parsedToken?.given_name || authResponse?.first_name || null;
      const lastName = parsedToken?.family_name || authResponse?.last_name || null;
      const hideEmail = parsedToken?.is_private_email === "true";

      return { email, firstName, lastName, hideEmail }
    }

    const callback = async authResponse => {
      if (!authResponse) handleError();

      const { email, firstName, lastName, hideEmail } = await profileFields(authResponse)

      const socialSignupObj = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone: {
          country_alpha_code: null,
          country_code: null,
          number: null,
        },
        provider,
        headers,
        hideEmail,
        authResponse,
      }
      const startSignUpHandler = () => {
        if (onStartSocialSignUpSuccess) onStartSocialSignUpSuccess(socialSignupObj)
      }

      // for Sign Ups, we need to add authentication info to state
      // and open the sign up form, so the user can complete their profile
      // before logging them in
      if (action === SocialAuthActions.REGISTER) {
        // track social provider selected for sign up
        trackSignUpMethodSelected(capitalize(provider));

        // we need to get the email address from the token to move on
        if (email) {
          // open sign up form
          startSignUpHandler();
        } else {
          handleError('Unable to retrieve email address from social provider. Please reload and try again.');
        }
          loadingHandler(false);
      // for Log Ins, we can just log the user in
      } else if (action === SocialAuthActions.LOGIN) {

        // function builds parameters to send to api
        const { params, error } =
        typeof buildParams === 'function'
          ? buildParams(authResponse)
          : { params: authResponse, error: null };

        if (error) {
          // can pass true to just use a default error message
          handleError(error === true ? null : error);
          return;
        }

        try {
          const emailAvailable = await api.get('/v1/users/email-availability', { params: { email } });

          // if user is trying to login with a social account that is not associated with an account
          // we need to start the sign up process
          if (emailAvailable?.data?.available === true) {
            trackSignUpStarted( window.location.pathname );
            trackSignUpMethodSelected(capitalize(provider));
            startSignUpHandler();
            return;
          }

        } catch (e) {
          handleError('There was a problem connecting to the server. Please try again later.');
          loadingHandler(false);
        };

        try {
          const response = await api.post(`/v1/users/auth/${provider}/callback`, params, {
            headers,
          });

          const { data: { data: { type, id: userId, attributes = {} } = {} } = {} } = response;

          const isRenter = type === 'renters';
          const isOwner = type === 'owners';

          loadingHandler(false);
          trackLoginCompleted({
            email: attributes.email,
            userId,
            lastLogin: attributes.last_login,
            accountType: type === 'owners' ? 'Owner' : 'Renter',
            loginMethod: provider,
          });

          reloadUser(attributes.cookie_fields);

          if (onSubmitSuccess) {
            onSubmitSuccess({
              data: {
                user: {
                  id: userId,
                  ...attributes,
                },
              },
              accountType: isOwner ? 'Owner' : 'Renter',
              dismissModal: isRenter,
              provider,
            });
          }
        } catch (e) {
          const errorResponse = e.response?.data?.errors?.social_login;
          const userNotFound = errorResponse?.[0]?.includes('not found') && 'User account not found. Please refresh the page and click "Sign Up".';
          const errorArray = Array.isArray(errorResponse) && errorResponse.join('\n\n');

          handleError(userNotFound || errorArray);
          loadingHandler(false);
        }
      } else {
        handleError();
      }
    }

    loadingHandler(true);
    logError(null);
    loginHandler(callback);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);
};

export default useSocialLoginHandler;
