import React from 'react';
import NavLinks from '../Nav/NavLinks';
import UserNav from '../Nav/UserNav';
import HelpDropdown from '../Nav/HelpDropdown';

function DesktopNav({ currentUser, hasSearchBar, isAdminOrOwner }) {
  const isLoggedIn = !!currentUser;
  return (
    <NavLinks isLoggedIn={isLoggedIn} currentUser={currentUser} hasSearchBar={hasSearchBar}>
      {isLoggedIn && <UserNav currentUser={currentUser} hasSearchBar={hasSearchBar} />}
      <HelpDropdown isAdminOrOwner={isAdminOrOwner} />
    </NavLinks>
  );
}

export default React.memo(DesktopNav);
