import styled from 'styled-components';

export const FooterComponent = styled.footer`
  background-color: #122945;
  color: #fff;
  position: relative;
  width: 100%;
  min-height: 550px;
  a {
    text-decoration: none;
  }

  ${({ theme }) => theme.belowLarge`
    flex-direction: column;
  `}
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 7.43% 2.08%;
  padding-bottom: calc(7.43% - 36px);

  ${({ theme }) => theme.belowLarge`
    flex-direction: column;
    padding: 1.5rem;
  `}

  ${({ theme }) => theme.mobile`
    padding-bottom: 0;
  `}
`;

export const AllColumnsWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.belowLarge`
    flex-wrap: wrap;
    width: 100%;
  `}
  ${({ theme }) => theme.mobile`
    font-size: 14px;
    margin-bottom: 1rem;
  `}
`;

export const ColumnWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.belowLarge`
    width: 100%;
    ul:first-child {
      margin-right: 30px;
    }
  `}
  ${({ theme }) => theme.mobile`
    flex-direction: row;
    align-items: flex-start;
    ul {
      margin-right: 10px;
    }
    ul:first-child {
      margin-right: 15px;
    }
  `}
`;

export const Column = styled.ul`
  margin: 0 73.5px 0 0;
  list-style: none;
  padding: 0;
  ${({ theme }) => theme.belowLarge`
    width: 30%;
    margin-bottom: 30px;
  `}
  ${({ theme }) => theme.mobile`
    width: 50%;
  `}
  li {
    margin-bottom: 8px;
    a {
      color: #fff;
    }
  }
  h4 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0 25px 0;
  }
  img {
    width: 100px;
    height: auto;
    margin-top: 5px;
  }
`;
export const SocialColumn = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  div {
    display: flex;
    a:last-child svg {
      margin-right: 0;
    }
  }

  ${({ theme }) => theme.mobile`
    margin-bottom: 38px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const ListItem = styled.li`
  color: #fff;
  display: flex;
  align-items: center;
`;

export const SocialListItem = styled.a`
  display: flex;
  align-items: flex-start;
  height: 25px;
  margin-bottom: 21px;
  svg {
    height: 32px;
    margin-right: 20px;
    fill: #fff;
  }
`;

export const AppListItem = styled.li`
  width: 80%;
  svg {
    max-width: 136px;
  }
`;

export const FooterCopyrightWrapper = styled.section`
  display: flex;
  flex-direction: row;
  padding: 0 34px 12px;

  ${({ theme }) => theme.belowLarge`
    flex-direction: column;
    font-size: 11px;
    position: relative;
    padding: 1.5rem;
  `}

  ${({ theme }) => theme.mobile`
    padding-top: 0;
  `}
`;

export const FooterCopyright = styled.p`
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 0;

  ${({ theme }) => theme.belowLarge`
    font-size: 11px;
    flex-direction: column;
    align-items: flex-start;
  `}
  a {
    color: #fff;
  }
`;

export const FooterCopyrightList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 13px;

  ${({ theme }) => theme.belowLarge`
    font-size: 11px;
    flex-direction: column;
    align-items: flex-start;
  `}

  a {
    color: #fff;
  }
`;

export const FooterCopyrightSpacer = styled.span`
  width: 3px;
  height: 3px;
  border-radius: 3px;
  margin: 0 11px;
  background-color: #fff;
  ${({ theme }) => theme.belowLarge`
    display: none;
  `}
`;
