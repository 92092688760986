import SafeTracking from 'tracking/SafeTracking';
import { format } from 'date-fns';

export const trackLoginCompleted = ({
  email, // $ from mixpanel doc
  userId,
  lastLoginDate,
  // Facebook is handled separately and can override
  loginMethod = 'Email',
  // 'Owner' logins are done via lyrv
  accountType = 'Renter',
}) => {
  SafeTracking.track('Login Completed', {
    email,
    userId,
    loginMethod,
    accountType,
    // eventUserType always returns "Not Logged In" in Mixpanel unless we pass it here from accountType
    eventUserType: accountType,
    lastLoginDate: !!lastLoginDate && format(new Date(lastLoginDate), 'M/d/yy'),
  }, { rvsIntegrations: { all: false, segment: true } });
};

export default {
  trackLoginCompleted,
};
