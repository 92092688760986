import styled from 'styled-components';

import { Button } from 'core/ui';
import { FormStylesWrapper } from 'form-fields/rebrand/fieldStyles';

import Modal, { CloseButtonStyled as CloseButton, ModalHeader } from 'components/Modal';

export const Divider = styled.div`
  padding: 13px 0;
  text-align: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${props => props.theme.colors.grey300};
  }

  span {
    display: inline-block;
    vertical-align: top;
    background: #fff;
    padding: 0 10px;
    position: relative;
    z-index: 2;
  }
`;

export const Heading = styled.h2`
  font-size: 1.75rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  padding: 1.5rem;
`;

export const ModalStyled = styled(Modal)`
  // the form gets too tall, so we need to be able to scroll
  overflow-y: auto !important;
  // target the modal window
  > [class$='__content'] {
    border-radius: 15px;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ModalHeaderStyled = styled(ModalHeader)`
  display: block;
  font-size: 1.125rem;
  border-bottom: 1px solid ${props => props.theme.colors.grey400};
  font-weight: 600;
  text-align: center;
  position: relative;
`;

export const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  margin: auto;
  width: 50px;
  height: 50px;
  line-height: 50px;
  padding: 0;
  top: 3px;
  right: 3px;
  border-radius: 50%;

  &:focus-visible {
    opacity: 1;
  }
`;

export const ModalFooter = styled.div`
  padding: 0 1.5rem 1.5rem;
  text-align: center;

  p {
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 600;
  }

  button, a {
    padding: 0;
    text-decoration: underline;
    white-space: nowrap;

    &:hover {
      color: ${props => props.theme.colors.coreBlueHover};
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid ${({ theme }) => theme.colors.coreBlueHover};
    }
  }
`;

export const FieldsWrapper = styled(FormStylesWrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Instruction = styled.small`
  margin-top: -1rem;
  margin-bottom: 1rem;
  padding-left: 5px;
  color: ${props => props.theme.colors.grey600};
  font-size: .75rem;
  line-height: 24px;
`

export const FormGroup = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  text-align: left;
  position: relative;
`;

export const FormGroupFlex = styled(FormGroup)`
  display: flex;
  gap: 10px;

  > div {
    width: 50%;
    position: relative;
  }
`;
export const SignUpRow = styled.div`
  text-align: center;
  font-size: 1.125rem;
  padding-top: 28px;

`;

export const SignUpButton = styled(Button)`
  text-decoration: underline;
  padding: 0;
  font-weight: 300;

  &:hover {
    color: ${props => props.theme.colors.coreBlueHover};
  }
`;
