export const SocialAuthActions = {
  REGISTER: 'Sign Up',
  LOGIN: 'Log in'
};

export const SocialAuthProviders = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  APPLE: 'apple'
};
