import isEmpty from 'just-is-empty';

import { isNil } from './Lang';

export function isObject(value) {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

export const prune = obj =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];

    // Skip empty values
    if (isNil(value)) {
      return acc;
    }

    if (isObject(value) && isEmpty(value)) {
      return acc;
    }

    return {
      ...acc,
      [key]: value || undefined,
    };
  }, {});

export const renameKey = (oldKey, newKey, obj) => {
  const { [oldKey]: old, ...others } = obj;

  return {
    ...others,
    [newKey]: old,
  };
};
