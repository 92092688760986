import React from 'react';
import update from 'immutability-helper';
import { Container, Subscribe } from 'unstated';
import { compose, setDisplayName, wrapDisplayName } from 'recompose';
import canUseDOM from 'can-use-dom';

const enhance = name => setDisplayName(wrapDisplayName(name, 'registeredModal'));

export default class ModalsContainer extends Container {
  static displayName = 'Modals';

  constructor() {
    super();

    this.state = {
      modalStack: new Map([]),
    };

    this.registry = {};
  }

  register = (name, ModalComponent) => {
    const Component = enhance(name)(ModalComponent);

    this.registry[name] = Component;
  };

  _createModalState = (name, props) => ({
    component: this.registry[name],
    name,
    props,
    isOpen: true,
  });

  _addAriaAttribute = () => {
    const elems = document.querySelectorAll('div[data-hypernova-key]');
    elems.forEach((element) => {
      element.setAttribute('aria-hidden', 'true');
    });
  };

  removeAriaAttribute = () => {
    const elems = document.querySelectorAll('div[data-hypernova-key]');
    elems.forEach((element) => {
      element.removeAttribute('aria-hidden');
    });
  };

  showModal = (name, props = {}) => {
    if (!this.registry[name]) {
      // eslint-disable-next-line no-console
      console.error(`Could not find modal named "${name}" in registry.`);
    }

    this._addAriaAttribute();

    const newState = update(this.state, {
      modalStack: {
        $add: [[name, this._createModalState(name, props)]],
      },
    });

    return this.setState(newState);
  };

  setupToggle = name => () => {
    const newState = update(this.state, {
      modalStack: original => update(original, {
        $add: [[name, { ...original.get(name), isOpen: false }]],
      }),
    });

    return this.setState(newState);
  };

  setupDestroy = name => () => {
    const newState = update(this.state, {
      modalStack: {
        $remove: [name],
      },
    });

    return this.setState(newState);
  };
}

const withClientModals = compose(
  BaseComponent => setDisplayName(wrapDisplayName(BaseComponent, 'WithModals'))(BaseComponent),
  BaseComponent => props => (
    <Subscribe to={[ModalsContainer]}>
      {context => (
        <BaseComponent
          {...props}
          showModal={context.showModal}
          setupToggle={context.setupToggle}
          setupDestroy={context.setupDestroy}
          modalStack={context.state.modalStack}
          removeAriaAttribute={context.removeAriaAttribute}
        />
      )}
    </Subscribe>
  ),
);

export const withModals = canUseDOM ? withClientModals : BaseComponent => BaseComponent;
