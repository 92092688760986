/* eslint-disable no-template-curly-in-string */

import { object, string, ref } from 'yup';

const REQUIRED_MESSAGE = 'Required';
const PASSWORD_MISMATCH_MESSAGE = 'Does not match password';
const INVALID_EMAIL_MESSAGE = 'Invalid email address';
const INVALID_US_PHONE_MESSAGE = 'Invalid phone number, must be 10 digits';
const INVALID_PHONE_MESSAGE = 'Please enter a valid phone number';
const MIN_CHARACTER_MESSAGE = 'Must be ${min} characters or more';
const MAX_CHARACTER_MESSAGE = 'Must be ${max} characters or less';
const NO_NUMBERS = 'Names cannot contain numbers';
const PHONE_REGEX_NO_SYMBOLS = /^(0|[1-9][0-9]{9})$/i;
// this regex matches string that starts with a 0 or starts with a digit from 1 to 9 followed by 5 to 14 digits
// used to validate against international numbers for renters
export const INTERNATIONAL_PHONE_REGEX = /^(0|[1-9][0-9]{5,14})$/i;

export const phoneRegexTest = value => {
  if (!value) return null;
  const phoneNormalized = value.replace(/\D+/g, '');
  return PHONE_REGEX_NO_SYMBOLS.test(phoneNormalized);
};

export const intPhoneRegexTest = value => {
  if (!value) return null;
  const phoneNormalized = value.replace(/\D+/g, '');
  return INTERNATIONAL_PHONE_REGEX.test(phoneNormalized);
};

const noAndWordOrSymbols = value => {
  return (
    value &&
    value.length > 0 &&
    value.indexOf('+') < 0 &&
    value.indexOf('&') < 0 &&
    value.match(/\band\b/i) === null
  );
};

const noNumbers = value => {
  return value && value.match(/\d/) === null;
};

export default object().shape({
  first_name: string()
    .min(2, MIN_CHARACTER_MESSAGE)
    .max(50, MAX_CHARACTER_MESSAGE)
    .test(REQUIRED_MESSAGE, REQUIRED_MESSAGE, value => noAndWordOrSymbols(value))
    .test(NO_NUMBERS, NO_NUMBERS, value => noNumbers(value))
    .required(REQUIRED_MESSAGE),
  last_name: string()
    .min(2, MIN_CHARACTER_MESSAGE)
    .max(50, MAX_CHARACTER_MESSAGE)
    .test(REQUIRED_MESSAGE, REQUIRED_MESSAGE, value => noAndWordOrSymbols(value))
    .test(NO_NUMBERS, NO_NUMBERS, value => {
      if (process.env.RACK_ENV !== 'production') {
        return true;
      }
      return noNumbers(value);
    })
    .required(REQUIRED_MESSAGE),
  email: string()
    .email(INVALID_EMAIL_MESSAGE)
    .required(REQUIRED_MESSAGE),
  phone: object().shape({
    number: string()
      .when('country_code', {
        is: '+1',
        then: number =>
          number.test('us-phone-test', INVALID_US_PHONE_MESSAGE, phoneRegexTest),
        otherwise: number =>
          number.test('int-phone-test', INVALID_PHONE_MESSAGE, intPhoneRegexTest),
      })
      .required(REQUIRED_MESSAGE),
    country_code: string().required(REQUIRED_MESSAGE),
    country_alpha_code: string().required(REQUIRED_MESSAGE),
  }),
  password: string()
    .min(8, MIN_CHARACTER_MESSAGE)
    .required(REQUIRED_MESSAGE),
  password_confirmation: string()
    .oneOf([ref('password')], PASSWORD_MISMATCH_MESSAGE)
    .required(REQUIRED_MESSAGE),
});
