import React from 'react';
import PropTypes from 'prop-types';
import canUseDOM from 'can-use-dom';

import { getStateFromDOM } from 'core/utils/client';
import { SocialAuthProviders } from 'core/helpers/SocialAuth';
import SocialButton from './SocialButton';

let tokenClient

const initTokenClient = (callback) => {
  const config = {
    client_id: getStateFromDOM('env.googleClientId'),
    scope: 'email profile openid phone',
    ux_mode: 'popup',
    context: 'signin'
  }

  tokenClient = google.accounts.oauth2.initTokenClient(config)
  callback()
}

const googleLoadSdk = (callback) => {
  if (!canUseDOM) return;

  const id = 'google-jssdk';
  const firstScript = document.getElementsByTagName('script')[0];

  // if loaded
  if (document.getElementById(id)) {
    callback();
    return;
  }

  if (!firstScript) return;

  const js = document.createElement('script');
  js.id = id;
  js.async = true;
  js.defer = true;
  js.onload = () => initTokenClient(callback);
  firstScript.parentNode.insertBefore(js, firstScript);
  js.src = '//accounts.google.com/gsi/client';
};

const googleLogin = (callback) => {
  tokenClient.callback = callback
  tokenClient.requestAccessToken();
};

const googleBuildParams = (authResponse) => {
  if (authResponse.error) {
    let error = authResponse.details ?? `Error code ${authResponse.error} received from Google.`;

    if (authResponse.error === 'idpiframe_initialization_failed') {
      error = 'Your browser has cookies disabled. Make sure your cookies are enabled and try again.';
    } else if (authResponse.error === 'popup_closed_by_user' || authResponse.error === 'popup_blocked_by_browser') {
      error = 'The authorization popup was closed or blocked. If this issue persists, try temporarily enabling popups for this site.';
    }

    return { error }
  }

  return { params: authResponse, error: null };
};

export default function GoogleButton(props) {
  const { action, onSubmitSuccess, onStartSocialSignUpSuccess } = props;

  return (
    <SocialButton
      {...{
        action,
        onSubmitSuccess,
        onStartSocialSignUpSuccess
      }}
      provider={SocialAuthProviders.GOOGLE}
      loadSdk={googleLoadSdk}
      loginHandler={googleLogin}
      buildParams={googleBuildParams}
    />
  );
}

GoogleButton.propTypes = {
  action: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func,
};

GoogleButton.defaultProps = {
  onSubmitSuccess: null,
};
