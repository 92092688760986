import { useEffect } from 'react';
import { getStateFromDOM } from 'core/utils/client';

function InbentaChat() {
  const { rack_env } = getStateFromDOM('env');
  const isProd = rack_env === 'production';

  useEffect(() => {
    // First check if the script has already been added to the page
    if (window.inbentaChatScriptLoaded || !rack_env) {
      return;
    }

    // Include the chat script directly in the component
    const script = document.createElement('script');
    script.id = 'inbenta-chat-script';

    // Determine if production environment
    script.src = isProd
      ? 'https://rvshare-chatbot.s3.amazonaws.com/inbenta-conf.min.js'
      : 'https://rvshare-chatbot.s3.amazonaws.com/inbenta-conf-test.min.js';

    script.async = true;
    document.body.appendChild(script);

    // Set the flag to indicate that the script has been loaded
    window.inbentaChatScriptLoaded = true;

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
      window.inbentaChatScriptLoaded = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // No need to render anything, as the chat functionality will be added dynamically
  return null;
}

export default InbentaChat;
