import useApi from 'core/hooks/useApi';
import { differenceInMilliseconds, parseISO } from 'date-fns';

// send user to dashboard
export const sendToDashboard = () => {
  window.location.replace('/dashboard');
};

// send user to Wizard
// eslint-disable-next-line no-unused-vars
export const sendToWizard = (rvId, queryString = '') => {
  window.location.replace(`/dashboard/list-your-rv/${rvId || '_'}${queryString}`);
};

export default function LoginRouting(userId, queryString = '') {
  const api = useApi();

  return api.get(`/v1/users/${userId}/rvs`).then(({ data }) => {
    // Grab all rvs associated with current user, or empty array to avoid array errors
    const rvs = data.data || [];
    // No RVs available, send to fresh Wizard
    if (!rvs.length) {
      return sendToWizard();
    }

    // Filter through rvs and determine action based on rv status
    // If there are any active RVs, send to Dashboard in Pyro
    const hasActiveRv = rvs.some((rv) => {
      return rv.attributes.status === 'active';
    });
    if (hasActiveRv) {
      return sendToDashboard();
    }

    // Filter for incomplete RVs
    const incompleteRvs = rvs.filter(rv => rv.attributes.status === 'incomplete');
    if (incompleteRvs.length) {
      // Parse date strings, then sort by most recently updated RV
      const sortedRvs = incompleteRvs.sort((left, right) => {
        return differenceInMilliseconds(parseISO(right.attributes.updated), parseISO(left.attributes.updated));
      });
      // Grab most recently updated RV in array
      const mostRecentRv = sortedRvs[0];
      // send user to wizard with most recently updated RV
      return sendToWizard(mostRecentRv.id, queryString);
    }

    // Default case in case everything fails, send to Dashboard
    return sendToDashboard();
  });
}
