import React from 'react';

import { SocialColumn } from 'components/PageFooter/styles';
import FooterSocialListItem from 'components/PageFooter/FooterSocialListItem';
import FooterAppListItem from 'components/PageFooter/FooterAppListItem';

function FooterSocialColumn() {
  return (
    <SocialColumn>
      <li>
        <div>
          <FooterSocialListItem name='Facebook' link='https://www.facebook.com/rvshare/' />
          <FooterSocialListItem name='Instagram' link='https://www.instagram.com/rvshare/' />
          <FooterSocialListItem name='Youtube' link='https://youtube.com/c/Rvshareonline' />
          <FooterSocialListItem name='X' link='https://x.com/RVshare' />
        </div>
      </li>
      <FooterAppListItem />
    </SocialColumn>
  )
}

export default FooterSocialColumn;
