import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

import Box from './Box';

const linkStyle = variant({
  key: 'links',
});

const StyledLink = styled(Box)`
  ${linkStyle}
`;

function Link(props) {
  return <StyledLink {...props}>{props.children}</StyledLink>;
}

Link.defaultProps = {
  variant: 'blue',
  as: 'a',
};

export default Link;
