import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { useMedia } from 'use-media';
import canUseDOM from 'can-use-dom';

import { getStateFromDOM } from 'core/utils/client';

import { sizes } from '../styles/mediaQuery';
import useIsMounted from './useIsMounted';

export const DeviceContext = createContext({});

const getCurrentDevice = (isMobile, isTablet) => {
  if (isMobile && !isTablet) {
    return 'mobile';
  }
  if (isTablet && !isMobile) {
    return 'tablet';
  }
  if (!isMobile && !isTablet) {
    return 'desktop';
  }
  return 'unknown';
};

const useDeviceContext = () => {
  const context = useContext(DeviceContext);
  const { isMobile, isTablet } = context || {};
  return {
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet,
    currentDevice: getCurrentDevice(isMobile, isTablet),
  };
};

const useMediaListener = () => {
  const isMounted = useIsMounted();

  // call getStateFromDOM once to see what the server classified us as
  const isDetectedMobile = useMemo(() => !!(getStateFromDOM().isMobile), []);
  const isDetectedTablet = useMemo(() => !!(getStateFromDOM().isTablet), []);

  // attach media query listeners to get up-to-date size info
  const isCurrentlyMobile = useMedia({ maxWidth: sizes.md - 1 });
  const isCurrentlyTablet = useMedia({ minWidth: sizes.md, maxWidth: sizes.lg - 1 });

  // First render should match the server
  const [isMobile, setIsMobile] = useState(isDetectedMobile);
  const [isTablet, setIsTablet] = useState(isDetectedTablet);

  useEffect(() => {
    // Force update whenever state doesn't match current query
    if (isMounted && isMobile !== isCurrentlyMobile) {
      setIsMobile(isCurrentlyMobile);
    }

    if (isMounted && isTablet !== isCurrentlyTablet) {
      setIsTablet(isCurrentlyTablet);
    }
  }, [isMounted, isMobile, isCurrentlyMobile, isTablet, isCurrentlyTablet]);

  return {
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet,
    currentDevice: getCurrentDevice(isMobile, isTablet),
  };
};

export const useDevice = canUseDOM ? useMediaListener : useDeviceContext;

export default useDevice;
