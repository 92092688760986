import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button as BaseButton, Text as BaseText } from 'core/ui';
import Icon from 'core/ui/Icon';
import useToggle from 'core/hooks/useToggle';
import useKey from 'core/hooks/useKey';
import OutsideClick from 'components/OutsideClickHandler';

const ArrowIcon = styled(({ dropdownOpen, ...rest }) => <Icon {...rest} />)`
  vertical-align: middle;
`;

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Button = styled(BaseButton)`
  display: inline-block;
  cursor: pointer;

  ${props => props.buttonStyles || ''}
`;

const Text = styled(BaseText)`
  display: inline;
`;

const DropdownStyled = styled.div`
  background-color: #fff;
  border: 1px solid ${props => props.theme.borders.lightGrey};
  border-radius: 4px;
  color: ${props => props.theme.colors.darkBlue};
  padding: 10px 30px;
  text-align: left;
  position: absolute;
  top: 50px;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;
  z-index: 6;
  ${props => props.dropdownStyles || ''}
`;

const ESC_KEY = 27;

function Dropdown(props) {
  const {
    children,
    buttonVariant,
    buttonActive,
    buttonStyles,
    button,
    id,
    dropdownStyles,
    afterToggleHandler,
  } = props;

  const wrapperRef = useRef(null);
  const [dropdownOpen, toggleDropdown] = useToggle(false);

  const handleToggle = useCallback(() => {
    const isOpen = !dropdownOpen;

    toggleDropdown();

    if (afterToggleHandler) {
      afterToggleHandler(wrapperRef.current, isOpen);
    }
  }, [afterToggleHandler, toggleDropdown, dropdownOpen]);

  // keep this the same, so QA automation doesn't break
  const uniqueId = `dropdownComponent__${id}`;

  useKey(
    () => {
      if (dropdownOpen) {
        handleToggle();
      }
    },
    {
      detectKeys: [ESC_KEY],
    },
    [dropdownOpen]
  );

  const handleOutsideClick = useCallback(() => {
    if (dropdownOpen) {
      handleToggle();
    }
  }, [dropdownOpen, handleToggle]);

  const defaultVariant = buttonVariant || 'greyBorder';

  const buttonTrigger =
    typeof button === 'string' ? (
      <Button
        onClick={handleToggle}
        type="button"
        variant={buttonActive || dropdownOpen ? 'blueNoHover' : defaultVariant}
        aria-controls={uniqueId}
        aria-haspopup="true"
        aria-expanded={!!dropdownOpen}
        dropdownOpen={dropdownOpen}
        data-id={uniqueId}
        buttonStyles={buttonStyles}
      >
        <Text ml="8px" mr="10px" fontSize="14px">
          {button}
        </Text>
        <ArrowIcon icon={dropdownOpen ? 'angle-up' : 'angle-down'} dropdownOpen={dropdownOpen} />
      </Button>
    ) : (
      button({ handleToggle, open: dropdownOpen })
    );

  return (
    <DropdownWrapper ref={wrapperRef}>
      <OutsideClick onOutsideClick={handleOutsideClick}>
        {buttonTrigger}

        <div id={uniqueId}>
          {dropdownOpen && (
            <DropdownStyled role="menu" dropdownStyles={dropdownStyles}>
              {typeof children === 'function' ? children({ handleToggle }) : children}
            </DropdownStyled>
          )}
        </div>
      </OutsideClick>
    </DropdownWrapper>
  );
}

Dropdown.defaultProps = {
  buttonVariant: 'greyBorder',
  buttonActive: false,
  dropdownStyles: null,
  afterToggleHandler: null,
};

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.elementType,
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
  buttonVariant: PropTypes.string,
  buttonActive: PropTypes.bool,
  dropdownStyles: PropTypes.shape({}),
  afterToggleHandler: PropTypes.func,
};

export default Dropdown;
