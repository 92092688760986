import React from 'react';

import { ListItem, FooterCopyrightSpacer } from 'components/PageFooter/styles';

function FooterListItem({ item, showBullet = false }) {
  return (
    <ListItem>
      <a
        href={item.href}
        target="_blank"
        rel="noopener noreferrer"
        data-track="click"
        data-action="Footer Link Click"
        data-category="Link Click"
        data-label={item['data-label'] || 'Home'}
        data-link={item['data-link'] || item.name}
        data-event-category="Global Element"
        data-event-action={item.name}
        data-event-label="Footer"
      >
        {item.name}
      </a>
      {showBullet ? <FooterCopyrightSpacer /> : null}
    </ListItem>
  );
}

export default FooterListItem;
