import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DisclaimerStyled = styled.div`
  color: ${props => props.theme.colors.mutedGrey};
  font-weight: ${props => props.theme.fontWeights.normal};
  font-size: 13px;
  margin-top: 1rem;

  a {
    text-decoration: underline;
    color: ${props => props.theme.colors.primaryBlue};
  }
`;

export default function Disclaimer(props) {
  return (
    <DisclaimerStyled {...props}>
      <span>By signing up, I agree to RVshare&apos;s</span>{' '}
      <a
        href="/terms-of-service"
        target="_blank"
        rel="noopener noreferrer"
        data-track="click"
        data-action="Link Click"
        data-category="Link Click"
        data-label="AuthModal"
        data-link="Terms of Service"
      >
        Terms of Service
      </a>{' '}
      and{' '}
      <a
        href="/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        data-track="click"
        data-action="Link Click"
        data-category="Link Click"
        data-label="AuthModal"
        data-link="Privacy Policy"
      >
        Privacy Policy
      </a>
      .
    </DisclaimerStyled>
  );
}

Disclaimer.propTypes = {
  className: PropTypes.string,
};

Disclaimer.defaultProps = {
  className: '',
};
