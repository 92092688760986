/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import * as Castle from '@castleio/castle-js';

import { normalizeServerErrors } from 'core/helpers/API';
import useApi from 'core/hooks/useApi';
import { withCurrentUser } from 'core/state/Globals';
import { configureCastle } from 'core/helpers/Castle';
import { trackSignUpCompleted } from 'tracking/account/TrackSignUps';

import ShakeAlert from 'components/ShakeAlert';
import TextField from 'form-fields/styled/TextField';
import EmailField from 'form-fields/styled/EmailField';
import InternationalPhoneField from 'form-fields/styled/InternationalPhoneField';
import PasswordField from 'form-fields/styled/PasswordField';
import { errorStyles } from 'form-fields/rebrand/fieldStyles';
import { Button } from 'core/ui';

import RegisterFormValidator from '../validators/Register';
import SafeTracking from '../../tracking/SafeTracking';

import { FieldsWrapper, FormGroup, FormGroupFlex, Instruction } from '../modals/styles/Modal';

const FormInputs = ({ disabled, buttonText }) => {
  return (
    <FieldsWrapper>
      <TextField id="register__user_type" type="hidden" name="user_type" />
      <FormGroupFlex>
        <div style={{ position: 'relative' }}>
          <label htmlFor="register__first_name">First Name</label>
          <TextField
            id="register__first_name"
            name="first_name"
            data-testid="register-first-name"
            disabled={disabled}
            errorStyles={errorStyles}
            required
          />
        </div>
        <div style={{ position: 'relative' }}>
          <label htmlFor="register__last_name">Last Name</label>
          <TextField
            id="register__last_name"
            name="last_name"
            data-testid="register-last-name"
            disabled={disabled}
            errorStyles={errorStyles}
            required
          />
        </div>
      </FormGroupFlex>
      <Instruction>
        Enter your name exactly as it appears on your driver&apos;s license.
      </Instruction>
      <FormGroup>
        <label htmlFor="register__email">Email</label>
        <EmailField
          id="register__email"
          name="email"
          data-testid="register-email"
          disabled={disabled}
          autoComplete="username"
          errorStyles={errorStyles}
          required
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="register__phone">Phone Number</label>
        <InternationalPhoneField
          name="phone"
          id="register__phone"
          autoComplete="tel-national"
          data-testid="register-phone"
          disabled={disabled}
          errorStyles={errorStyles}
          required
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="register__password">Password</label>
        <PasswordField
          id="register__password"
          name="password"
          data-testid="register-password"
          disabled={disabled}
          autoComplete="new-password"
          required
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="register__password_confirmation">Confirm Password</label>
        <PasswordField
          id="register__password_confirmation"
          name="password_confirmation"
          data-testid="register-password-confirmation"
          disabled={disabled}
          autoComplete="new-password"
          errorStyles={errorStyles}
          required
        />
      </FormGroup>
      <Button variant="coreBlueLargeAlt" type="submit" disabled={disabled} data-testid="sign-up">
        {buttonText}
      </Button>
    </FieldsWrapper>
  );
};

function RegisterForm({ email: initialEmail, onSubmitSuccess, reloadUser }) {
  const api = useApi();
  useEffect(() => {
    configureCastle();
  }, []);

  const onSubmit = useCallback(
    (values, context) => {
      const { setSubmitting, setStatus, setErrors } = context;

      Castle.createRequestToken().then(castleToken => {
        api
          .post('/v1/users/register', { castleToken, user: values })
          .then(({ data }) => {
            const {
              data: { id, attributes },
            } = data;

            setStatus({ success: true });
            setSubmitting(false);

            if (onSubmitSuccess) {
              onSubmitSuccess();
              
              SafeTracking.track('User Registered By Email', {
                rvsIntegrations: { all: false, gtm: true },
              });
              trackSignUpCompleted({
                email: attributes.email,
                userId: id,
                signUpMethod: 'email',
              });
            }
          })
          .catch(({ response: { data, status } = {} }) => {
            let error = 'Something went wrong';
            const phoneNotUnique = data?.errors?.base && 
              data?.errors?.base[0].includes('This phone number is already in use') && {
                phone: {
                  number: 'This phone number is already in use',
                },
              };
            const phoneInvalid = data?.errors?.base && data?.errors?.base[0].includes('Profile phone is invalid') && {
              phone: {
                number: 'Invalid phone number',
              },
            };
            const phoneVoip = data?.errors?.base &&
              data?.errors?.base[0].includes('cell phone or landline number') && {
                phone: {
                  number: 'Please provide a cell phone or landline number',
                },
              };

            if (status === 401) {
              error = 'Email address in use';
            }

            setStatus({ error });
            setErrors(phoneNotUnique || phoneInvalid || phoneVoip || normalizeServerErrors(data));
            setSubmitting(false);
          });
      });
    },
    [api, onSubmitSuccess, reloadUser]
  );

  return (
    <Formik
      initialValues={{
        user_type: 'renter',
        first_name: '',
        last_name: '',
        phone: {
          country_alpha_code: 'US',
          country_code: '+1',
          number: '',
        },
        email: initialEmail || '',
        password: '',
        password_confirmation: '',
      }}
      onSubmit={onSubmit}
      validationSchema={RegisterFormValidator}
      render={context => {
        const { handleSubmit, isSubmitting, status: { error, success } = {} } = context;
        const getButtonText = () => {
          if (isSubmitting) {
            return 'Signing Up';
          }

          if (success) {
            return 'Signed Up';
          }

          return 'Sign Up';
        };

        const disabled = isSubmitting || success;

        return (
          <div id="sign-up-part3">
            <ShakeAlert show={!!error && !isSubmitting}>
              {error || 'Something went wrong'}
            </ShakeAlert>
            <form noValidate onSubmit={handleSubmit}>
              <FormInputs disabled={disabled} buttonText={getButtonText()} />
            </form>
          </div>
        );
      }}
    />
  );
}

export default withCurrentUser(RegisterForm, { includeSetter: true });
