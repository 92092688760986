import React, { useCallback } from 'react';

import { withModals } from 'core/state/Modals';

const ConnectedModal = React.memo((props) => {
  const {
    isOpen,
    component: ModalComponent,
    customProps,
    onAfterToggle,
    onToggle,
    modalName,
  } = props;

  /**
   * Hide a modal instance
   *
   * @param {any} args
   *
   * @returns {Promise}
   */
  const toggle = (...args) => {
    onToggle(args);
    onAfterToggle(modalName);
  };

  return (
    <ModalComponent
      {...customProps}
      modal={{
        ...customProps.modal,
        isOpen,
        toggle,
        onRequestClose: toggle,
      }}
      isOpen={isOpen}
    />
  );
});

function ModalRoot(props) {
  const { modalStack, setupToggle, setupDestroy, removeAriaAttribute } = props;

  const modals = Array.from(modalStack.entries());
  const openModalsCount = modals.length;

  const onToggleAnyModal = useCallback(
    (modalName) => {
      if (openModalsCount === 1) {
        // only 1 modal is showing, so it is safe to remove this attribute
        removeAriaAttribute();
      }

      // Destroy modal when cleaning up
      setupDestroy(modalName)();
    },
    [openModalsCount, removeAriaAttribute, setupDestroy],
  );

  return (
    <>
      {modals.map(([name, modal]) => {
        const { component, props: customProps, isOpen } = modal;

        return (
          <ConnectedModal
            key={name}
            isOpen={isOpen}
            component={component}
            customProps={customProps}
            onAfterToggle={onToggleAnyModal}
            onToggle={setupToggle(name)}
            modalName={name}
          />
        );
      })}
    </>
  );
}

export default withModals(ModalRoot);
