import React, { useState, useEffect } from 'react';
import { Subscribe } from 'unstated';
import { compose } from 'recompose';

import {
  trackSignUpStarted,
  trackSignUpTypeSelected,
  trackSignUpMethodSelected,
} from 'tracking/account/TrackSignUps';

import { Button } from 'core/ui';
import ModalsContainer from 'core/state/Modals';
import Collapse from 'components/Collapse';
import RegisterGroupChooser from 'auth/components/RegisterGroupChooser';
import RegisterRenterChooser from 'auth/components/RegisterRenterChooser';
import Disclaimer from 'auth/components/Disclaimer';

import { ModalStyled, ModalHeaderStyled, CloseButtonStyled, ModalFooter } from './styles/Modal';

function RegisterModal({ modal, showModal }) {
  const [isRenter, setIsRenter] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showSocialAuthForm, setShowSocialAuthForm] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (modal.isOpen) trackSignUpStarted( window.location.pathname );
  }, [modal.isOpen]);

  // open renter register options if user clicks on register as renter
  const onChooseRenter = () => {
    trackSignUpTypeSelected();
    setIsRenter(true);
  };

  const onChooseOwner = e => {
    e.preventDefault()
    trackSignUpTypeSelected( 'Owner' );
    window.location.href = '/list-rv';
  }

  const onShowRegisterForm = () => {
    trackSignUpMethodSelected('Email');
    setShowRegisterForm(true);
    setShowSocialAuthForm(false);
  };

  const onSwitchToLoginModal = async () => {
    await modal.onRequestClose();
    showModal('Login');
  };

  // if a social sign up button is pressed, set returned user data and show the social auth form
  const onStartSocialSignUpSuccess = values => {
    setUserData(values);
    setShowSocialAuthForm(true);
    setShowRegisterForm(false);
  };

  // On successful registration with email, pass user along with response from api
  const onRegisterSubmitSuccess = () => {
    window.location.reload();
  };

  return (
    <ModalStyled id="registration-modal" {...modal}>
      <ModalHeaderStyled>
        {showSocialAuthForm ? 'Complete Sign Up' : 'Sign Up'}
        <CloseButtonStyled type="button" onClick={() => modal.toggle()}>
          <span className="sr-only">Close modal</span>&times;
        </CloseButtonStyled>
      </ModalHeaderStyled>

      {/* User chooses if they're an owner or renter */}
      <Collapse isOpen={!isRenter}>
        <RegisterGroupChooser onChooseRenter={onChooseRenter} onChooseOwner={onChooseOwner} />
      </Collapse>

      <Collapse isOpen={isRenter}>
        {/* Renter chooses how they'd like to register (social or email)
            Register by email and social sign up forms */}
        <RegisterRenterChooser
          onShowRegisterForm={onShowRegisterForm}
          showRegisterForm={showRegisterForm}
          onStartSocialSignUpSuccess={onStartSocialSignUpSuccess}
          showSocialAuthForm={showSocialAuthForm}
          userData={userData}
          setUserData={setUserData}
          onRegisterSubmitSuccess={onRegisterSubmitSuccess}
        />
      </Collapse>

      <ModalFooter>
        <p>
          Already have an RVshare account?{' '}
          <Button type="button" variant="linkPrimary" onClick={onSwitchToLoginModal}>
            Log In
          </Button>
        </p>
        {isRenter && <Disclaimer />}
      </ModalFooter>
    </ModalStyled>
  );
}

const enhance = compose(BaseComponent => props => (
  <Subscribe to={[ModalsContainer]}>
    {({ showModal }) => <BaseComponent showModal={showModal} {...props} />}
  </Subscribe>
));

export default enhance(RegisterModal);
