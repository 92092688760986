import styled from 'styled-components';

import MaskedTextField from '../MaskedTextField';

export const PhoneInputWrapper = styled.div`
  position: relative;
`;

export const CountryDropDown = styled.div`
  > button {
    width: 55px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0;
    background-color: transparent;
    padding: 0.375rem 0.75rem;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid ${({ theme }) => theme.colors.primaryBlue};
      border-radius: 3px;
      outline-offset: -3px;
    }

    &:hover {
      background-color: #f3f3f3;
    }

    img {
      width: 20px;
    }
  }
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  left: 1px;
  width: 100%;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
`;

export const DownArrow = styled.span`
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  height: 100%;
`;

export const CountriesMenu = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  top: 100%;
  max-height: 150px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  z-index: 9;

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 0.1rem 0.75rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:first-child {
        padding-bottom: 0.25rem;
        margin-bottom: 0.25rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
      }

      &:hover {
        background-color: #f3f3f3;
      }

      &:focus-visible {
        outline: 1px solid ${({ theme }) => theme.colors.primaryBlue};
        border-radius: 3px;
        outline-offset: -3px;
      }
    }
  }
`;

export const CountryCodeDisplayed = styled.div`
  pointer-events: none;
`;

export const CountryFlag = styled.img`
  display: block;
  margin-right: 0.5rem;
  width: 20px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

export const NoCountryFlag = styled.div`
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
`;

export const MaskedTextFieldStyled = styled(MaskedTextField)`
  padding-left: 85px;
`;
