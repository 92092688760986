import React from 'react';
import styled, { css } from 'styled-components';

const WIDTH = '34px';

const HamburgerInnerStyles = css`
  position: absolute;
  width: ${WIDTH};
  height: 4px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #afb6bd;
`;

const HamburgerOuter = styled.div`
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 5px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
`;

const HamburgerBox = styled.div`
  position: relative;
  display: inline-block;
  width: ${WIDTH};
  height: 24px;
`;

const HamburgerInner = styled.div`
  display: block;
  margin-top: -2px;
  top: 2px;
  ${HamburgerInnerStyles}

  ${({ open }) => open && 'transform: translate3d(0,10px,0) rotate(45deg);'}

  &::before {
    ${HamburgerInnerStyles}
    display: block;
    content: '';
    top: 10px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform, opacity;

    ${({ open }) =>
    open &&
      `
        transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
        opacity: 0;
    `}
  }

  &::after {
    ${HamburgerInnerStyles}
    display: block;
    content: '';
    top: 20px;
    bottom: -10px;

    ${({ open }) =>
    open &&
      `
        transform: translate3d(0,-20px,0) rotate(-90deg);
    `}
  }
`;

function Hamburger({ open }) {
  return (
    <HamburgerOuter>
      <HamburgerBox>
        <HamburgerInner open={open} />
      </HamburgerBox>
    </HamburgerOuter>
  );
}

export default Hamburger;
