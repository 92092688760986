import styled from 'styled-components';
import {
  space,
  color,
  width,
  flex,
  order,
  alignSelf,
  fontSize,
  display,
} from 'styled-system';

const Box = styled.div`
  box-sizing: border-box;
  ${space};
  ${width};
  ${fontSize};
  ${color};
  ${flex};
  ${order};
  ${alignSelf};
  ${display};
  ${props => props.theme.Box};
`;

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...flex.propTypes,
  ...order.propTypes,
  ...alignSelf.propTypes,
  ...display.propTypes,
};

export default Box;
